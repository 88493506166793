import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { Guia } from "../../models/Guia";
import { IUsesStorageHelper } from "../../interfaces/IUsesStorageHelper";
import { StorageHelper } from "../../helpers/storage.helper";
import {
  OperacaoSignalR,
  SignalrService,
  EnumTipoObjeto,
} from "../signalr.service";
import { StatusService } from "../status.service";
import { EntitiesHelper } from "../../helpers/entities.helper";
import { LeiLookup } from "../../models/lei/lei.lookup";

@Injectable()
export class UsuarioGuiasService implements IUsesStorageHelper {
  readonly databaseName = "userdata";
  readonly collectionName = "guias";

  public $Guias: Observable<Guia[]>;
  private _guias = new BehaviorSubject<Guia[]>(null);

  public $GuiaAtiva: Observable<Guia>;
  private _guiaAtiva = new BehaviorSubject<Guia>(null);

  public get guiaAtiva(): Guia {
    return EntitiesHelper.Copy(this._guiaAtiva.getValue());
  }

  constructor(
    private signalrService: SignalrService,
    private statusService: StatusService
  ) {
    this.$Guias = this._guias.asObservable();
    this.$GuiaAtiva = this._guiaAtiva.asObservable();
  }

  public fromNuvem(guias: Guia[]): Promise<void> {
    return new Promise(async (resolve) => {
      guias = await StorageHelper.upsertMany(
        guias,
        this.databaseName,
        this.collectionName,
        false
      );
      resolve();
    });
  }

  public carregar(): Promise<void> {
    return new Promise(async (resolve) => {
      let commit = false;
      const guias = await this.listar();

      if (!guias || guias.length === 0) {
        guias.push(this.criarGuia());
        commit = true;
      }

      if (guias.findIndex((g) => g.ativa) === -1) {
        guias[0].ativa = true;
        commit = true;
      }

      if (guias.filter((g) => g.ativa).length > 2) {
        const iProximaGuia = guias.findIndex((g) => g.ativa);
        for (const guia of guias) guia.ativa = false;

        guias[iProximaGuia].ativa = true;
        commit = true;
      }

      if (commit) this.atualizarVarias(guias);

      this._guias.next(guias);
      this._guiaAtiva.next(guias.find((g) => g.ativa));

      resolve();
    });
  }

  private listar(): Promise<Guia[]> {
    return new Promise(async (resolve) => {
      let guias = await StorageHelper.list<Guia>(
        this.databaseName,
        this.collectionName
      );
      if (!guias) resolve([]);
      else resolve(guias.filter((g) => !g.removido).sort((g) => g.ordem));
    });
  }

  public buscar(id: string): Promise<Guia> {
    return new Promise(async (onsuccess) => {
      await StorageHelper.getByKey<Guia>(
        id,
        this.databaseName,
        this.collectionName
      )
        .then((guia) => onsuccess(guia))
        .catch((err) => {
          throw err;
        });
    });
  }

  public atualizar(guia: Guia, sync = true): Promise<Guia> {
    return new Promise(async (resolve) => {
      guia = await StorageHelper.upsert(
        guia,
        this.databaseName,
        this.collectionName
      );
      if (sync) {
        const mensagem = new OperacaoSignalR();
        mensagem.dados = guia;
        this.signalrService.enviarMensagem(mensagem, EnumTipoObjeto.Guias);
      }

      resolve(guia);
    });
  }

  public atualizarVarias(guias: Guia[], sync = true): Promise<Guia[]> {
    return new Promise(async (resolve) => {
      guias = await StorageHelper.upsertMany(
        guias,
        this.databaseName,
        this.collectionName
      );
      if (sync) {
        const mensagens = guias.map((g) => {
          const msg = new OperacaoSignalR();
          msg.dados = g;
          return msg;
        });

        this.signalrService.enviarMensagens(mensagens, EnumTipoObjeto.Guias);
      }

      resolve(guias);
    });
  }

  public alterarConteudoGuia(id: string, item: LeiLookup): Promise<void> {
    return new Promise(async (resolve) => {
      const guia = await this.buscar(id);

      guia.idLei = item.id;
      guia.titulo = item.titulo;

      await this.atualizar(guia);
      await this.carregar();

      resolve();
    });
  }

  private criarGuia(): Guia {
    return new Guia();
  }

  public fecharGuia(id: string): Promise<void> {
    return new Promise(async (resolve) => {
      const guias = this._guias.getValue();
      const iGuiaRemover = guias.findIndex((g) => g.id === id);

      guias[iGuiaRemover].removido = true;

      await this.atualizarVarias(guias);
      await this.carregar();
      resolve();
    });
  }

  public novaGuia(guia: Guia = null, tornarAtiva = false): Promise<Guia> {
    return new Promise(async (onsuccess) => {
      if (guia == null) guia = new Guia();

      this.atualizar(guia)
        .then((guiaSalva) => {
          if (tornarAtiva) {
            this.alterarGuiaAtiva(guiaSalva.id)
              .then((guias) => {
                var guiaRetornar = guias.find((g) => g.id === guia.id);
                onsuccess(guiaRetornar);
              })
              .catch((err) => {
                throw err;
              });
          } else {
            this.carregar()
              .then(() => {
                onsuccess(guiaSalva);
              })
              .catch((err) => {
                throw err;
              });
          }
        })
        .catch((err) => {
          throw err;
        });
    });
  }

  public alterarGuiaAtiva(id: string): Promise<Guia[]> {
    return new Promise(async (onsuccess) => {
      this.listar()
        .then((guias) => {
          guias.forEach((guia) => {
            guia.ativa = guia.id === id;
          });

          this.atualizarVarias(guias)
            .then((guiasSalvas) => {
              this.carregar()
                .then(() => onsuccess(guiasSalvas))
                .catch((err) => {
                  throw err;
                });
            })
            .catch((err) => {
              throw err;
            });
        })
        .catch((err) => {
          throw err;
        });
    });
  }
}
