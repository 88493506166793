import { Component, Inject } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { PreferenciasUsuario } from "src/app/models/UserData";

@Component({
  selector: "app-config-dialog",
  templateUrl: "./config-dialog.component.html",
  styleUrls: ["./config-dialog.component.scss"],
})
export class ConfigDialogComponent {
  dialog: MatDialog;

  constructor(
    public dialogRefConfig: MatDialogRef<ConfigDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PreferenciasUsuario
  ) {}

  onCancel(): void {
    this.dialogRefConfig.close();
  }
  onSave() {
    this.dialogRefConfig.close(this.data);
  }
}
