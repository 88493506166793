import { Component, OnInit, OnDestroy } from '@angular/core';
import { PaineisService } from '../leitor-content-tabcontent/paineis.service';
import { Subscription } from 'rxjs';
import { PopupsService } from '../popups/popups.service';
import { ConfiguracoesUsuario } from '../../models/usuario/ConfiguracoesUsuario';
import { UsuarioPreferenciasService } from '../../services/data-services/usuario.preferencias.service';
import { CheckForUpdateService } from '../../services/checkForUpdateService';
import { StatusService } from '../../services/status.service';
import { AuthService } from '../../modules/shared/services/auth.service';
import { UiService } from '../../services/ui.service';

@Component({
  selector: '[app-navbar]',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {
  atualizacaoDisponivel: boolean;
  quantidadeItensRevisao = 0;
  subscriptions: Subscription[] = [];

  public mobile = false;
  public model = new NavbarModel();

  deferredPrompt: any;
  exibirInstalacao = false;

  addToHomeScreen() {
    // Show the prompt
    this.deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice
      .then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          // hide our user interface that shows our A2HS button
          this.exibirInstalacao = false;
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
        }
        this.deferredPrompt = null;
      });
  }

  constructor(
    public auth: AuthService,
    private paineisService: PaineisService,
    private popupsService: PopupsService,
    private usuarioPreferenciasService: UsuarioPreferenciasService,
    public checkForUpdateService: CheckForUpdateService,
    public statusService: StatusService,
    public uiService: UiService,
  ) { }

  atualizarApp() {
    this.checkForUpdateService.updateApplication()
  }

  ngOnInit() {
    this.subscriptions.push(this.usuarioPreferenciasService.$Configuracoes.subscribe(config => {
      this.model = NavbarModel.fromUserData(config);
    }));

    this.subscriptions.push(this.uiService.Mobile.subscribe(m => this.mobile = m));

    // usado para teste
    // this.atualizacaoDisponivel = true;

    window.addEventListener('beforeinstallprompt', function (e) {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
      this.exibirInstalacao = true;
    });

  }

  ngOnDestroy(): void {
    if (this.subscriptions)
      this.subscriptions.forEach(sub => sub.unsubscribe())
  }

  logoff() {
    this.auth.logoff();
  }

  lnkPreferencias_click(event: Event) {
    event.preventDefault();
    this.popupsService.abrirPopupPreferencias();
  }

  public lnkPerfil_click(e: Event): void {
    e.preventDefault();
    this.popupsService.abrirPopupPerfil();
  }

  NovidadesClick() {
    this.paineisService.abrirPainelNovidades();
  }
}

export class NavbarModel {
  public user_nome: string;
  public user_foto: string;

  public static fromUserData(user: ConfiguracoesUsuario) {
    const ret = new NavbarModel();

    if (user) {
      ret.user_nome = user.nome;
      ret.user_foto = user.foto;
    }

    return ret;
  }
}
