export class DataControle {
  tipo: EnumTipoDataControle;
  data: Date;
}

export enum EnumTipoDataControle {
  DataVerificacaoModoOffline = 0,
  DataVerificacaoListaLeis = 1,
  DataVisualizacaoNovidades = 2,
}
