<button
  *ngFor="let star of stars; index as i"
  [id]="'star_' + i"
  Class="rating-star"
  (click)="countStar(i + 1)"
>
  <mat-icon
    [ngStyle]="{ color: myVariableColor }"
    class="material-icons-outlined"
    >{{ showIcon(i + 1) }}</mat-icon
  >
</button>
