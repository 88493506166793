import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { ConfiguracoesUsuario } from "../../models/usuario/ConfiguracoesUsuario";
import { UserdataDTO } from "../../models/UserdataSync";
import {
  SignalrService,
  OperacaoSignalR,
  EnumTipoObjeto,
} from "../signalr.service";
import { PreferenciasUsuario } from "src/app/models/UserData";
import { AppConfig } from "src/app/app.config";
import { HttpParams, HttpClient } from "@angular/common/http";
import { ErrorHandlerService } from "../errorHandler.service";

@Injectable()
export class UsuarioPreferenciasService {
  private readonly key = "config";

  public $Configuracoes: Observable<ConfiguracoesUsuario>;
  private _Configuracoes = new BehaviorSubject<ConfiguracoesUsuario>(
    this.CarregarConfiguracoes()
  );

  constructor(
    private signalrService: SignalrService,
    private errorHandlerService: ErrorHandlerService,
    private httpClient: HttpClient
  ) {
    this.$Configuracoes = this._Configuracoes.asObservable();
    signalrService.$Mensagem.subscribe((msg) => this.onMessageReceived(msg));
  }

  private onMessageReceived(msg: OperacaoSignalR): void {
    if (msg && msg.tipoObjeto === EnumTipoObjeto.Preferencias) {
      this.setConfiguracoes(msg.dados, false);
    }
  }

  private CarregarConfiguracoes(): ConfiguracoesUsuario {
    let retorno = null;

    let json = localStorage.getItem(this.key);
    if (json) retorno = JSON.parse(json);

    return retorno;
  }

  public get Configuracoes(): ConfiguracoesUsuario {
    return this._Configuracoes.value;
  }

  private setConfiguracoes(value: ConfiguracoesUsuario, sync = true) {
    let json = JSON.stringify(value);
    localStorage.setItem(this.key, json);

    this._Configuracoes.next(value);

    if (sync) {
      const mensagem = new OperacaoSignalR();
      mensagem.dados = value;
      this.signalrService.enviarMensagem(mensagem, EnumTipoObjeto.Preferencias);
    }
  }

  public alterarConfiguracoes(
    config: ConfiguracoesUsuario,
    audit = true
  ): void {
    if (audit) config.dataUltimaAlteracao = new Date();

    this.setConfiguracoes(config, true);
  }

  public fromNuvem(userdata: UserdataDTO): Promise<void> {
    return new Promise((onsuccess, onerror) => {
      const model = ConfiguracoesUsuario.fromModel(userdata);
      this.setConfiguracoes(model, false);

      onsuccess();
    });
  }

  public alterarPreferenciasUsuario(
    preferencias: PreferenciasUsuario,
    config: ConfiguracoesUsuario
  ) {
    config.preferenciasUsuario.exibirItensRevogados =
      preferencias.exibirItensRevogados;
    config.preferenciasUsuario.exibirComentariosSvm =
      preferencias.exibirComentariosSvm;
    config.preferenciasUsuario.exibirApenasItensComentados =
      preferencias.exibirApenasItensComentados;
    config.preferenciasUsuario.exibirMeusComentarios =
      preferencias.exibirMeusComentarios;

    this.alterarConfiguracoes(config);
  }

  public resetarPreferenciasUsuario(): Promise<ConfiguracoesUsuario> {
    return new Promise(async (onsuccess, onerror) => {
      const url = `${AppConfig.apiEndpoint}/DadosCadastroUsuario/ResetarConfiguracoesUsuario`;
      let requestParams = new HttpParams();

      requestParams = requestParams.append("usuarioId", this.Configuracoes.id);
      await this.httpClient
        .get(url, { params: requestParams })
        .toPromise()
        .then((result: ConfiguracoesUsuario) => {
          if (result) {
            const prefe = Object.assign(this.Configuracoes, result);
            this.setConfiguracoes(prefe, false);
            onsuccess(prefe);
          }
        })
        .catch((error) => this.errorHandlerService.handleError(error));
    });
  }
}
