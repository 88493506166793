import { LeiStorage } from "./lei.storage";
import { LeiDownloadResponse } from "../../arguments/lei/lei.download.response";
import { CompressionHelper } from "../../helpers/compression.helper";
import { Lei } from "../Lei";

export class LeiInfo {
  id: string;
  descricao: string;
  quantidadeItens: number;
  quantidadePalavras: number;
  dataHoraUltimaModificacaoTextoLei: Date;
  dataHoraUltimaModificacaoOficial: Date;
  tipoDocumento: number;
  disponivel: boolean;
  atualizacaoPendente: boolean;

  static fromStorage(lei: LeiStorage): LeiInfo {
    return {
      id: lei.id,
      descricao: lei.descricao,
      quantidadeItens: lei.quantidadeItens,
      quantidadePalavras: lei.quantidadePalavras,
      dataHoraUltimaModificacaoTextoLei: lei.dataHoraUltimaModificacaoTextoLei,
      dataHoraUltimaModificacaoOficial: lei.dataHoraUltimaModificacaoOficial,
      tipoDocumento: lei.tipoDocumento,
      disponivel: lei.dados ? true : false,
      atualizacaoPendente: lei.atualizacaoPendente,
    };
  }

  static fromLeiDownloadResponse(leiDownload: LeiDownloadResponse) {
    const leiTemp = leiDownload.jsonData;
    const jsonLei = CompressionHelper.strUnzip(leiTemp);

    const lei = <Lei>JSON.parse(jsonLei);
    const retorno = new LeiInfo();

    retorno.id = lei.id;
    retorno.descricao = lei.descricao;
    retorno.quantidadeItens = lei.quantidadeItens;
    retorno.quantidadePalavras = lei.quantidadePalavras;
    retorno.dataHoraUltimaModificacaoTextoLei = lei.dataHoraUltimaModificacao;
    retorno.dataHoraUltimaModificacaoOficial =
      lei.dataHoraUltimaModificacaoOficial;
    retorno.tipoDocumento = lei.tipoDocumento;
    retorno.disponivel = true;

    return retorno;
  }
}
