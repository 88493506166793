import { Injectable } from "@angular/core";
import {
  Tempo,
  EstatisticasLeitura,
} from "../models/usuario/EstatisticasLeitura";
import { Observable, BehaviorSubject, Subject } from "rxjs";
import { Conteudo } from "../models/pagina/conteudo";
import { UsuarioGuiasService } from "./data-services/usuario.guias.service";
import { BuscaService } from "./busca.service";
import { UsuarioEstatisticasService } from "./data-services/usuario.estatisticas.service";
import { EntitiesHelper } from "../helpers/entities.helper";
import { UsuarioPreferenciasService } from "./data-services/usuario.preferencias.service";
import { Guia } from "../models/Guia";
import { BuscaPanelParameters } from "../components/leitor-content-panelbusca/busca-panel.parameters";
import { TextoPagina } from "../models/pagina/TextoPagina";
import { StatusService } from "./status.service";
import { Lei } from "../models/Lei";
import { UsuarioMarcacoesService } from "./data-services/usuario.marcacoes.service";
import { UsuarioComentariosService } from "./data-services/usuario.comentarios.service";
import { UsuarioGrifosService } from "./data-services/usuario.grifos.service";
import { MatchBuscaTexto } from "../models/MatchBuscaTexto";
import { LeiLookup } from "../models/lei/lei.lookup";
import { LeiRepositorio } from "../repositorios/lei.repositorio";
import { LoggingService } from "./logging.service";
import { KeyValue } from "@angular/common";
import { UsuarioComentariosGerenciadosService } from "./data-services/usuario.comentarios.gerenciados.service";
import { PreferenciasUsuario } from "../models/UserData";
import { UsuarioReferenciaService } from "./data-services/usuario.referencia.service";
import { UsuarioReferenciasGerenciadasService } from "./data-services/usuario.referencias.gerenciadas.service";

@Injectable()
export class ConteudoService {
  public Carregando: Observable<boolean>;
  private carregandoConteudo = new BehaviorSubject<boolean>(false);

  public Conteudo: Observable<Conteudo>;
  private conteudo = new BehaviorSubject<Conteudo>(null);

  public IndexFoco: Observable<number>;
  private _indexFoco = new Subject<number>();

  private _multipleReadedLinesChanged = new Subject<void>();
  public $multipleReadedLinesChanged =
    this._multipleReadedLinesChanged.asObservable();

  private _LeisGuiasCached: Array<LeiGuiacached>;
  private guia: Guia;
  private busca: BuscaPanelParameters;

  IndiceLei: Observable<KeyValue<string, number>[]>;
  private indiceLei = new BehaviorSubject<KeyValue<string, number>[]>(null);

  public getConteudo() {
    let conteudo = EntitiesHelper.Copy(this.conteudo.getValue());
    if (!conteudo) conteudo = new Conteudo();

    return conteudo;
  }

  constructor(
    private usuarioGuiasService: UsuarioGuiasService,
    private usuarioEstatisticasService: UsuarioEstatisticasService,
    private usuarioPreferenciasService: UsuarioPreferenciasService,
    private usuarioMarcacoesService: UsuarioMarcacoesService,
    private usuarioComentariosGerenciadosService: UsuarioComentariosGerenciadosService,
    private usuarioReferenciasGerenciadasService: UsuarioReferenciasGerenciadasService,
    private usuarioComentariosService: UsuarioComentariosService,
    private usuarioReferenciaService: UsuarioReferenciaService,
    private usuarioGrifosService: UsuarioGrifosService,
    private leiRepositorio: LeiRepositorio,
    private buscaService: BuscaService,
    private statusService: StatusService,
    private loggingService: LoggingService
  ) {
    this.Conteudo = this.conteudo.asObservable();
    this.Carregando = this.carregandoConteudo.asObservable();
    this.IndiceLei = this.indiceLei.asObservable();
    this.IndexFoco = this._indexFoco.asObservable();

    this._LeisGuiasCached = new Array<LeiGuiacached>();

    usuarioGuiasService.$Guias.subscribe((guias) => {
      this.guias_changed(guias);
    });
    usuarioGuiasService.$GuiaAtiva.subscribe((guia) =>
      this.guiaAtiva_changed(guia)
    );

    buscaService.$Busca.subscribe((busca) => this.busca_changed(busca));
  }

  private async busca_changed(busca: BuscaPanelParameters) {
    if (EntitiesHelper.equals(busca, this.busca)) return;

    this.busca = EntitiesHelper.Copy(busca);

    const conteudo = this.getConteudo();
    conteudo.busca = this.busca ? EntitiesHelper.Copy(this.busca) : null;
    this.conteudo.next(conteudo);
  }

  private async guiaAtiva_changed(guia) {
    if (EntitiesHelper.equals(guia, this.guia)) return;

    this.carregandoConteudo.next(true);
    this.guia = EntitiesHelper.Copy(guia);

    let conteudo = this.getConteudo();

    if (this.guia) conteudo.novaGuia = this.guia.idLei ? false : true;

    if (this.guia) {
      if (conteudo.idGuia !== this.guia.id) {
        conteudo.idGuia = this.guia.id;
        conteudo.busca = null;

        await this.carregarBusca(conteudo);
      }

      if (Conteudo.getIdLei(conteudo) !== this.guia.idLei) {
        Conteudo.setIdLei(conteudo, this.guia.idLei);
        conteudo.tituloGuia = this.guia.titulo;

        if (Conteudo.getIdLei(conteudo)) {
          conteudo = await this.carregarLei(conteudo, this.guia.id);
        } else {
          conteudo.linhas = [];
          conteudo.progressoLeitura = null;
          conteudo.estatisticas = null;
          conteudo.urlFonteLei = null;
        }
      }
    }

    this.conteudo.next(conteudo);
    this.carregandoConteudo.next(false);
  }

  private guias_changed(guias) {
    for (let index = 0; index < this._LeisGuiasCached.length; index++)
      if (
        guias.findIndex((g) => g.id === this._LeisGuiasCached[index].idGuia) ===
        -1
      )
        this._LeisGuiasCached.splice(index, 1);
  }

  private carregarIndice(lei: Lei, indexarRevogados: boolean) {
    this.indiceLei.next(Lei.carregarIndice(lei, indexarRevogados));
  }

  private carregarLei(conteudo: Conteudo, idGuia: string): Promise<Conteudo> {
    let idLei = Conteudo.getIdLei(conteudo);

    if (conteudo.busca && !this.busca) idLei = conteudo.idLei;

    return new Promise(async (resolve) => {
      if (!idLei) {
        resolve(conteudo);
      } else {
        const iLeiCache = this._LeisGuiasCached.findIndex(
          (l) => l.lei && l.lei.id === idLei
        );
        try {
          let lei: Lei =
            iLeiCache !== -1
              ? this._LeisGuiasCached[iLeiCache].lei
              : await this.leiRepositorio.carregarLei(idLei);
          this.carregarIndice(
            lei,
            this.usuarioPreferenciasService.Configuracoes.preferenciasUsuario
              .exibirItensRevogados
          );
          const [
            marcacoes,
            comentarios,
            referencias,
            grifos,
            estatisticas,
            comentariosGerenciado,
            referenciasGerenciado,
          ] = await Promise.all([
            this.usuarioMarcacoesService.buscarLei(idLei),
            this.usuarioComentariosService.carregarComentariosPorLei(idLei),
            this.usuarioReferenciaService.carregarReferenciasPorLei(idLei),
            this.usuarioGrifosService.carregarGrifosPorLei(idLei),
            this.usuarioEstatisticasService.buscar(idLei),
            this.usuarioComentariosGerenciadosService.carregarComentariosGerenciadosPorLei(
              idLei
            ),
            this.usuarioReferenciasGerenciadasService.carregarReferenciasGerenciadasPorLei(
              idLei
            ),
          ]);

          if (iLeiCache === -1 && !conteudo.busca) {
            this._LeisGuiasCached.push({ idGuia: idGuia, lei: lei });
          }

          conteudo.linhas = lei.itens.map((i) => new TextoPagina(i));
          conteudo.tipoDocumento = lei.tipoDocumento;
          conteudo.urlFonteLei = lei.url;

          for (let index = 0; index < conteudo.linhas.length; index++) {
            conteudo.linhas[index].index = index;
            conteudo.linhas[index].idLei = idLei;

            conteudo.linhas[index].marcacoesProva = marcacoes.filter((m) => {
              const idLinha = conteudo.linhas[index].id;
              //provas devem ser exibidas em todas versões
              return (
                m.range.idItens.findIndex((id) => id.idItem === idLinha) !== -1
              );
            });
            conteudo.linhas[index].referencias = referencias.filter((r) => {
              const idLinha = conteudo.linhas[index].id;

              return (
                r.links?.find((e) => e.idItem === idLinha)?.idItem === idLinha
              );
            });

            conteudo.linhas[index].comentarios = comentarios.filter((c) => {
              const idLinha = conteudo.linhas[index].id;
              //comentarios devem ser exibidas em todas versões
              return (
                c.range.idItens.findIndex((id) => id.idItem === idLinha) !== -1
              );
            });

            conteudo.linhas[index].grifos = grifos.filter((g) => {
              const idLinha = conteudo.linhas[index].id;
              const versao = conteudo.linhas[index].indexVersao;

              return g.idItem === idLinha && g.idImportacao === versao;
            });
          }
          conteudo.estatisticas = estatisticas;
          conteudo = await this.calcularProgressoLeiAtual(
            conteudo,
            lei,
            estatisticas
          );

          if (comentariosGerenciado) {
            for (let index = 0; index < conteudo.linhas.length; index++) {
              conteudo.linhas[index].index = index;
              conteudo.linhas[index].idLei = idLei;

              conteudo.linhas[index].comentariosGerenciados =
                comentariosGerenciado.comentarios.filter((c) => {
                  const idLinha = conteudo.linhas[index].id;

                  return (
                    c.range.idItens.findIndex((id) => id.idItem === idLinha) !==
                    -1
                  );
                });
            }
          }

          if (referenciasGerenciado) {
            for (let index = 0; index < conteudo.linhas.length; index++) {
              conteudo.linhas[index].index = index;
              conteudo.linhas[index].idLei = idLei;

              conteudo.linhas[index].referenciasGerenciado =
                referenciasGerenciado.referencias.filter((c) => {
                  const idLinha = conteudo.linhas[index].id;

                  return c.idItem === idLinha;
                });
            }
          }

          resolve(conteudo);
        } catch (err) {
          this.statusService.setMessage("Não foi possivel carregar a lei");
          throw err;
        }
      }
    });
  }

  private carregarBusca(conteudo: Conteudo): Promise<Conteudo> {
    const taskname = "carregarBusca";

    return new Promise((onsuccess, onerror) => {
      this.buscaService
        .carregarBuscaGuia(conteudo.idGuia)
        .then((buscaSalva) => {
          if (buscaSalva) {
            const busca = BuscaPanelParameters.fromBuscaSalva(buscaSalva);
            if (busca.buscarTodosDocumentos) {
              this.statusService.mostrarMensagemProgresso(
                "Recuperando busca salva",
                taskname
              );
              this.buscaService.buscar(busca, false).then((resultadoBusca) => {
                conteudo.busca = EntitiesHelper.Copy(resultadoBusca);
                onsuccess(conteudo);

                this.statusService.ocultarMensagemProgresso(taskname);
              });
            } else {
              conteudo.busca = EntitiesHelper.Copy(busca);
              if (!conteudo.busca.matchResultadoBuscaFoco) {
                conteudo.busca.matchResultadoBuscaFoco =
                  conteudo.busca.matchsResultadoBusca[0];
              }
              onsuccess(conteudo);
            }
          } else {
            onsuccess(conteudo);
          }
        });
    });
  }

  private calcularProgressoLeiAtual(
    conteudo: Conteudo,
    lei: LeiLookup | Lei,
    estatisticas: EstatisticasLeitura
  ): Promise<Conteudo> {
    const guia = this.usuarioGuiasService.guiaAtiva;

    return new Promise((onsuccess) => {
      const configuracoes = this.usuarioPreferenciasService.Configuracoes;
      if (guia && guia.idLei && configuracoes && configuracoes.palavrasMinuto) {
        const palavrasMinuto = configuracoes.palavrasMinuto;

        const progresso = new ProgressoLeitura();
        const qntlinhasLidas =
          estatisticas && estatisticas.linhasLidas
            ? estatisticas.linhasLidas.length
            : 0;

        progresso.progresso = EstatisticasLeitura.CalcularProgresso(
          lei.quantidadeItens,
          qntlinhasLidas
        );
        progresso.tempoRestante = EstatisticasLeitura.CalcularTempoRestante(
          palavrasMinuto,
          progresso.progresso,
          lei.quantidadePalavras
        );

        conteudo.progressoLeitura = EntitiesHelper.Copy(progresso);
      } else {
        conteudo.progressoLeitura = null;
      }

      onsuccess(conteudo);
    });
  }

  public async marcarLido(id: string): Promise<void> {
    const c = this.getConteudo();
    const idLei = Conteudo.getIdLei(c);

    this.usuarioEstatisticasService
      .marcarLido(idLei, id)
      .then((estatisticas) => {
        this.leiRepositorio.carregarItemLookup(idLei).then((lei) => {
          this.calcularProgressoLeiAtual(c, lei, estatisticas);

          c.estatisticas = EntitiesHelper.Copy(estatisticas);
          c.linhas.find((x) => x.id == id).lida =
            estatisticas.linhasLidas.indexOf(id) > -1;
          this.conteudo.next(c);
        });
      });

    this.loggingService.LogEvent("Leitor - Ler linha", null, null);
  }

  public async marcarLidoAteAqui(index: number): Promise<void> {
    const c = this.getConteudo();
    const idLei = Conteudo.getIdLei(c);

    let de = -1;
    const ate = index;

    de = c.linhas.findIndex(
      (l) => !l.lida && !l.versoes[l.versoes.length - 1].revogado
    );

    const linhasAlterar = c.linhas
      .filter(
        (l) =>
          l.index >= de &&
          l.index <= ate &&
          !l.lida &&
          !l.versoes[l.versoes.length - 1].revogado
      )
      .map((l) => l.id);

    this.usuarioEstatisticasService
      .marcarVariosLidos(idLei, linhasAlterar)
      .then((estatisticas) => {
        this.leiRepositorio.carregarItemLookup(idLei).then((lei) => {
          this.calcularProgressoLeiAtual(c, lei, estatisticas);
          c.estatisticas = EntitiesHelper.Copy(estatisticas);
          this.conteudo.next(c);
          this._multipleReadedLinesChanged.next();
        });
      });

    this.loggingService.LogEvent("Leitor - Ler várias linhas", null, null);
  }

  public alterarMatchFocado(match: MatchBuscaTexto): void {
    if (!match) {
      return;
    }

    const busca = EntitiesHelper.Copy(this.getConteudo().busca);
    if (!busca) {
      return;
    }

    busca.matchResultadoBuscaFoco = match;
    const conteudo = this.conteudo.getValue();
    conteudo.busca = busca;

    this.conteudo.next(conteudo);
  }
  public getPreferenciasUsuario() {
    return this.usuarioPreferenciasService.Configuracoes.preferenciasUsuario;
  }

  public alterarPreferenciasUsuario(
    exibirRevogados: boolean,
    exibirComentariosSvm: boolean,
    exibirApenasItensComentados: boolean,
    exibirMeusComentarios: boolean
  ) {
    const config = new PreferenciasUsuario(
      this.usuarioPreferenciasService.Configuracoes.preferenciasUsuario.larguraPapel,
      this.usuarioPreferenciasService.Configuracoes.preferenciasUsuario.idTema,
      exibirRevogados,
      exibirApenasItensComentados,
      exibirMeusComentarios,
      exibirComentariosSvm
    );
    this.usuarioPreferenciasService.alterarPreferenciasUsuario(
      config,
      this.usuarioPreferenciasService.Configuracoes
    );

    const guiaCached = this._LeisGuiasCached.find(
      (g) => g.idGuia == this.conteudo.getValue().idGuia
    );
    this.carregarIndice(guiaCached.lei, exibirRevogados);
  }

  public alterarIndexFoco(index: number) {
    this._indexFoco.next(index);
  }
}

export class ProgressoLeitura {
  public progresso: number;
  public tempoRestante: Tempo;
}

export class LeiGuiacached {
  public lei: Lei;
  public idGuia: string;
}
