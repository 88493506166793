<mat-icon class="error-icon">error_outline</mat-icon>
<h3 class="titulo" mat-dialog-title>{{data.titulo}}</h3>
<div mat-dialog-content>

    <div class="placeholder">
        <span class="mensagem">{{data.mensagem}}</span>
    </div>

    <div class="btn-bar">
        <button mat-raised-button (click)="finalizar()" title="OK" color="primary">OK</button>
    </div>

</div>