<span class="placeholder flex-row center-start">
  <span class="favorito">
    <button mat-icon-button (click)="btnFavorito_click($event)">
      <mat-icon *ngIf="item.favorita">bookmark</mat-icon>
      <mat-icon *ngIf="!item.favorita">bookmark_border</mat-icon>
    </button>
  </span>
  <span class="descricao flex-column flex-1">
    <span class="titulo">{{ item.titulo }}</span>
    <span class="data-alteracao">
      {{ dataAlteracao }}
      <mat-chip-list>
        <mat-chip
          *ngIf="item.atualizacaoPendente"
          class="chip-atualizacao"
        >
          documento em processo de atualização</mat-chip
        >
      </mat-chip-list>
    </span>
  </span>
  <span class="info">
    <button mat-icon-button (click)="btnInfo_click($event, item)">
      <mat-icon>info</mat-icon>
    </button></span
  >
  <span class="progresso">
    <span
      class="placeholder-indicador-leitura flex-row"
      indicador-leitura
      *ngIf="item.progresso >= 0 || tempoRestante"
      [progresso]="item.progresso"
      [tempoRestante]="tempoRestante"
    ></span>
  </span>
</span>
