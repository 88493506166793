<h3 mat-dialog-title>Processando busca</h3>
<div mat-dialog-content>

    <div class="placeholder">
        <div id="busy-msg" [@msgCarregamento]="data.mensagemAtual" *ngIf="data.mensagemAtual" [innerText]="data.mensagemAtual"></div>

        <div id="update-progressbar" *ngIf="data.progress">
            <div id="update-progressbar-step" [style.width]="data.progress + '%'"></div>
        </div>

        <div id="update-msg" [innerText]="data.message"></div>
    </div>

    <div class="btn-bar">
        <button mat-raised-button (click)="cancelar()" title="Parar a busca" color="warn">Parar</button>
    </div>
</div>