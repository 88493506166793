export const environment = {
  production: true,
  serverUrl: "https://beta-api.supervademecum.com.br",
  serverLogUrl: "https://beta-log-api.supervademecum.com.br/api",

  firebase: {
    apiKey: "AIzaSyDMUbdrS1I2LJqOVemXn1J9iIhabjzOvbQ",
    authDomain: "super-vade-mecum-hml.firebaseapp.com",
    databaseURL: "https://super-vade-mecum-hml.firebaseio.com",
    projectId: "super-vade-mecum-hml",
    storageBucket: "super-vade-mecum-hml.appspot.com",
    messagingSenderId: "685396639133",
    appId: "1:685396639133:web:9d061a876dde582d7ac374",
    measurementId: "G-LNPZQ74XNX",
  },
  appInsights: {
    connectionString:
      "InstrumentationKey=e9aaaf80-09a9-453d-88ba-f4e558c5243d;IngestionEndpoint=https://westus-0.in.applicationinsights.azure.com/;LiveEndpoint=https://westus.livediagnostics.monitor.azure.com/",
  },
};
