import { Component, OnInit, OnDestroy } from '@angular/core';
import { LeitorParameters } from './leitor.parameters';
import { PainelLateralService } from '../timer/painel-lateral/painel-lateral.service';
import { Subscription } from 'rxjs';
import { UsuarioPreferenciasService } from '../../services/data-services/usuario.preferencias.service';
import { ConfiguracoesUsuario } from '../../models/usuario/ConfiguracoesUsuario';

@Component({
  selector: 'app-leitor',
  templateUrl: './leitor.component.html',
  styleUrls: ['./leitor.component.scss']
})
export class LeitorComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  public params: LeitorParameters;
  public MostrarPainelLateral: boolean;
  public configuracoes: ConfiguracoesUsuario;

  constructor(
    private painelLateralService: PainelLateralService,
    private usuarioPreferenciasService: UsuarioPreferenciasService
  ) {
    this.params = new LeitorParameters();
  }

  ngOnInit() {
    this.subscriptions.push(this.painelLateralService.$mostrarPainel.subscribe(visivel => this.MostrarPainelLateral = visivel));
    this.subscriptions.push(this.usuarioPreferenciasService.$Configuracoes.subscribe(c => this.configuracoes = c));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.subscriptions = [];
  }

  public painelLateral_dismiss(): void {
    this.painelLateralService.togglePainel();
  }
}
