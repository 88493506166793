import { Injectable } from "@angular/core";
import { NgxIndexedDBService } from "ngx-indexed-db";
import { DataControle, EnumTipoDataControle } from "../models/dataControle";

const STORE_NAME = "datasControle";

@Injectable()
export class DataControleRepositorio {
  constructor(private dbService: NgxIndexedDBService) {}

  async salvar(dataControle: DataControle): Promise<void> {
    this.dbService.update(STORE_NAME, dataControle).toPromise();
  }

  async buscar(tipo: EnumTipoDataControle): Promise<DataControle> {
    return this.dbService
      .getByKey(STORE_NAME, tipo)
      .toPromise()
      .then((dataControle: DataControle) => dataControle);
  }
}
