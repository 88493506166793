<div class="popup-placeholder noselect" (click)="$event.stopPropagation()">
    <div class="header">
        <h3>Meu Perfil<span *ngIf="validarMudancas" title="Alterações pendentes">*</span></h3>
    </div>
    <div class="body">
        <div class="busy-indicator" *ngIf="ocupado">
            <mat-spinner [mode]="'indeterminate'"> </mat-spinner>
            <span *ngIf="carregando">Carregando informações...</span>
            <span *ngIf="salvando">Salvando alterações...</span>
        </div>

        <span *ngIf="model">
      <mat-card style="margin-bottom: 12px;">
        <app-form-identificacao (onsaved)="childForm_saved($event)" (onsaving)="childForm_saving()" (confirmarAlteracoes)="validarMudancas = $event">
        </app-form-identificacao>
      </mat-card>

      <!-- <mat-card style="margin-bottom: 12px;" *ngIf="model && !model.trial">
        <app-form-dados-pessoais [dadosServidor]="model" (onsaved)="childForm_saved($event)" 
          (onsaving)="childForm_saving()" (confirmarAlteracoes)="validarMudancas = $event"></app-form-dados-pessoais>
      </mat-card> -->

      <mat-accordion>
        <app-form-pagamento *ngIf="model && !model.trial" [dadosServidor]="model" (onsaved)="childForm_saved($event)" (onsaving)="childForm_saving()" (confirmarAlteracoes)="validarMudancas = $event">
        </app-form-pagamento>
        <app-form-alterar-senha (onsaved)="childForm_saved($event)" (onsaving)="childForm_saving()" (confirmarAlteracoes)="validarMudancas = $event">
        </app-form-alterar-senha>
        <app-form-indicacoes *ngIf="model && !model.trial" [dadosServidor]="model" (onsaved)="childForm_saved($event, true)"
          (onsaving)="childForm_saving(true)"></app-form-indicacoes>
        <app-form-cancelamento [dadosServidor]="model" (onsaved)="childForm_saved($event, true)"
          (onsaving)="childForm_saving(true)"></app-form-cancelamento>
      </mat-accordion>
    </span>
    </div>
</div>