<div #Pagina id="pagina-container" [style.cursor]="cursor">
  <div *ngIf="conteudo && conteudo.idLei" id="pagina" class="shadow">
    <app-cabecalho
      [indexLinhaAtual]="indexPrimeiroItem"
      [tituloAtual]="ultimoTitulo"
      (focoChange)="carregarConteudo($event)"
      (exibirRevogadosChange)="exibirRevogados = $event"
    >
    </app-cabecalho>
    <!-- LOADING LINES -->
    <div *ngIf="this.carregandoConteudo | async" class="viewport">
      <div class="viewport-carregando">
        <div class="background-masker"></div>
        <div class="background-masker-titulo">
          <div></div>
          <div></div>
        </div>
        <div class="background-masker-bottom-titulo"></div>
        <div class="background-masker-linha-inicio"></div>
        <div
          *ngFor="let item of [].constructor(2); let i = index"
          class="background-masker"
        ></div>
        <div class="background-masker-linha-termino" style="width: 20%"></div>
        <div class="background-masker"></div>
        <div class="background-masker-bottom-titulo"></div>
        <div class="background-masker-linha-inicio"></div>
        <div
          *ngFor="let item of [].constructor(4); let i = index"
          class="background-masker"
        ></div>
        <div class="background-masker-linha-termino" style="width: 50%"></div>
        <div class="background-masker"></div>
        <div class="background-masker-bottom-titulo"></div>
        <div class="background-masker-linha-inicio"></div>
        <div class="background-masker"></div>
        <div class="background-masker-bottom-titulo"></div>
        <div class="background-masker-linha-inicio"></div>
        <div
          *ngFor="let item of [].constructor(2); let i = index"
          class="background-masker"
        ></div>
        <div class="background-masker-linha-termino" style="width: 20%"></div>
        <div class="background-masker"></div>
        <div class="background-masker-bottom-titulo"></div>
        <div class="background-masker-linha-inicio"></div>
        <div class="background-masker"></div>
      </div>
    </div>
    <!-- FIM LOADING LINES -->
    <span *ngIf="IsPrintAdm">
      <button (click)="print()">print</button>
    </span>

    <div *ngIf="!printing" class="viewport" id="viewport">
      <div *uiScroll="let item of linhasPaginaDataSource">
        <div
          *ngIf="showlines(item.id)"
          class="documento-conteudo{{ model.larguraPapel }}"
        >
          <span
            *ngIf="
              exibirRevogados ||
              (!exibirRevogados &&
                !item.versoes[item.versoes.length - 1].revogado)
            "
          >
            <app-marcacao-superior
              [linha]="item"
              [opcoesPonteiros]="opcoesCoresPonteiros"
              [exibirComentariosSvm]="exibirComentariosSvm"
              [exibirMeusComentarios]="exibirMeusComentarios"
              [posicaoAtual]="'Acima'"
            >
            </app-marcacao-superior>
            <div
              [id]="'popover' + item.id"
              [mdePopoverTriggerFor]="appPopover"
              [style.marginLeft.px]="marginLeftPopOver"
              style="pointer-events: initial"
            ></div>
            <app-linha
              *ngIf="
                item.versoes[item.versoes?.length - 1].revogado
                  ? exibirRevogados
                  : !exibirApenasItensComentados ||
                    ((item.comentarios?.length > 0 ||
                      item.comentariosGerenciados?.length > 0) &&
                      exibirApenasItensComentados)
              "
              [id]="'linha' + item.id"
              [linha]="item"
              [exibirApenasItensComentados]="exibirApenasItensComentados"
              [exibirItensRevogados]="exibirItensRevogados"
              [linhaHoverQuiz]="hoverQuiz"
              [comentarioCriar]="
                editandoComentario(item.id) ? comentarioComentando : null
              "
              (on_cancelarComentario)="cancelarComentario()"
              (on_salvarComentario)="salvarComentario($event)"
              (on_openQuiz)="openQuiz($event)"
            ></app-linha>
            <app-marcacao-superior
              [linha]="item"
              [exibirComentariosSvm]="exibirComentariosSvm"
              [exibirMeusComentarios]="exibirMeusComentarios"
              [opcoesPonteiros]="opcoesCoresPonteiros"
              [posicaoAtual]="'Abaixo'"
              (on_salvarReferencia)="salvarReferencia($event)"
              [criarReferencia]="
                editandoReferencia(item.id) ? criarReferencia : null
              "
            >
            </app-marcacao-superior>
          </span>
        </div>
        <div [ngTemplateOutlet]="quiz" *ngIf="item.id == quizArtRange[0]"></div>
      </div>
    </div>
    <div *ngIf="printing" id="print-section">
      <div *ngFor="let item of this.conteudo.linhas">
        <span
          *ngIf="
            exibirRevogados ||
            (!exibirRevogados &&
              !item.versoes[item.versoes.length - 1].revogado)
          "
        >
          <app-marcacao-superior
            [linha]="item"
            [exibirComentariosSvm]="exibirComentariosSvm"
            [exibirMeusComentarios]="exibirMeusComentarios"
            [opcoesPonteiros]="opcoesCoresPonteiros"
            [posicaoAtual]="'Acima'"
          >
          </app-marcacao-superior>
          <div
            [id]="'popover' + item.id"
            [mdePopoverTriggerFor]="appPopover"
            [style.marginLeft.px]="marginLeftPopOver"
            style="pointer-events: initial"
          ></div>
          <app-linha
            *ngIf="
              item.versoes[item.versoes?.length - 1].revogado
                ? exibirRevogados
                : !exibirApenasItensComentados ||
                  ((item.comentarios?.length > 0 ||
                    item.comentariosGerenciados?.length > 0) &&
                    exibirApenasItensComentados)
            "
            [id]="'linha' + item.id"
            [exibirApenasItensComentados]="exibirApenasItensComentados"
            [exibirItensRevogados]="exibirItensRevogados"
            [linha]="item"
            [comentarioCriar]="
              editandoComentario(item.id) ? comentarioComentando : null
            "
            (on_cancelarComentario)="cancelarComentario()"
            (on_salvarComentario)="salvarComentario($event)"
          ></app-linha>
          <app-marcacao-superior
            [linha]="item"
            [exibirComentariosSvm]="exibirComentariosSvm"
            [exibirMeusComentarios]="exibirMeusComentarios"
            [opcoesPonteiros]="opcoesCoresPonteiros"
            [posicaoAtual]="'Abaixo'"
          >
          </app-marcacao-superior>
        </span>
      </div>
    </div>
    <mat-progress-bar
      *ngIf="carregando"
      class="infinite-progress-bar"
      mode="indeterminate"
    ></mat-progress-bar>
  </div>

  <!-- POP OVER MARCAÇÕES -->
  <div
    *ngIf="popOverAberta"
    class="popover-bgd"
    (click)="fecharPopOvers()"
  ></div>
  <mde-popover
    #appPopover="mdePopover"
    mdePopoverPositionY="above"
    [mdePopoverTriggerOn]="false"
    [mdePopoverCloseOnClick]="false"
    [mdePopoverOffsetY]="5"
    style="height: 30px !important; width: 50px !important"
  >
    <!-- BOTÕES MARCAÇÕES -->
    <div *ngIf="!comentarioComentando && !provaMarcando">
      <button
        md-button
        class="btn-popover mat-raised-button material-icon-copy-popover"
        (click)="copyText()"
        (mouseenter)="toggleHoverPopOverButton($event.srcElement)"
        (mouseleave)="toggleHoverPopOverButton($event.srcElement)"
        [style.border-color]="'#c6c2d9'"
        [style.color]="'#c6c2d9'"
        title="Copiar"
      >
        <mat-icon class="icon material-icons-outlined icon-copy icon-popover">
          <span class="material-icons-outlined"> copy_all </span></mat-icon
        >
      </button>
      <button
        md-button
        class="btn-popover mat-raised-button"
        (click)="processarSelecaoMarcacaoComentario(true)"
        style="margin-left: 5px"
        (mouseenter)="toggleHoverPopOverButton($event.srcElement)"
        (mouseleave)="toggleHoverPopOverButton($event.srcElement)"
        [style.border-color]="opcoesCoresPonteiros.corTagMnemonico"
        [style.color]="opcoesCoresPonteiros.corTagMnemonico"
        title="Título"
      >
        <mat-icon class="material-icons-outlined icon icon-titulo icon-popover"
          >title</mat-icon>
      </button>
      <!-- //azure card 358 Desativar botão caiu em prova <button
        md-button
        class="btn-popover mat-raised-button"
        (click)="processarSelecaoMarcacaoProva()"
        style="margin-left: 5px"
        [style.border-color]="opcoesCoresPonteiros.corTagProva"
        (mouseenter)="toggleHoverPopOverButton($event.srcElement)"
        (mouseleave)="toggleHoverPopOverButton($event.srcElement)"
        [style.color]="opcoesCoresPonteiros.corTagProva"
        title="Caiu em prova"
      >
        <div class="icon icon-prova icon-popover"></div>
      </button> -->
      <button
        md-button
        class="btn-popover mat-raised-button"
        (click)="processarSelecaoMarcacaoComentario(false)"
        style="margin-left: 5px"
        (mouseenter)="toggleHoverPopOverButton($event.srcElement)"
        (mouseleave)="toggleHoverPopOverButton($event.srcElement)"
        [style.border-color]="opcoesCoresPonteiros.corTagComentario"
        [style.color]="
          opcoesCoresPonteiros.corTagComentario === '#E9EBF5'
            ? '#0E4875'
            : opcoesCoresPonteiros.corTagComentario
        "
        title="Comentário"
      >
        <div class="icon icon-comentario icon-popover"></div>
      </button>

      <button
        *ngIf="selection?.selector?.selected?.length == 1 && IsAdm"
        md-button
        class="btn-popover mat-raised-button material-icon-referencia-popover"
        (click)="referencia()"
        style="margin-left: 5px"
        (mouseenter)="toggleHoverPopOverButton($event.srcElement)"
        (mouseleave)="toggleHoverPopOverButton($event.srcElement)"
        [style.border-color]="'#c6c2d9'"
        [style.color]="'#c6c2d9'"
        title="referencia"
      >
        <mat-icon
          class="icon material-icons-outlined icon-referencia icon-popover"
        >
          new_label</mat-icon
        >
      </button>
    </div>
    <!--// azure card 358 Desativar botão caiu em prova <div
      *ngIf="provaMarcando"
      class="card-prova"
      id="cadProva"
      [style.marginLeft.px]="marginLeftContentPopOver"
    >
      <ng-container
        *ngIf="carregandoOpcoesProva || !provaNova; else elseTemplate"
      >
        <mat-progress-bar mode="indeterminate"> </mat-progress-bar>
      </ng-container>
      <ng-template #elseTemplate>
        <div
          class="popover-div-prova"
          [style.border-color]="opcoesCoresPonteiros.corTagProva"
        >
          <app-tag-picker
            #instituicaoElement
            class="prova-picker"
            [nullText]="'Instituição'"
            [options]="opcoesProva.instituicoes"
            [selected]="provaMarcando.instituicao"
            (onSelect)="provaMarcando.dados.instituicao = $event"
          >
          </app-tag-picker>
          <app-tag-picker
            class="prova-picker"
            [nullText]="'Banca'"
            [options]="opcoesProva.bancas"
            [selected]="provaMarcando.banca"
            (onSelect)="provaMarcando.dados.banca = $event"
          ></app-tag-picker>
          <app-tag-picker
            class="prova-picker"
            [nullText]="'Ano'"
            [options]="opcoesProva.anos"
            [selected]="provaMarcando.ano"
            (onSelect)="provaMarcando.dados.ano = $event"
          ></app-tag-picker>
          <app-tag-picker
            class="prova-picker"
            [nullText]="'Tipo'"
            [options]="opcoesProva.tipos"
            [selected]="provaMarcando.tipo"
            (onSelect)="provaMarcando.dados.tipo = $event"
          ></app-tag-picker>
          <app-tag-picker
            class="prova-picker"
            [nullText]="'Cargo'"
            [options]="opcoesProva.cargos"
            [selected]="provaMarcando.cargo"
            (onSelect)="provaMarcando.dados.cargo = $event"
          ></app-tag-picker>
        </div>
      </ng-template>
    </div>
  </mde-popover> -->
  <!-- FINAL POP OVER MARCAÇÕES -->
<!-- </div> -->

<app-placeholder-paineis></app-placeholder-paineis>

<ng-template #quiz>
  <div class="quiz-container">
    <app-quiz
      class="quiz-app"
      [quizList]="quizQuestions"
      (on_closeQuiz)="openQuiz()"
    ></app-quiz>
  </div>
</ng-template>
