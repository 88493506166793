<h1 mat-dialog-title>Paleta de cores</h1>
<div mat-dialog-content>

  <div class="row color-box-container">
    <div class="color-box" *ngFor="let cor of data.cores" [style.backgroundColor]="cor" (click)="pick(cor)"></div>
  </div>

  <div class="row color-bar">
    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6" [style.backgroundColor]="corAtual">
      Cor atual
    </div>
    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6" [style.backgroundColor]="corNova ? corNova : corAtual">
      Nova cor
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="cancelar()">Cancelar</button>
  <button mat-button (click)="salvar()">Ok</button>
</div>