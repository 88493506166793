<div class="mat-dialog-container-referencia">
  <div class="form-row-title">
    <h1 mat-dialog-title class="titulo-referencia">
      {{ data.referencia.ref }}
    </h1>

    <img
      class="icon-referencia"
      src="assets\ui\icons\logotipo-super-vade-mecum.png"
    />
    <div
      class="icon icon-close"
      matTooltip="Fechar"
      mat-dialog-close
      align="end"
    ></div>
  </div>
  <div class="form-row">
    <p class="form-item-row">{{ data.tipo }}</p>
  </div>
  <div mat-dialog-content>
    <div class="form-column">
      <app-rich-text
        [editorDicaEstudo]="false"
        [dicaEstudo]="data.referencia.conteudo"
        class="referencia-focus"
      ></app-rich-text>
    </div>
  </div>

  <div *ngIf="data.referencia.link" mat-dialog-actions align="end">
    <span
      [matTooltip]="
        'Em se tratando de conteúdo externo o nosso link pode deixar de funcionar caso o proprietário da página altere a publicação. Se este link não está funcionando, comunique-nos.'
      "
    >
      <a
        mat-button
        mat-dialog-close
        href="{{ data.referencia.link }}"
        target="_blank"
        rel="noopener noreferrer"
        matTooltipPosition="below"
        >Visualizar conteúdo</a
      >
    </span>
  </div>
</div>
