import { Injectable } from "@angular/core";
import {
  BuscaPanelParameters,
  estadoJanelaBusca,
} from "../components/leitor-content-panelbusca/busca-panel.parameters";
import { Intervalo } from "../components/leitor-content-pagina/pagina.parameters";
import { MatchBuscaTexto } from "../models/MatchBuscaTexto";
import { StorageHelper } from "../helpers/storage.helper";
import { BuscaSalva } from "../models/UserData";
import { Observable, BehaviorSubject } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { StringHelper } from "../helpers/string.helper";
import { UsuarioGuiasService } from "./data-services/usuario.guias.service";
import { Guia } from "../models/Guia";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "../app.config";
import { StatusService } from "./status.service";
import {
  CompressedResult,
  CompressionHelper,
} from "../helpers/compression.helper";
import { BuscaRequest } from "../arguments/busca/BuscaRequest";
import { ResultadoBuscaResponse } from "../arguments/busca/ResultadoBuscaResponse";
import { LoggingService } from "./logging.service";

export class ResultadoMarcacaoItem {
  idItem: string;
  intervalos: Array<Intervalo>;

  constructor() {
    this.intervalos = new Array<Intervalo>();
  }
}

@Injectable()
export class BuscaService {
  readonly databaseName = "buscas";
  readonly collectionName = "buscas";

  public isBusy: boolean;
  private matchBuscas: Array<MatchBuscaTexto>;

  //Guilherme - Não subscrever a este observable, utilizar ConteudoService.$Conteudo
  public $Busca: Observable<BuscaPanelParameters>;
  private _busca = new BehaviorSubject<BuscaPanelParameters>(null);
  private guiaAtiva: Guia;

  constructor(
    public dialog: MatDialog,
    private usuarioGuiasService: UsuarioGuiasService,
    private loggingService: LoggingService,
    private statusService: StatusService,
    private httpClient: HttpClient
  ) {
    this.matchBuscas = new Array<MatchBuscaTexto>();
    this.$Busca = this._busca.asObservable();

    usuarioGuiasService.$GuiaAtiva.subscribe((g) => this.guia_subscribe(g));
  }

  private guia_subscribe(guiaAtual: Guia): void {
    const taskname = "carregarBusca";

    if (
      !guiaAtual ||
      (this.guiaAtiva && guiaAtual.idLei === this.guiaAtiva.idLei)
    )
      return;

    this.guiaAtiva = guiaAtual;
    this.fecharPainelBusca(false).then(() => {
      this.carregarBuscaGuia(this.guiaAtiva.id).then((buscaSalva) => {
        let busca = null;
        if (buscaSalva) {
          const busca = BuscaPanelParameters.fromBuscaSalva(buscaSalva);
          if (busca.buscarTodosDocumentos) {
            this.statusService.mostrarMensagemProgresso(
              "Recuperando busca salva",
              taskname
            );
            this.buscar(busca, false).then((resultadoBusca) => {
              if (!resultadoBusca.matchResultadoBuscaFoco)
                resultadoBusca.matchResultadoBuscaFoco =
                  resultadoBusca.matchsResultadoBusca[0];
              this._busca.next(resultadoBusca);
              this.statusService.ocultarMensagemProgresso(taskname);
            });
          } else {
            if (!busca.matchResultadoBuscaFoco)
              busca.matchResultadoBuscaFoco = busca.matchsResultadoBusca[0];

            this._busca.next(busca);
          }
        } else {
          this._busca.next(busca);
        }
      });
    });
  }

  public carregarBuscaGuia(idGuia: string): Promise<BuscaSalva> {
    return new Promise((onsuccess, onerror) => {
      StorageHelper.list<BuscaSalva>(
        this.databaseName,
        this.collectionName,
        (b) => b.guiaId === idGuia
      )
        .then((guias) => onsuccess(guias[0]))
        .catch((err) => onerror(err));
    });
  }

  public abrirPainelBusca(buscaAberta: BuscaPanelParameters = null): void {
    if (!buscaAberta) {
      buscaAberta = new BuscaPanelParameters();
    }
    this._busca.next(buscaAberta);
  }

  public async fecharPainelBusca(apagarBusca: boolean) {
    if (apagarBusca) {
      await this.finalizarBusca();
    }

    this._busca.next(null);
  }

  private async finalizarBusca() {
    if (!this.guiaAtiva) {
      return;
    }

    const buscasArmazenadas = <BuscaSalva[]>await new Promise((onsuccess) => {
      StorageHelper.list<BuscaSalva>(
        this.databaseName,
        this.collectionName
      ).then((buscas) => onsuccess(buscas));
    });

    // deletar versao antiga da busca
    const indexBuscaAtualizar = buscasArmazenadas.findIndex(
      (busca) => busca.guiaId === this.guiaAtiva.id
    );
    if (indexBuscaAtualizar !== -1) {
      await new Promise<void>((onsuccess) => {
        StorageHelper.delete(
          this.databaseName,
          this.collectionName,
          buscasArmazenadas[indexBuscaAtualizar].id
        ).then(() => onsuccess());
      });
    }

    const carregarBuscas = new Promise((onsuccess) => {
      StorageHelper.list<BuscaSalva>(
        this.databaseName,
        this.collectionName
      ).then((buscas) => onsuccess(buscas));
    });

    carregarBuscas.then((buscas: BuscaSalva[]) => {
      const indexBuscaAtualizar = buscas.findIndex(
        (busca) => busca.guiaId === this.usuarioGuiasService.guiaAtiva.id
      );
      if (indexBuscaAtualizar !== -1) {
        StorageHelper.delete(
          this.databaseName,
          this.collectionName,
          buscas[indexBuscaAtualizar].id
        );
      }
    });
  }

  buscar(
    params: BuscaPanelParameters,
    updateObservable = true
  ): Promise<BuscaPanelParameters> {
    const taskname = "buscar";
    return new Promise((onsuccess, onerror) => {
      this.isBusy = true;

      params.textoBuscar = StringHelper.RemoverAcentosCaracteresEspeciais(
        params.textoBuscar
      );

      this.matchBuscas = new Array<MatchBuscaTexto>();

      const guiaAtiva = this.guiaAtiva;
      let idLei = guiaAtiva.idLei;

      if (
        params.buscarTodosDocumentos &&
        params.resultadosBuscaWeb &&
        params.resultadosBuscaWeb.resultadoAtual
      ) {
        idLei = params.resultadosBuscaWeb.resultadoAtual.id;
      }

      if (!params.buscarTodosDocumentos) {
        this.loggingService.LogEvent(
          "Leitor - Buscar documento atual",
          null,
          null
        );
        const dto = new BuscaRequest();

        dto.idLei = idLei;
        dto.texto = params.textoBuscar;

        if (params.provascaiuEmProva.ativo) {
          dto.buscarMarcacoes = true;
          if (params.provaBuscar) {
            if (params.provaBuscar.instituicao)
              dto.idInstituicaoMarcacao = params.provaBuscar.instituicao.id;
            if (params.provaBuscar.banca)
              dto.idBancaMarcacao = params.provaBuscar.banca.id;
            if (params.provaBuscar.ano)
              dto.idAnoMarcacao = params.provaBuscar.ano.id;
            if (params.provaBuscar.tipo)
              dto.idTipoMarcacao = params.provaBuscar.tipo.id;
            if (params.provaBuscar.cargo)
              dto.idCargoMarcacao = params.provaBuscar.cargo.id;
          }
        }

        if (params.comentariosComentado.ativo) {
          dto.buscarComentarios = true;
          dto.textoComentario = params.comentarioBuscar;
        } else {
          dto.buscarComentarios = false;
          dto.textoComentario = null;
        }

        if (params.mnemonicosOpcao.ativo) {
          dto.buscarMnemonicos = true;
          dto.textoMnemonico = params.mnemonicoBuscar;
        } else {
          dto.buscarMnemonicos = false;
          dto.textoMnemonico = null;
        }

        dto.buscarCaneta1 = params.marcacoesCaneta[0].ativo;
        dto.buscarCaneta2 = params.marcacoesCaneta[1].ativo;
        dto.buscarCaneta3 = params.marcacoesCaneta[2].ativo;
        dto.buscarCaneta4 = params.marcacoesCaneta[3].ativo;
        dto.buscarCaneta5 = params.marcacoesCaneta[4].ativo;

        const url = `${AppConfig.apiEndpoint}/busca/v2`;

        if (updateObservable) {
          this.statusService.mostrarMensagemProgresso("Buscando...", taskname);
        }

        this.httpClient
          .post(url, dto)
          .toPromise()
          .then((result: CompressedResult) => {
            params.matchsResultadoBusca = new Array<MatchBuscaTexto>();
            params.matchResultadoBuscaFoco = null;

            if (updateObservable) {
              this.statusService.mostrarMensagemProgresso(
                "Processando resultados...",
                taskname
              );
            }

            const resultadosBusca = <ResultadoBuscaResponse>(
              JSON.parse(CompressionHelper.unzip(result))
            );
            params.estadoJanelaBusca = estadoJanelaBusca.resultadoBuscaSimples;
            params.resultadosBuscaWeb = resultadosBusca;
            if (params.resultadosBuscaWeb.resultadoAtual) {
              params.matchResultadoBuscaFoco = null;
              params.buscaSemResultado =
                resultadosBusca.leisEncontradas.length === 0;

              params.matchsResultadoBusca = [];
              resultadosBusca.nodes.forEach((node) => {
                node.children.forEach((child) => {
                  child.ocorrencias.forEach((ocorrencia) => {
                    const match = new MatchBuscaTexto();

                    match.de = ocorrencia.de;
                    match.ate = ocorrencia.ate;
                    match.cor = "yellow";
                    match.textoItemId = child.id;

                    match.idLei = resultadosBusca.resultadoAtual.id;
                    match.tituloLei = resultadosBusca.resultadoAtual.titulo;

                    params.matchsResultadoBusca.push(match);
                  });
                });
              });
              params.matchResultadoBuscaFoco = params.matchsResultadoBusca[0];

              if (updateObservable) {
                this._busca.next(params);
                this.statusService.ocultarMensagemProgresso(taskname);
              }

              this.isBusy = false;
              onsuccess(params);
            } else {
              params.buscaSemResultado = true;

              if (updateObservable) {
                this._busca.next(params);
                this.statusService.ocultarMensagemProgresso(taskname);
              }

              this.isBusy = false;
              onsuccess(params);
            }
          })
          .catch((err) => {
            this.statusService.setMessage(err.message);
            this.statusService.ocultarMensagemProgresso(taskname);

            this.isBusy = false;
            onerror(err);
          });

        this._busca.next(params);
        this.salvarBusca(new BuscaSalva(params));
      } else {
        this.loggingService.LogEvent(
          "Leitor - Buscar todos documentos",
          null,
          null
        );

        const dto = new BuscaRequest();

        dto.texto = params.textoBuscar;

        if (params.idPagina) dto.pagina = params.idPagina;

        if (params.provascaiuEmProva.ativo) {
          dto.buscarMarcacoes = true;
          if (params.provaBuscar) {
            if (params.provaBuscar.instituicao)
              dto.idInstituicaoMarcacao = params.provaBuscar.instituicao.id;
            if (params.provaBuscar.banca)
              dto.idBancaMarcacao = params.provaBuscar.banca.id;
            if (params.provaBuscar.ano)
              dto.idAnoMarcacao = params.provaBuscar.ano.id;
            if (params.provaBuscar.tipo)
              dto.idTipoMarcacao = params.provaBuscar.tipo.id;
            if (params.provaBuscar.cargo)
              dto.idCargoMarcacao = params.provaBuscar.cargo.id;
          }
        }

        if (params.comentariosComentado.ativo || params.comentarioBuscar) {
          dto.buscarComentarios = true;
          dto.textoComentario = params.comentarioBuscar;
        }

        if (params.mnemonicosOpcao.ativo || params.mnemonicoBuscar) {
          dto.buscarMnemonicos = true;
          dto.textoMnemonico = params.mnemonicoBuscar;
        }

        dto.buscarCaneta1 = params.marcacoesCaneta[0].ativo;
        dto.buscarCaneta2 = params.marcacoesCaneta[1].ativo;
        dto.buscarCaneta3 = params.marcacoesCaneta[2].ativo;
        dto.buscarCaneta4 = params.marcacoesCaneta[3].ativo;
        dto.buscarCaneta5 = params.marcacoesCaneta[4].ativo;

        const url = `${AppConfig.apiEndpoint}/busca/v2`;

        if (updateObservable) {
          this.statusService.mostrarMensagemProgresso("Buscando...", taskname);
        }

        this.httpClient
          .post(url, dto)
          .toPromise()
          .then((result: CompressedResult) => {
            params.matchsResultadoBusca = new Array<MatchBuscaTexto>();
            params.matchResultadoBuscaFoco = null;

            if (updateObservable) {
              this.statusService.mostrarMensagemProgresso(
                "Processando resultados...",
                taskname
              );
            }

            const resultadosBusca = <ResultadoBuscaResponse>(
              JSON.parse(CompressionHelper.unzip(result))
            );

            params.estadoJanelaBusca =
              estadoJanelaBusca.resultadoBuscaAvancadaMinimizado;
            params.resultadosBuscaWeb = resultadosBusca;

            if (params.resultadosBuscaWeb.resultadoAtual) {
              params.matchResultadoBuscaFoco = null;
              params.buscaSemResultado =
                resultadosBusca.leisEncontradas.length === 0;

              params.matchsResultadoBusca = [];
              resultadosBusca.nodes.forEach((node) => {
                node.children.forEach((child) => {
                  child.ocorrencias.forEach((ocorrencia) => {
                    const match = new MatchBuscaTexto();

                    match.de = ocorrencia.de;
                    match.ate = ocorrencia.ate;
                    match.cor = "yellow";
                    match.textoItemId = child.id;

                    match.idLei = resultadosBusca.resultadoAtual.id;
                    match.tituloLei = resultadosBusca.resultadoAtual.titulo;

                    params.matchsResultadoBusca.push(match);
                  });
                });
              });

              if (updateObservable) {
                this._busca.next(params);
                this.statusService.ocultarMensagemProgresso(taskname);
              }

              this.isBusy = false;
              onsuccess(params);
            } else {
              params.buscaSemResultado = true;

              if (updateObservable) {
                this._busca.next(params);
                this.statusService.ocultarMensagemProgresso(taskname);
              }

              this.isBusy = false;
              onsuccess(params);
            }
          })
          .catch((err) => {
            this.statusService.setMessage(err.message);
            this.statusService.ocultarMensagemProgresso(taskname);

            this.isBusy = false;
            onerror(err);
          });
      }
    });
  }

  public async salvarBusca(
    buscaSalvar: BuscaSalva = null,
    idGuia: string = null
  ) {
    if (!this.guiaAtiva && !idGuia) {
      return;
    }

    // Salvar status busca ja aberta
    if (!buscaSalvar && !this._busca.value) return;
    else if (!buscaSalvar) buscaSalvar = new BuscaSalva(this._busca.value);

    // Vincular busca à guia
    buscaSalvar.guiaId = !idGuia ? this.guiaAtiva.id : idGuia;

    const buscasArmazenadas = <BuscaSalva[]>await new Promise((onsuccess) => {
      StorageHelper.list<BuscaSalva>(
        this.databaseName,
        this.collectionName
      ).then((buscas) => {
        onsuccess(buscas);
      });
    });

    // deletar versao antiga da busca
    const indexBuscaAtualizar = buscasArmazenadas.findIndex(
      (busca) => busca.guiaId === buscaSalvar.guiaId
    );
    if (indexBuscaAtualizar !== -1) {
      await new Promise<void>((onsuccess) => {
        StorageHelper.delete(
          this.databaseName,
          this.collectionName,
          buscasArmazenadas[indexBuscaAtualizar].id
        ).then(() => {
          onsuccess();
        });
      });
    }

    // salvar versão nova
    await new Promise((onsuccess) => {
      StorageHelper.upsert(
        buscaSalvar,
        this.databaseName,
        this.collectionName
      ).then((busca) => {
        onsuccess(busca);
      });
    });

    const carregarBuscas = new Promise((onsuccess) => {
      StorageHelper.list<BuscaSalva>(
        this.databaseName,
        this.collectionName
      ).then((buscas) => {
        onsuccess(buscas);
      });
    });

    carregarBuscas.then((buscas: BuscaSalva[]) => {
      const indexBuscaAtualizar = buscas.findIndex(
        (busca) => busca.guiaId === buscaSalvar.guiaId
      );
      if (
        indexBuscaAtualizar !== -1 &&
        buscasArmazenadas[indexBuscaAtualizar]
      ) {
        StorageHelper.delete(
          this.databaseName,
          this.collectionName,
          buscasArmazenadas[indexBuscaAtualizar].id
        );
      }

      StorageHelper.upsert(buscaSalvar, this.databaseName, this.collectionName);
    });
  }
}
