import { TabContentParameters } from '../leitor-content-tabcontent/tabcontent.parameters';

export class LeitorContentParameters {
    tabContentParameters: TabContentParameters;

    constructor(
    ) {
        this.tabContentParameters = new TabContentParameters();
    }
}
