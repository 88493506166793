import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import {
  ListaOpcoesModoUso,
  OpcaoModoUso,
} from "../primeiro-login/primeiro-login.component";
import { EntitiesHelper } from "../../../helpers/entities.helper";
import { UiService } from "../../../services/ui.service";
import { AppConfig } from "../../../app.config";
import { OpcaoToolbar } from "../../leitor-toolbar/opcaoToolbar";
import { TipoSelecao } from "../../../services/selection.service";
import { MatDialog } from "@angular/material/dialog";
import {
  ColorPickerComponent,
  PaletaCoresModel,
} from "../../dialogs/color-picker/color-picker.component";
import { Tema } from "../../../models/tema";
import { TemasApp } from "../../../constants/lista.temas";
import { UsuarioPreferenciasService } from "../../../services/data-services/usuario.preferencias.service";
import { ConfiguracoesUsuario } from "../../../models/usuario/ConfiguracoesUsuario";
import {
  LocalDataService,
  LocalSettings,
} from "../../../services/data-services/local.data.service";
import { DialogoService } from "../dialogo/dialogo.service";
import { DialogoModel } from "../dialogo/dialogo.component";
import { Router } from "@angular/router";
import { PopupsService } from "../popups.service";
import { versoes } from "../../../../../changelog.json";
import { DataControleRepositorio } from "../../../repositorios/dataControle.repositorio";
import { EnumTipoDataControle } from "../../../models/dataControle";
import { StatusService } from "../../../services/status.service";
import { LoggingService } from "../../../services/logging.service";

@Component({
  selector: "app-preferencias",
  templateUrl: "./preferencias.component.html",
  styleUrls: ["../popups-styles.scss", "./preferencias.component.scss"],
})
export class PreferenciasComponent implements OnInit {
  public currentYear: string;
  public posicionamentoMarcacoes = new Array<string>("Acima", "Abaixo");
  public modoRealceCanetas = new Array<string>("Grifar", "Sublinhar");
  public detalhesVersao: Array<string>;
  public model: PreferenciasModel;
  private validarMudancas = false;
  @Output() confirmarAlteracoes = new EventEmitter<boolean>(false);
  localData: LocalSettings;
  localDataCompare: string;
  versoes: Versao[];

  constructor(
    private usuarioPreferenciasService: UsuarioPreferenciasService,
    private popupsService: PopupsService,
    private localDataService: LocalDataService,
    private uiService: UiService,
    private dialogoService: DialogoService,
    private router: Router,
    public dialog: MatDialog,
    private dataControleRepositorio: DataControleRepositorio,
    public statusService: StatusService,
    private loggingService: LoggingService
  ) {
    this.currentYear = new Date().getFullYear().toString();
  }

  public ngOnInit() {
    this.carregarModel();
  }

  private carregarModel() {
    this.model = new PreferenciasModel(
      this.usuarioPreferenciasService.Configuracoes
    );
    this.localData = this.localDataService.carregarConfiguracoes();
    this.carregarVersoes();
  }

  private carregarVersoes() {
    const listaVersoes: Versao[] = [];

    versoes.forEach((v) => {
      const versao: Versao = { numero: v.numero, alteracoes: v.alteracoes };
      versao.numero = v.numero;
      listaVersoes.push(versao);
    });

    this.versoes = listaVersoes;
  }

  modoOffline_changed() {
    if (this.localData.ModoOffline) {
      const model = new DialogoModel();

      model.titulo = "Modo 100% offline";
      model.mensagem =
        "Antes de continuar, todas as leis deverão ser baixadas, isso pode demorar um pouco. Deseja continuar?";

      model.incluirOpcao("Sim", "primary", async () => {
        await this.iniciarModoOffline();
        this.dialogoService.fecharDialogo();
      });

      model.incluirOpcao("Não", "warn", () => {
        this.localData.ModoOffline = false;
        this.dialogoService.fecharDialogo();
      });

      this.dialogoService.mostrarDialogo(model);
    } else {
      this.localDataService.salvarConfiguracoes(this.localData);
      this.dataControleRepositorio.salvar({
        tipo: EnumTipoDataControle.DataVerificacaoModoOffline,
        data: null,
      });
    }
  }

  async iniciarModoOffline() {
    this.localDataService.salvarConfiguracoes(this.localData);
    await this.dataControleRepositorio.salvar({
      tipo: EnumTipoDataControle.DataVerificacaoModoOffline,
      data: null,
    });

    this.cancelar();
    this.tentarFechar(false).then(() => {
      this.popupsService.fecharPopups();
      this.router.navigate(["/downloader"]);
    });
  }

  public alterarTema(tema: Tema): void {
    this.model.temaSelecionado = tema;
    this.uiService.AlterarTema(this.model.temaSelecionado);
  }

  public cancelar(): void {
    this.uiService.AlterarTema(this.model.temaOriginal);
    this.carregarModel();
  }

  public salvar() {
    this.loggingService.LogEvent("Editar preferências", null, null);

    const prefs = this.usuarioPreferenciasService.Configuracoes;

    prefs.idTema = TemasApp.indexOf(this.model.temaSelecionado);

    prefs.palavrasMinuto = this.model.palavrasPorMinuto;
    prefs.perfilUsuario = this.model.opcaoModoUsoSelecionada.tipo;

    prefs.parametrosCaneta.corTagProva = this.model.marcadores[0].cor;
    prefs.parametrosCaneta.realcarMarcacoesProva =
      this.model.marcadores[0].sublinhar;
    prefs.parametrosCaneta.posicionamentoProva =
      this.model.marcadores[0].posicionamento;

    prefs.parametrosCaneta.corTagComentario = this.model.marcadores[1].cor;
    prefs.parametrosCaneta.realcarComentarios =
      this.model.marcadores[1].sublinhar;
    prefs.parametrosCaneta.posicionamentoComentario =
      this.model.marcadores[1].posicionamento;

    prefs.parametrosCaneta.corTagMnemonico = this.model.marcadores[2].cor;
    prefs.parametrosCaneta.realcarMnemonicos =
      this.model.marcadores[2].sublinhar;
    prefs.parametrosCaneta.posicionamentoMnemonico =
      this.model.marcadores[2].posicionamento;

    prefs.parametrosCaneta.corCaneta1 = this.model.grifos[0].cor;
    prefs.parametrosCaneta.descricaoCaneta1 = this.model.grifos[0].apelido;
    prefs.parametrosCaneta.modoRealceCaneta1 = this.model.grifos[0].modoRealce;

    prefs.parametrosCaneta.corCaneta2 = this.model.grifos[1].cor;
    prefs.parametrosCaneta.descricaoCaneta2 = this.model.grifos[1].apelido;
    prefs.parametrosCaneta.modoRealceCaneta2 = this.model.grifos[1].modoRealce;

    prefs.parametrosCaneta.corCaneta3 = this.model.grifos[2].cor;
    prefs.parametrosCaneta.descricaoCaneta3 = this.model.grifos[2].apelido;
    prefs.parametrosCaneta.modoRealceCaneta3 = this.model.grifos[2].modoRealce;

    prefs.parametrosCaneta.corCaneta4 = this.model.grifos[3].cor;
    prefs.parametrosCaneta.descricaoCaneta4 = this.model.grifos[3].apelido;
    prefs.parametrosCaneta.modoRealceCaneta4 = this.model.grifos[3].modoRealce;

    prefs.parametrosCaneta.corCaneta5 = this.model.grifos[4].cor;
    prefs.parametrosCaneta.descricaoCaneta5 = this.model.grifos[4].apelido;
    prefs.parametrosCaneta.modoRealceCaneta5 = this.model.grifos[4].modoRealce;

    prefs.preferenciasUsuario.larguraPapel = this.model.opcaoLarguraPapel;

    this.usuarioPreferenciasService.alterarConfiguracoes(prefs);
    this.carregarModel();
  }

  public resetarConfiguracoes() {
    this.loggingService.LogEvent("Editar preferências", null, null);

    const prefs = this.usuarioPreferenciasService.Configuracoes;

    prefs.idTema = TemasApp.indexOf(this.model.temaSelecionado);

    prefs.palavrasMinuto = this.model.palavrasPorMinuto;
    prefs.perfilUsuario = this.model.opcaoModoUsoSelecionada.tipo;

    prefs.preferenciasUsuario.larguraPapel = this.model.opcaoLarguraPapel;

    this.usuarioPreferenciasService.resetarPreferenciasUsuario();
    this.carregarModel();
  }

  public tentarFechar(salvar: boolean): Promise<void> {
    return new Promise((onSuccess, onFail) => {
      if (salvar) {
        this.salvar();
      } else {
        this.cancelar();
      }

      onSuccess();
    });
  }

  public get alterado(): boolean {
    const valida =
      !EntitiesHelper.equals(
        this.model.temaOriginal,
        this.model.temaSelecionado
      ) ||
      !EntitiesHelper.equals(
        this.model.opcaoModoUsoOriginal,
        this.model.opcaoModoUsoSelecionada
      ) ||
      !EntitiesHelper.equals(
        this.model.palavrasPorMinutoOriginal,
        this.model.palavrasPorMinuto
      ) ||
      !EntitiesHelper.equals(this.model.grifosOriginal, this.model.grifos) ||
      !EntitiesHelper.equals(
        this.model.marcadoresOriginal,
        this.model.marcadores
      ) ||
      !EntitiesHelper.equals(
        this.model.opcaoLarguraPapelOriginal,
        this.model.opcaoLarguraPapel
      )
        ? true
        : false;

    if (valida !== this.validarMudancas) {
      this.confirmarAlteracoes.emit(valida);
      this.validarMudancas = valida;
    }

    return valida;
  }

  public colorPick(item): void {
    const model = new PaletaCoresModel();
    model.tipo = item.tipoMarcacao;
    model.corAtual = item.cor;

    const dialogRef = this.dialog.open(ColorPickerComponent, {
      data: model,
      width: "250px",
    });
    const s_colorPicker = dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        item.cor = result;
      }
      s_colorPicker.unsubscribe();
    });
  }

  public btnAutofix_click() {
    try {
      const model = new DialogoModel();

      model.titulo = "Autofix";
      model.mensagem =
        "Para realizar correções será realizado um logoff um pouco mais demorado. Deseja continuar?";

      model.incluirOpcao("Sim", "primary", async () => {
        this.loggingService.LogEvent("Prefências - Autofix", null, null);
        window.location.href = "auth/logoff?clear=true";
      });

      model.incluirOpcao("Não", "warn", () => {
        this.dialogoService.fecharDialogo();
      });

      this.dialogoService.mostrarDialogo(model);
    } catch (error) {
      throw new Error(
        `Erro em preferenciasComponent.btnAutofix_click ${error?.message}`
      );
    }
  }

  updatedRating(rating: number) {
    this.model.palavrasPorMinuto = rating;
  }
}

export class PreferenciasModel {
  public versaoApp = versoes.sort(function (a, b) {
    if (b.numero > a.numero) {
      return 1;
    } else {
      return 0;
    }
  })[0];

  public opcoesLarguraPapel: string[] = ["3-3", "2-3", "1-3"];
  public opcaoLarguraPapelOriginal: string;
  public opcaoLarguraPapel: string;

  public opcoesModoUso: OpcaoModoUso[] = ListaOpcoesModoUso;
  public temas: Tema[] = TemasApp;

  public temaOriginal: Tema;
  public temaSelecionado: Tema;

  public opcaoModoUsoOriginal: OpcaoModoUso = null;
  public opcaoModoUsoSelecionada: OpcaoModoUso = null;

  public palavrasPorMinutoOriginal: number;
  public palavrasPorMinuto: number;

  public marcadoresOriginal: PreferenciasMarcadorModel[];
  public marcadores: PreferenciasMarcadorModel[];

  public grifosOriginal: PreferenciasMarcadorModel[];
  public grifos: PreferenciasMarcadorModel[];

  constructor(prefs: ConfiguracoesUsuario) {
    this.opcaoLarguraPapelOriginal = prefs.preferenciasUsuario.larguraPapel;
    this.opcaoLarguraPapel = this.opcaoLarguraPapelOriginal;

    this.temaOriginal = this.temas[prefs.idTema];
    this.temaSelecionado = this.temaOriginal;

    this.opcaoModoUsoOriginal = this.opcoesModoUso.find(
      (o) => o.tipo === prefs.perfilUsuario
    );
    this.opcaoModoUsoSelecionada = this.opcaoModoUsoOriginal;

    this.palavrasPorMinutoOriginal = prefs.palavrasMinuto;
    this.palavrasPorMinuto = this.palavrasPorMinutoOriginal;

    this.marcadoresOriginal = OpcaoToolbar.CarregarItensMenu(
      prefs.parametrosCaneta
    )
      .filter(
        (op) =>
          //azure card 358 Desativar botão caiu em prova
          op.tipo === TipoSelecao.Prova ||
          op.tipo === TipoSelecao.Comentario ||
          op.tipo === TipoSelecao.Mnemonico
      )
      .map((m) => {
        let p: string;
        switch (m.tipo) {
          //azure card 358 Desativar botão caiu em prova
          case TipoSelecao.Prova:
            p = prefs.parametrosCaneta.posicionamentoProva;
            break;
          case TipoSelecao.Comentario:
            p = prefs.parametrosCaneta.posicionamentoComentario;
            break;
          case TipoSelecao.Mnemonico:
            p = prefs.parametrosCaneta.posicionamentoMnemonico;
            break;
        }
        if (!p) {
          p = "Acima";
        }

        return {
          icone: m.icone,
          tipoMarcacao: m.tipo,
          iconeColorPicker: 'url("assets/ui/icons/ic-color-picker-normal.png")',
          cor: m.cor,
          sublinhar: m.realcarMarcacao,
          apelido: m.descricao,
          posicionamento: p,
          modoRealce: null,
        };
      });

    this.grifosOriginal = OpcaoToolbar.CarregarItensMenu(prefs.parametrosCaneta)
      .filter(
        (op) =>
          op.tipo === TipoSelecao.Caneta1 ||
          op.tipo === TipoSelecao.Caneta2 ||
          op.tipo === TipoSelecao.Caneta3 ||
          op.tipo === TipoSelecao.Caneta4 ||
          op.tipo === TipoSelecao.Caneta5
      )
      .map((g) => {
        let r: string;
        switch (g.tipo) {
          case TipoSelecao.Caneta1:
            r = prefs.parametrosCaneta.modoRealceCaneta1;
            break;
          case TipoSelecao.Caneta1:
            r = prefs.parametrosCaneta.modoRealceCaneta1;
            break;
          case TipoSelecao.Caneta2:
            r = prefs.parametrosCaneta.modoRealceCaneta2;
            break;
          case TipoSelecao.Caneta3:
            r = prefs.parametrosCaneta.modoRealceCaneta3;
            break;
          case TipoSelecao.Caneta4:
            r = prefs.parametrosCaneta.modoRealceCaneta4;
            break;
          case TipoSelecao.Caneta5:
            r = prefs.parametrosCaneta.modoRealceCaneta5;
            break;
        }
        if (!r) {
          r = "Grifar";
        }

        return {
          icone: g.icone,
          tipoMarcacao: g.tipo,
          iconeColorPicker: 'url("assets/ui/icons/ic-color-picker-normal.png")',
          cor: g.cor,
          sublinhar: false,
          apelido: g.descricao,
          posicionamento: null,
          modoRealce: r,
        };
      });

    this.marcadores = EntitiesHelper.Copy(this.marcadoresOriginal);
    this.grifos = EntitiesHelper.Copy(this.grifosOriginal);
  }
}

export class PreferenciasMarcadorModel {
  icone: string;
  tipoMarcacao: TipoSelecao;
  iconeColorPicker = 'url("assets/ui/icons/ic-color-picker-normal.png")';
  cor: string;
  sublinhar: boolean;
  apelido: string;
  modoRealce: string;
  posicionamento: string;
}

export class Versao {
  numero: string;
  alteracoes: string[];
}
