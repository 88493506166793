import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppConfig } from "src/app/app.config";
import { ErrorHandlerService } from "../errorHandler.service";
import { ErrorLogParameters } from "src/app/models/error/errorLogParameters";
import { ReferenciasGerenciadas } from "src/app/models/ReferenciasGerenciadas";
import { StorageHelper } from "src/app/helpers/storage.helper";

@Injectable()
export class UsuarioReferenciasGerenciadasService {
  readonly REFERENCIA_GERENCIADA_CONTEUDO_STORE =
    "referencias-gereciada-conteudo";
  readonly DATABASE_NAME = "svdm";

  constructor(
    private httpClient: HttpClient,
    private errorHandlerService: ErrorHandlerService
  ) {}

  public carregarReferenciasGerenciadasPorLei(
    leiId: string
  ): Promise<ReferenciasGerenciadas> {
    return new Promise(async (onsuccess, onerror) => {
      const url = `${AppConfig.apiEndpoint}/Referencias/ReferenciasGerenciadas`;
      let requestParams = new HttpParams();

      const referencia: ReferenciasGerenciadas =
        await StorageHelper.getByKey<ReferenciasGerenciadas>(
          leiId,
          this.DATABASE_NAME,
          this.REFERENCIA_GERENCIADA_CONTEUDO_STORE
        ).then((referencia: ReferenciasGerenciadas) => referencia);
      requestParams = requestParams.append("IdLei", leiId);
      requestParams = requestParams.append(
        "DataUltimaAtualizacao",
        referencia
          ? referencia.dataUltimaAtualizacao
          : new Date(0).toISOString()
      );
      await this.httpClient
        .get(url, { params: requestParams })
        .toPromise()
        .then((result: ReferenciasGerenciadas) => {
          if (result) {
            const referen = new ReferenciasGerenciadas(result);
            this.salvarReferenciasGerenciadasLocal(referen).catch((err) => {
              onerror(err);
            });
            onsuccess(referen);
          }
          if (referencia) {
            onsuccess(referencia);
          } else {
            onsuccess(
              new ReferenciasGerenciadas({
                id: "",
                dataHoraModificacao: null,
                removido: false,
                idLei: "",
                dataUltimaAtualizacao: "",
                referencias: [],
              })
            );
          }
        })
        .catch((err) => {
          this.errorHandlerService.handleError(err);
          onsuccess(
            new ReferenciasGerenciadas({
              id: "",
              dataHoraModificacao: null,
              removido: false,
              idLei: "",
              dataUltimaAtualizacao: "",
              referencias: [],
            })
          );
        });
    });
  }

  public salvarReferenciasGerenciadasLocal(
    referencia: ReferenciasGerenciadas
  ): Promise<void> {
    return new Promise(async (onsuccess, onerror) => {
      await StorageHelper.upsert<ReferenciasGerenciadas>(
        referencia,
        this.DATABASE_NAME,
        this.REFERENCIA_GERENCIADA_CONTEUDO_STORE
      ).catch((err) => onerror(err));
    });
  }

  public buscarReferenciasGerenciadasLocal(): Promise<
    ReferenciasGerenciadas[]
  > {
    return new Promise(async (onsuccess, onerror) => {
      await StorageHelper.list<ReferenciasGerenciadas>(
        this.DATABASE_NAME,
        this.REFERENCIA_GERENCIADA_CONTEUDO_STORE
      )
        .then((referencias: ReferenciasGerenciadas[]) => {
          onsuccess(referencias);
        })
        .catch((err) => {
          onerror(err);
        });
    });
  }

  public buscarReferenciasGerenciadasLocalById(
    leiId: string
  ): Promise<ReferenciasGerenciadas> {
    return new Promise(async (onsuccess, onerror) => {
      await StorageHelper.getByKey<ReferenciasGerenciadas>(
        leiId,
        this.DATABASE_NAME,
        this.REFERENCIA_GERENCIADA_CONTEUDO_STORE
      )
        .then((referencia: ReferenciasGerenciadas) => onsuccess(referencia))
        .catch((err) => {
          onerror(err);
        });
    });
  }
}
