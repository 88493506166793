<mat-expansion-panel (opened)="abrir()" (closed)="fechar()">
  <mat-expansion-panel-header>
    <mat-panel-title> Pagamento </mat-panel-title>
    <mat-panel-description>
      <span>{{ bandeiraCartao }}</span>
      <span>&nbsp; {{ finalCartao }}</span>
    </mat-panel-description>
  </mat-expansion-panel-header>

  <div *ngIf="alterar">
    <div class="row">
      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
        <mat-form-field>
          <input
            matInput
            placeholder="Número do cartão"
            [(ngModel)]="model.numero"
            mask="9999 9999 9999 9999"
          />
        </mat-form-field>
      </div>
      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
        <mat-form-field>
          <input
            matInput
            placeholder="Nome impresso no cartão"
            [(ngModel)]="model.nomeCartao"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
        <mat-form-field>
          <mat-select placeholder="Bandeira" [(ngModel)]="model.bandeira">
            <mat-option *ngFor="let bandeira of bandeiras" [value]="bandeira">{{
              bandeira.descricao
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
        <mat-form-field>
          <input matInput placeholder="Código" [(ngModel)]="model.codigo" />
        </mat-form-field>
      </div>

      <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
        <mat-form-field>
          <input
            matInput
            placeholder="Vencimento"
            [(ngModel)]="model.vencimento"
            [textMask]="maskMesAno"
          />
        </mat-form-field>
      </div>
    </div>

    <!-- dados pessoais -->
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <mat-form-field>
          <input
            matInput
            placeholder="CPF"
            [(ngModel)]="modelDadosPessoais.cpf"
            mask="000.000.000-00"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
        <mat-form-field>
          <input
            matInput
            placeholder="Telefone"
            [(ngModel)]="modelDadosPessoais.telefone"
            [textMask]="modelDadosPessoais.maskCelular"
          />
        </mat-form-field>
      </div>

      <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
        <mat-form-field>
          <input
            matInput
            placeholder="CEP"
            [(ngModel)]="model.cep"
            (blur)="cep_blur()"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
        <mat-form-field>
          <input
            matInput
            placeholder="Logradouro"
            [(ngModel)]="model.logradouroEndereco"
          />
        </mat-form-field>
      </div>
      <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
        <mat-form-field>
          <input
            matInput
            placeholder="Número"
            [(ngModel)]="model.numeroEndereco"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="column">
        <mat-form-field>
          <input
            matInput
            placeholder="Complemento"
            [(ngModel)]="model.complemento"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
        <mat-form-field>
          <input matInput placeholder="Bairro" [(ngModel)]="model.bairro" />
        </mat-form-field>
      </div>
      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
        <mat-form-field>
          <input
            matInput
            placeholder="Cidade"
            [(ngModel)]="model.cidadeEndereco"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
        <mat-form-field appearance="">
          <mat-label>UF</mat-label>
          <mat-select placeholder="UF" [(ngModel)]="model.uf">
            <mat-option *ngFor="let estado of listaUfs" [value]="estado">
              {{ estado }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
      <mat-slide-toggle [(ngModel)]="alterar">Alterar cartão</mat-slide-toggle>
    </div>
    <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
      <div class="button-bar" *ngIf="alterar">
        {{ this.confirmarAlteracoes.next(true) }}
        <button mat-raised-button color="warn" (click)="cancelar()">
          Cancelar
        </button>
        <button
          mat-raised-button
          color="primary"
          (click)="salvar()"
          *ngIf="model.valido && modelDadosPessoais.valido"
        >
          Salvar
        </button>
      </div>
    </div>
  </div>
</mat-expansion-panel>
