import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InfoLeiComponent, InfoLeiModel } from '../info-lei/info-lei.component';
import { EstatisticasLeitura, Tempo } from '../../../../models/usuario/EstatisticasLeitura';
import { UsuarioPreferenciasService } from '../../../../services/data-services/usuario.preferencias.service';
import { LeiLookup } from '../../../../models/lei/lei.lookup';
import { UsuarioApontamentosService } from '../../../../services/data-services/usuario.apontamentos.service';
import { Apontamento } from '../../../../services/timer.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-item-menu-nova-guia',
  templateUrl: './item-menu-nova-guia.component.html',
  styleUrls: ['./item-menu-nova-guia.component.scss']
})
export class ItemMenuNovaGuiaComponent implements OnInit {
  @Input() item: LeiLookup;

  @Output() favorito_mudou: EventEmitter<boolean> = new EventEmitter();
  tempoRestante: Tempo;
  dataAlteracao: string;

  constructor(
    public dialog: MatDialog,
    public datePipe: DatePipe,
    private usuarioPreferenciasService: UsuarioPreferenciasService,
    private usuarioApontamentosService: UsuarioApontamentosService
  ) { }

  ngOnInit() {
    this.carregarInfo()
  }

  carregarInfo(): Promise<void> {
    return new Promise(async resolve => {
      this.tempoRestante = EstatisticasLeitura.CalcularTempoRestante(this.usuarioPreferenciasService.Configuracoes.palavrasMinuto, this.item.progresso, this.item.quantidadePalavras)
      
      const data = this.item.dataHoraUltimaModificacaoOficial ? this.datePipe.transform(this.item.dataHoraUltimaModificacaoOficial, 'yyyyMMdd', 'GMT') : null
      this.dataAlteracao = `Última alteração indefinida`
      if (data) {
        const dia = Number.parseInt(data.substr(6,2));
        const mes = Number.parseInt(data.substr(4,2));
        const ano = Number.parseInt(data.substr(0,4));

        //Leis com data 01/01/xxxx possuem apenas o ano identificado corretamente
        if (dia && mes && ano && (dia !== 1 || mes !== 1))
          this.dataAlteracao = `Última alteração em ${this.pad(dia, 2)}/${this.pad(mes, 2)}/${ano}`
        else if (ano && ano > 1)
          this.dataAlteracao = `Última alteração em ${ano}`
      }

      resolve()
    })
  }

  public btnFavorito_click(evt: Event): void {
    evt.stopPropagation();
    this.favorito_mudou.emit(!this.item.favorita);
  }

  public btnInfo_click(evt: Event, item: LeiLookup) {
    evt.stopPropagation();

    const model = new InfoLeiModel();
    model.idLei = item.id;
    model.nomeLei = item.titulo;
    model.dataAtualizacao = this.dataAlteracao;
    model.qtdPalavras = item.quantidadePalavras;
    model.progressoLido = this.item.progresso;

    const config = this.usuarioPreferenciasService.Configuracoes;
    if (config) {
      model.tempoRestante = Tempo.toTimeString(EstatisticasLeitura.CalcularTempoRestante(config.palavrasMinuto, this.item.progresso, item.quantidadePalavras));
      model.tempoTotal = Tempo.toTimeString(EstatisticasLeitura.CalcularTempoTotal(config.palavrasMinuto, item.quantidadePalavras));
    }

    model.tempoTimer = "00:00:00";
    let tempoTotalSecAux = 0;
    this.usuarioApontamentosService.buscar(item.id).then(apontamentos => {
      
      if (apontamentos) {
        apontamentos.forEach(ap => {
            const a = Apontamento.fromJSON(JSON.stringify(ap));
            tempoTotalSecAux += a.tempoTotalSegundos;
        });
      }

      model.tempoTimer = Tempo.toTimeStringFromSeconds(tempoTotalSecAux);
    });

    this.dialog.open(InfoLeiComponent, {
      width: '420px',
      data: model
    });
  }

  pad(num, size) {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }
}
