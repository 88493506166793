<mat-expansion-panel (opened)="abrir()" (closed)="fechar()">
  <mat-expansion-panel-header>
    <mat-panel-title>
      Alterar senha
    </mat-panel-title>
  </mat-expansion-panel-header>

  <div [formGroup]="frmGroup">
  <div class="row">
    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
      <mat-form-field>
        <input matInput placeholder="Senha atual" type="password" formControlName="senhaAtual">
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
      <mat-form-field>
        <!-- <input matInput placeholder="Nova senha" type="password" [(ngModel)]="model.senhaNova"> -->
        <input matInput placeholder="Nova senha" type="password" formControlName="senha">
        <mat-error *ngIf="frmGroup.controls['senha'].hasError('minlength') || frmGroup.controls['senha'].hasError('minlength')">
          A nova senha precisa ter entre 6 e 12 caracteres
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
      <mat-form-field>
        <input matInput placeholder="Confirme a senha" type="password"  formControlName="confirmaSenha">
        <mat-error *ngIf="frmGroup.controls['confirmaSenha'].hasError('MatchPassword')">
          As senhas digitadas não correspondem
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row" *ngIf="frmGroup.valid">
    {{this.confirmarAlteracoes.next(true)}}
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 button-bar">
      <button mat-raised-button color="warn" (click)="cancelar()">Cancelar</button>
      <button mat-raised-button color="primary" (click)="salvar()">Salvar</button>
    </div>
  </div>
  </div>
</mat-expansion-panel>