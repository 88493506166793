import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export class FormControlValidators {
    static cpf(control: AbstractControl): ValidationErrors | null {
        if (!control.value)
            return null

        const valorCpf = (<string>control.value).split('.').join('').replace('-', '')

        if (valorCpf.length != 11)
            return { 'erro': 'Formato incorreto' }

        let valores = [
            parseInt(valorCpf[0]) * 10,
            parseInt(valorCpf[1]) * 9,
            parseInt(valorCpf[2]) * 8,
            parseInt(valorCpf[3]) * 7,
            parseInt(valorCpf[4]) * 6,
            parseInt(valorCpf[5]) * 5,
            parseInt(valorCpf[6]) * 4,
            parseInt(valorCpf[7]) * 3,
            parseInt(valorCpf[8]) * 2
        ]

        let soma = 0
        valores.forEach(valor => soma += valor)
        let resto = `${soma * 10 % 11}`
        if(resto == '10' || resto == '11')
            resto = '0'

        if (resto != valorCpf[9])
            return { 'erro': 'Formato incorreto' }

        valores = [
            parseInt(valorCpf[0]) * 11,
            parseInt(valorCpf[1]) * 10,
            parseInt(valorCpf[2]) * 9,
            parseInt(valorCpf[3]) * 8,
            parseInt(valorCpf[4]) * 7,
            parseInt(valorCpf[5]) * 6,
            parseInt(valorCpf[6]) * 5,
            parseInt(valorCpf[7]) * 4,
            parseInt(valorCpf[8]) * 3,
            parseInt(valorCpf[9]) * 2
        ]

        soma = 0
        valores.forEach(valor => soma += valor)
        resto = `${soma * 10 % 11}`
        if(resto == '10' || resto == '11')
            resto = '0'

        if (resto != valorCpf[10])
            return { 'erro': 'Formato incorreto' }

        return null
    }


    static diaMesAno(control: AbstractControl): ValidationErrors | null {
        if (!control.value)
            return null

        const valor = (<string>control.value)

        if (valor.length != 10)
            return { 'erro': 'Formato incorreto' }

        try {
            const spl = valor.split('/')
            const dia = parseInt(spl[0])
            const mes = parseInt(spl[1])
            const ano = parseInt(spl[2])

            const data = new Date(ano, mes, dia)
        } catch (error) {
            return { 'erro': 'Formato incorreto' }
        }

        return null
    }

    static validadeCartao(control: AbstractControl): ValidationErrors | null {
        if (!control.value)
            return null
            
        const valor = (<string>control.value)

        if (valor.length != 5)
            return { 'erro': 'Formato incorreto' }

        try {
            const spl = valor.split('/')
            const mes = parseInt(spl[1])
            const ano = parseInt('20' + spl[2])

            const data = new Date(ano, mes)
        } catch (error) {
            return { 'erro': 'Formato incorreto' }
        }

        return null
    }

    static cep(control: AbstractControl): ValidationErrors | null {
        if (!control.value)
            return null
            
        const valor = (<string>control.value)
        if (valor.length != 10)
            return { 'erro': 'Formato incorreto' }

        return null
    }
}