import { Injectable } from "@angular/core";
import { Router, NavigationStart } from "@angular/router";
import { Subject ,  Observable } from "rxjs";

@Injectable()
export class IndicatorsService {
    private alertSubject = new Subject<any>();
    private loadingInterstitialSubject = new Subject<any>();

    constructor(private router: Router) {
        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.loadingInterstitialSubject.next();
                this.alertSubject.next();
            }
        });
    }

    get GetLoadingInterstitialState(): Observable<any> {
      return this.loadingInterstitialSubject.asObservable();
    }

    get GetAlertState(): Observable<any> {
      return this.alertSubject.asObservable();
    }

    showInterstitial() {
        this.loadingInterstitialSubject.next({});
    }

    hideInterstitial() {
        this.loadingInterstitialSubject.next();
    }

    alertSuccess(message: string) {
        this.alertSubject.next({ type: 'success', text: message });
    }

    alertError(message: string) {
        this.alertSubject.next({ type: 'error', text: message });
    }
}