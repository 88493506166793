import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { switchMap } from "rxjs/operators";
import { AppConfig } from "../../../app.config";
import { ConfiguracoesUsuario } from "../../../models/usuario/ConfiguracoesUsuario";
import { CadastroUsuarioPlanoRequest } from "../../auth/models/CadastroUsuarioPlanoRequest.model";
import { UserLoginRequest } from "../arguments/user/user-login.request";
import { UserLoginResponse } from "../arguments/user/user-login.response";
import { ERROR_MESSAGES } from "../resources/messages";

@Injectable()
export class AuthService {
  $Token: Observable<string>;
  Token = new Subject<string>();

  constructor(private httpClient: HttpClient) {
    this.$Token = this.Token.asObservable();
  }

  async mailLogin(request: UserLoginRequest): Promise<UserLoginResponse> {
    return await new Promise<UserLoginResponse>((resolve) => {
      const response = new UserLoginResponse();
      this.httpClient
        .post(`${AppConfig.apiEndpoint}/auth/login`, request)
        .toPromise()
        .then((result: any) => {
          Object.assign(response, result.accessToken.value);
          this.setAccessToken(response);
        })
        .catch((error: HttpErrorResponse) => {
          response.erro = ERROR_MESSAGES[error.error];
        })
        .finally(() => {
          resolve(response);
        });
    });
  }

  async alterarSenhaPrimeiroAcesso(novaSenha: string): Promise<void> {
    const result = <{ email: string }>(
      await this.httpClient
        .post(`${AppConfig.apiEndpoint}/auth/alterar-senha-primeiro-acesso`, {
          senha: novaSenha,
        })
        .toPromise()
    );
    const model = new UserLoginRequest();
    model.email = result.email;
    model.senha = novaSenha;

    const loginResponse = await this.mailLogin(model);
  }

  refreshToken(email: string, key: string): Observable<string> {
    const url = `${AppConfig.apiEndpoint}/auth/refresh-token`;
    const model = {
      Email: email,
      Token: key,
    };

    return this.httpClient.post(url, model).pipe(
      switchMap((result: any) => {
        const response = new UserLoginResponse();
        Object.assign(response, result.accessToken.value);

        this.setAccessToken(response);
        return response.token;
      })
    );
  }

  logoff(): void {
    window.location.replace("auth/logoff");
  }

  private setAccessToken(value: UserLoginResponse) {
    const json = JSON.stringify(value);
    localStorage.setItem("usertoken", btoa(json));
    this.Token.next(value ? value.token : null);
  }

  atualizaProblemaPagamento(value: UserLoginResponse) {
    value.problemaPagamento = false;
    this.setAccessToken(value);
  }

  get accessToken(): UserLoginResponse {
    const encodedStr = localStorage.getItem("usertoken");
    let token = null;

    if (encodedStr) {
      const json = atob(encodedStr);
      token = new UserLoginResponse();
      Object.assign(token, JSON.parse(json));
    }

    return token;
  }

  get userID() {
    let retorno = null;

    let json = localStorage.getItem("config");
    if (json) {
      retorno = (<ConfiguracoesUsuario>JSON.parse(json)).id;
    }

    return retorno;
  }

  userIsLoggedIn(): boolean {
    const token = this.accessToken;
    return token ? true : false;
  }

  userHasValidAccess(): boolean {
    return true;
  }

  isUserFirstAccess(): boolean {
    const token = this.accessToken;
    return token.cadastroAtivo ? false : true;
  }

  public async register(request: CadastroUsuarioPlanoRequest) {
    try {
      const planoTrial = request.tipoPlano == "trial";
      const response = await this.httpClient
        .post(`${AppConfig.apiEndpoint}/auth/signup`, request)
        .toPromise();
    } catch (error) {
      if (error.error) throw error.error;
      throw error;
    }
  }

  async solicitarTokenRedefinicaoSenha(email: string) {
    await this.httpClient
      .post(`${AppConfig.apiEndpoint}/auth/verify-email`, `\"${email}\"`, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      })
      .toPromise();
  }

  async validarTokenRedefinicaoSenha(email: string, token: string) {
    const payload = {
      email: email,
      codigo: token,
    };

    await this.httpClient
      .post(`${AppConfig.apiEndpoint}/auth/verify-code`, payload)
      .toPromise();
  }

  async redefinirSenha(
    email: string,
    token: string,
    senha: string,
    confirmaSenha: string
  ) {
    const payload = {
      email: email,
      codigo: token,
      senha: senha,
      confirmaSenha: confirmaSenha,
    };

    await this.httpClient
      .post(`${AppConfig.apiEndpoint}/auth/redefine-password`, payload)
      .toPromise();
  }
}
