import { BaseObj } from './UserdataSync';

export class Guia extends BaseObj {
    idLei: string;
    idClient: string;

    titulo: string;
    ativa: boolean;
    ordem: number;


    hover: boolean;

    constructor() {
        super();

        this.titulo = 'Nova guia';
        this.ordem = new Date().getTime();
    }
}
