import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class FeatureFlagService {
  constructor() {}

  public IsAdm(email: string): boolean {
    switch (email) {
      case "brunohbelo@hotmail.com":
        return true;
      case "henrique-tavares@outlook.com":
        return true;
      case "filipe.pavan@gmail.com":
        return true;
      case "marcelo.bedin@outlook.com":
        return true;
      default:
        return false;
    }
  }

  public IsPrintAdm(email: string): boolean {
    switch (email) {
      case "srtbelo@gmail.com":
        return true;
      case "lhtm@outlook.com":
          return true;
      default:
        return false;
    }
  }
}
