import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { Subscription } from "rxjs";
import { MaskHelper } from "../../../../helpers/mask.helper";
import {
  DadosPessoaisServidorModel,
  SolicitacaoAlteracaoDados,
  DadosCartaoCredito,
  DadosPessoais,
} from "../perfil.component";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppConfig } from "../../../../app.config";
import { WebserviceHelper } from "../../../../helpers/webservice.helper";
import { ListaUfs } from "src/app/constants/lista.ufs";

@Component({
  selector: "app-form-pagamento",
  templateUrl: "./form-pagamento.component.html",
  styleUrls: ["./form-pagamento.component.scss"],
})
export class FormPagamentoComponent implements OnInit, OnDestroy {
  private headers = new HttpHeaders({
    "content-type": "application/json",
  });

  @Input() public dadosServidor: DadosPessoaisServidorModel;

  @Output() public onsaving = new EventEmitter();
  @Output() public onsaved = new EventEmitter<string>();
  @Output() confirmarAlteracoes = new EventEmitter<boolean>(false);

  private s_user: Subscription;

  public bandeiraCartao: string;
  public finalCartao: string;

  public alterar: boolean;
  public model: PagamentoModel;
  public modelDadosPessoais: DadosPessoaisModel;
  public bandeiras = BandeiraCartao.listarBandeiras();

  public maskMesAno = MaskHelper.mesAno;

  public listaUfs = ListaUfs;

  constructor(private httpClient: HttpClient) {}

  public ngOnInit(): void {
    this.carregarModel();
  }

  private carregarModel(carregarDadosCartao = true) {
    if (carregarDadosCartao) {
      this.bandeiraCartao = this.dadosServidor.resumoCartao.bandeiraCartao;
      this.finalCartao = this.dadosServidor.resumoCartao.finalCartao;
    }

    this.model = new PagamentoModel();
    this.modelDadosPessoais = new DadosPessoaisModel(
      this.dadosServidor.dadosPessoais
    );
    this.alterar = false;
  }

  public ngOnDestroy(): void {}

  public abrir(): void {
    this.carregarModel();
  }

  public fechar(): void {}

  public cancelar(): void {
    this.carregarModel();
  }

  public salvar(): void {
    this.onsaving.emit();

    const dadosPessoais = DadosPessoais.fromDadosPessoaisModel(
      this.modelDadosPessoais
    );
    const dadosCartao = DadosCartaoCredito.fromPagamentoModel(
      this.model,
      this.modelDadosPessoais.cpf
    );
    const solicitacao = new SolicitacaoAlteracaoDados();
    solicitacao.dadosPessoais = dadosPessoais;
    solicitacao.dadosCartaoCredito = dadosCartao;
    solicitacao.planoContratadoId = this.modelDadosPessoais.planoContratadoId;

    this.httpClient
      .post(AppConfig.apiEndpoint + "/DadosCadastroUsuario", solicitacao, {
        headers: this.headers,
      })
      .toPromise()
      .then((response: any) => {
        this.onsaved.emit(
          "Sua nova forma de pagamento foi salva com sucesso! Ela será validada na próxima renovação da sua assinatura"
        );

        this.bandeiraCartao = this.model.bandeira.descricao;
        this.finalCartao = this.model.numero;
        while (this.finalCartao.length > 4) {
          this.finalCartao = this.finalCartao.substring(1);
        }

        this.carregarModel(false);
      })
      .catch((err) => {
        this.onsaved.emit(err.error);
        this.carregarModel();
      });
  }

  cep_blur() {
    WebserviceHelper.consultaCep(this.model.cep).then((result) => {
      if (result) {
        this.model.cep = result.cep;
        this.model.logradouroEndereco = result.logradouro;
        this.model.bairro = result.bairro;
        this.model.cidadeEndereco = result.cidade;
        this.model.uf = result.estado;
        this.model.siglaPais = "BR";
      }
    });
  }
}

export class PagamentoModel {
  public bandeira: BandeiraCartao;
  public numero: string;
  public codigo: string;
  public vencimento: string;
  public nomeCartao: string;

  public cep: string;
  public logradouroEndereco: string;
  public numeroEndereco: string;
  public complemento: string;
  public bairro: string;
  public cidadeEndereco: string;
  public uf: string;
  public siglaPais: string;

  public get valido(): boolean {
    return this.bandeira &&
      this.numero &&
      this.numero.length > 0 &&
      this.codigo &&
      this.codigo.length > 0 &&
      this.vencimento &&
      this.vencimento.length > 0 &&
      this.nomeCartao &&
      this.nomeCartao.length > 0 &&
      this.cep &&
      this.cep.length > 0 &&
      this.logradouroEndereco &&
      this.logradouroEndereco.length > 0 &&
      this.numeroEndereco &&
      this.numeroEndereco.length > 0 &&
      this.bairro &&
      this.bairro.length > 0 &&
      this.cidadeEndereco &&
      this.cidadeEndereco.length > 0 &&
      this.uf &&
      this.uf.length > 0
      ? true
      : false;
  }
}

export class BandeiraCartao {
  public id: number;
  public descricao: string;

  public static listarBandeiras(): BandeiraCartao[] {
    return [
      { id: 1, descricao: "VISA" },
      { id: 2, descricao: "AMEX" },
      { id: 3, descricao: "MASTERCARD" },
      { id: 4, descricao: "ELO" },
      { id: 5, descricao: "HIPERCARD" },
      { id: 6, descricao: "DINERS" },
    ];
  }
}

export class DadosPessoaisModel {
  private cpf_original: string;
  private telefone_original: string;

  constructor(dados: DadosPessoais) {
    this.cpf_original = dados.cpf;
    this.cpf = this.cpf_original;

    this.telefone_original = dados.telefone;
    this.telefone = this.telefone_original;

    this.planoContratadoId = dados.planoContratadoId;
  }

  public cpf: string;
  public telefone: string;
  public planoContratadoId: number;

  public maskCelular = MaskHelper.celular;
  public maskMesAno = MaskHelper.mesAno;

  public get alterado(): boolean {
    return this.cpf !== this.cpf_original ||
      this.telefone !== this.telefone_original
      ? true
      : false;
  }

  public get valido(): boolean {
    return this.cpf &&
      this.cpf.length > 0 &&
      this.telefone &&
      this.telefone.length > 0
      ? true
      : false;
  }
}
