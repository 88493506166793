import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppConfig } from "../app.config";
import { ErrorHandlerService } from "./errorHandler.service";
import { HistoricoAtualizacoes } from "../models/HistoricoAtualizacoes";

@Injectable({
  providedIn: "root",
})
export class HistoricoAtualizacoesService {
  constructor(
    private httpClient: HttpClient,
    private errorHandlerService: ErrorHandlerService
  ) {}

  public async getHistoricoAtualizacoes(
    historico: number
  ): Promise<HistoricoAtualizacoes[]> {
    let resposta;
    let url = `${AppConfig.apiEndpoint}/HistoricoAtualizacoes/GetHistoricoAtualizacoes`;
    let requestParams = new HttpParams();
    requestParams = requestParams.append(
      "TipoItemHistoricoAtualizacao",
      historico.toString()
    );
    await this.httpClient
      .get(url, { params: requestParams })
      .toPromise()
      .then((result: HistoricoAtualizacoes[]) => {
        resposta = result;
      })
      .catch((err) => {
        this.errorHandlerService.handleError(err);
        resposta = new Array<HistoricoAtualizacoes>();
      });
    return resposta;
  }
}
