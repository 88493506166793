<div class="bar flex-row center-start">
  <div class="space"></div>
  <div aba-leitor *ngFor="let guia of guias; let i = index" [guia]="guia" [guiaIndex]="index(guia)"
    [ativaIndex]="indexGuiaAtiva" [hoverIndex]="indexMouseOver" [totalGuias]="guias.length" [larguraAba]="larguraAba"
    (mouseover)="guia.hover = true" (mouseleave)="guia.hover = false" (click)="alternarPara(guia)"
    (aoFecharAba)="fecharGuia($event)"></div>
  <div class="btn-nova-guia-wrapper flex-row center-center">
    <mat-icon title="Nova aba" (click)="btnNovaGuia_click($event)">add</mat-icon>
  </div>
  <div class="flex-1 space"></div>
</div>
