export class IndicacoesModel {
    public enderecosEmailIndicar: string;
  
    public valorTabelaDesconto: number;
    public valorMaximoDesconto: number;
    public valorPlano: number;
    public dataProximoVencimento: Date;
  
    public indicacoes: ContatoIndicacao[];
  
    constructor() {
      this.indicacoes = new Array<ContatoIndicacao>();
    }
  
    public get valorDescontoIndicacoes(): number {
      let valor = 0;
  
      const qtdIndicacoes = this.indicacoes.filter(i => i.status.codigo === StatusIndicacao.aprovado).length;
      valor = qtdIndicacoes * this.valorTabelaDesconto;
  
      return (valor > this.valorMaximoDesconto) ? this.valorMaximoDesconto : valor;
    }
  }
  
  export class ContatoIndicacao {
    nome: string;
    email: string;
    dataEnvio: Date;
    dataCadastro: Date;
    status: StatusIndicacaoModel;
  }
  
  export enum StatusIndicacao {
    pendente = 1,
    aprovado = 2,
    cancelado = 3
  }
  
  export class StatusIndicacaoModel {
    codigo: StatusIndicacao;
    descricao: string;
  
    public static status: StatusIndicacaoModel[] = [
      { codigo: StatusIndicacao.pendente, descricao: 'Pendente' },
      { codigo: StatusIndicacao.aprovado, descricao: 'Aprovado' },
      { codigo: StatusIndicacao.cancelado, descricao: 'Cancelado' }
    ];
  }