<div class="wrapper flex-row center-start" [ngClass]="classes" [style.width]="larguraAba">
  <div class="fundo"></div>
  <div class="conteudo flex-row center-start">
    <div class="titulo" [title]="guia.titulo">{{guia.titulo}}</div>
    <span class="flex-1"></span>
    <div class="btn-fechar flex-row center-end" title="Fechar aba" (click)="fecharGuia($event)">
      <mat-icon>close</mat-icon>
    </div>
  </div>
</div>
