<table class="noselect">
  <tr>
    <td rowspan="2" class="spinner-placeholder">
      <mat-spinner [mode]="'indeterminate'" [diameter]="36"> </mat-spinner>
    </td>
    <td>
      <h4 class="static-msg">Por favor aguarde</h4>
    </td>
  </tr>
  <tr>
    <td>{{ status }}</td>
  </tr>
  <tr>
    <td></td>
    <td>
      <p class="await-msg">
      ...esse processo pode levar alguns segundos.
      </p>
    </td>
  </tr>
</table>
