<div *ngIf="carregandoConteudo" class="div-cabecalho-loading"></div>
<div
  class="div-cabecalho"
  *ngIf="!carregandoConteudo"
  [ngClass]="{ shadow: indexLinhaAtual != 0 }"
>
  <div class="div-esquerda-cabecalho">
    <a
      [href]="urlFonteLei"
      target="_blank"
      rel="noopener noreferrer"
      title="Fonte"
    >
      <mat-icon matTooltip="Ir para a fonte do documento" class="fonte-icone"
        >public</mat-icon
      >
    </a>
    <span
      title="Índice Sistematico"
      matTooltip="Exibir índice sistemático"
      class="icon icon-menu clickable"
      (click)="abrirIndiceSistematico()"
    ></span>
    <span
      *ngIf="dicaEstudos?.length >= 1"
      title="Dica de Estudos"
      matTooltip="Exibir Dica de Estudos"
    >
      <app-dica-estudos [dicasEstudo]="dicaEstudos"></app-dica-estudos>
    </span>
    <div class="noselect div-cabecalho-titulo" [title]="tituloAtual">
      <span style="display: inline-flex; margin-top: -4px; line-height: 2">
        {{ tituloAtualWrapped }}
      </span>
    </div>
  </div>
  <div class="div-direita-cabecalho">
    <form-busca-artigo-documento-atual
      [prefixoBusca]="prefixoBusca"
      [tooltipBusca]="tooltipBusca"
    >
    </form-busca-artigo-documento-atual>
    <button
      mat-button
      class="mat-small"
      title="Ir para o começo"
      color="primary"
      (click)="focoChange.emit(0)"
    >
      <mat-icon
        class="icone-cabecalho"
        aria-hidden="false"
        matTooltip="Ir para o começo do documento"
        >first_page</mat-icon
      >
    </button>
    <button
      mat-button
      class="mat-small"
      title="Ir para o final"
      color="primary"
      (click)="focoChange.emit(totalLinhas - 10)"
    >
      <mat-icon
        class="icone-cabecalho"
        aria-hidden="false"
        matTooltip="Ir para o final do documento"
        >last_page</mat-icon
      >
    </button>

    <button
      mat-button
      class="mat-small"
      title="Painel de configuração"
      color="primary"
      (click)="openDialog()"
    >
      <mat-icon
        class="icone-cabecalho-v"
        aria-hidden="false"
        matTooltip="Painel de configuração"
        >settings</mat-icon
      >
    </button>
  </div>
</div>
