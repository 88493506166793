<div class="nova-guia-placeholder container flex-column">
  <div class="nova-guia-header shadow flex-column stretch-center">
    <mat-form-field>
      <input name="txt-busca-nova-guia" matInput placeholder="Buscar" [(ngModel)]="txtBusca"
        (ngModelChange)="txtBusca_Change()">
      <div class="icon icon-busca" matSuffix></div>
    </mat-form-field>
    <div class="flex-row">
      <button mat-button color="primary" (click)="toggleFiltroFavoritos()">
        <mat-icon *ngIf="(filtrarFavoritos | async)" style="margin-right: 8px;">bookmark</mat-icon>
        <mat-icon *ngIf="!(filtrarFavoritos | async)" style="margin-right: 8px;">bookmark_border</mat-icon>
        <span>Favoritos</span>
      </button>
      <span class="flex-1"></span>
      <button id="btn-sort"  mat-button color="primary" style="margin-right: 8px;" [matMenuTriggerFor]="sortingMenu"
        *ngIf="(modoOrdenacao | async)">
        <span>{{(modoOrdenacao | async).label}}</span>
        <mat-icon style="margin-left: 8px;">import_export</mat-icon>
      </button>
      <mat-menu #sortingMenu="matMenu">
        <button mat-menu-item *ngFor="let modo of opcoesModoOrdenacao"
          (click)="alterarModoOrdenacao(modo)">{{modo.label}}</button>
      </mat-menu>
      <button mat-button color="primary" class="flex-row center-center" (click)="abrirBusca()" *ngIf="!modoOffline">
        <span>Busca avançada</span>
        <mat-icon class="invert-x" style="margin-left: 8px;">search</mat-icon>
      </button>
    </div>
  </div>
  <div class="nova-guia-body shadow flex-1 relative hide-overflow flex-column">
    <ng-container *ngIf="!(carregando) && (listaLeis | async) && (listaLeis | async).length === 0; else elseTemplate">
      <div class="msg-nenhum-resultado flex-column flex-1 foreground-primary center-center">
        <mat-icon>search</mat-icon>
        <h2>Nenhum resultado encontrado</h2>
        <p *ngIf="!modoOffline"><a (click)="abrirBusca()">Clique aqui</a> para pesquisar dentro do conteúdo de toda literatura do Super
          Vade Mecum</p>
      </div>
    </ng-container>
    <ng-template #elseTemplate>
      <virtual-scroller *ngIf="!(carregando) && (listaLeis | async) && (listaLeis | async).length > 0" #scroll
        [items]="listaLeis | async" (vsUpdate)="scroll.viewPortItems = $event">
        <app-item-menu-nova-guia *ngFor="let item of scroll.viewPortItems" [item]="item"
          (favorito_mudou)="favoritoAlterardo(item)" (click)="selecionar(item)"></app-item-menu-nova-guia>
      </virtual-scroller>
      <virtual-scroller *ngIf="(carregando)" #scroll [items]="listaLeis | async" (vsUpdate)="scroll.viewPortItems = $event">
        <div class="background-animation">
          <div class="background-masker" style="margin-top: 2px !important;"></div>
          <div class="backgound-divisor"></div>
          <div *ngFor="let item of [].constructor(linhasLoading); let i = index">
            <div class="background-masker"></div>
            <div class="backgound-divisor"></div>
          </div>
        </div>
      </virtual-scroller>
    </ng-template>
  </div>
</div>
