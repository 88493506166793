// import { LeiStorage } from "./lei.storage";
// import { LeiLookup } from "./lei.lookup";
// import { LeiDownload } from "../../arguments/lei/lei.download";

export class IdDataLei {
    ids: Array<string>;
    MaiorDataAlteracaoApp: Date;

    constructor() {
        this.ids = new Array<string>();
        this.MaiorDataAlteracaoApp = new Date(1,1,1);
        this.MaiorDataAlteracaoApp.setFullYear(1);
    }
}