export class Versao {
    prefixo: string;
    texto: string;
    textoMarcado: string;
    textoGrifado: string;
    observacoes: string;
    revogado: boolean;
    tipoUltimaAlteracao: TipoAlteracao;
}

export enum TipoAlteracao {
    Incluido = 0,
    Alterado = 1,
    Revogado = 2
}
