import { LeiStorage } from "../../models/lei/lei.storage";

export class CabecalhoLeiResponse {
  id: string;
  titulo: string;
  urlFonte: string;
  dataHoraUltimaModificacao: Date;
  dataHoraUltimaModificacaoOficial: Date;
  tipoDocumento: number;
  dataPromulgacaoLei: Date;
  palavrasChave: string;
  quantidadeItens: number;
  quantidadePalavras: number;
  atualizacaoPendente: boolean;

  static toLeiStorage(l: CabecalhoLeiResponse): LeiStorage {
    return {
      id: l.id,
      descricao: l.titulo,
      dataHoraModificacao: l.dataHoraUltimaModificacao,
      dataHoraUltimaModificacaoTextoLei: l.dataHoraUltimaModificacao,
      dataHoraUltimaModificacaoOficial: l.dataHoraUltimaModificacaoOficial,
      tipoDocumento: l.tipoDocumento,
      quantidadeItens: l.quantidadeItens,
      quantidadePalavras: l.quantidadePalavras,
      disponivel: false,
      dados: null,
      removido: false,
      atualizacaoPendente: l.atualizacaoPendente,
    };
  }
}
