<div *ngIf="editandoTitulo; else readonly">
  <div [style.backgroundColor]="corTitulo" class="editor-wrapper">
    <div class="editor-container">

        <mat-icon
        backgroundContrast [backgroundColor]="corTitulo"
          class="material-icons-outlined icon-titulo"
          >title</mat-icon
        >

      <div
        #editor
        class="editor editor-titulo"
        [ngClass]="{ novo: novoTitulo }"
        [style.height.px]="titulo.alturaExibicao"
        placeholder="Insira o titulo"
        contenteditable="true"
        [innerHTML]="titulo.texto"
        (keydown)="key_pressed($event)"
        (keydown.enter)="enter_pressed($event)"
        (keydown.esc)="esc_pressed($event)"
        (paste)="on_paste($event)"
        (blur)="on_blur($event)"
      ></div>
    </div>
    <div class="editor-titulo-icon">
      <div class="char-count" backgroundContrast [backgroundColor]="corTitulo">
        {{ tamanhoTexto }}/{{ charLimit }}
      </div>
      <mat-icon
        backgroundContrast
        [backgroundColor]="corTitulo"
        class="material-icons-outlined editor-titulo-icons-info"
        matTooltip="Adicione textos em negrito pressionando as teclas Ctrl + B.
           Adicione textos em itálico pressionando as teclas Ctrl + I.
           Adicione textos com sublinhado pressionando as teclas Ctrl + U."
        [matTooltipClass]="'editor-comentario-mattooltip'"
        [matTooltipPosition]="'below'"
        style="padding-left: 8px; cursor: pointer; font-size: 10pt"
        >info</mat-icon
      >
    </div>
  </div>
</div>

<ng-template #readonly>
  <table
    backgroundContrast
    [backgroundColor]="corTitulo"
    class="readonly-wrapper"
    (click)="mouseup_edicao()"
  >
    <tr>
      <td>
        <span
          class="texto"
          [class.texto-titulo]="titulo.mnemonico"
          #viewer
          [innerHTML]="titulo.texto"
          >{{ titulo.texto }}</span
        >
      </td>
      <td>
        <mat-icon
          class="btn-excluir"
          [ngStyle]="{ color: editandoTitulo ? 'color' : 'unset' }"
          svgIcon="close"
          matTooltip="Excluir"
          (click)="confirmarDelecaoComentario($event, titulo)"
        >
        </mat-icon>
      </td>
    </tr>
  </table>
</ng-template>
