<mat-expansion-panel>
    <mat-expansion-panel-header>
        Indicações e descontos
    </mat-expansion-panel-header>

    <ng-container *ngIf="model; else elseTemplate">
        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <p>Convide seus amigos para utilizarem o Super Vade Mecum e ganhe descontos! A cada indicação válida, você ganha {{model.valorTabelaDesconto | currency: 'BRL' : 'symbol' : '1.2-2' : 'pt'}} de desconto em sua mensalidade.</p>
                <p>Para enviar um convite, digite no campo abaixo os endereços de email de seus amigos. Você pode enviar vários de uma vez separando os endereços com ponto-e-vírgula (;)</p>
                <p>Conforme LEI Nº 13.709 que dispõe sobre o tratamento de dados pessoais (LGPD), ao nos fornecer o endereço de e-mail você está ciente que estará compartilhando dado de alguém conhecido que será usado pela nossa equipe para oferecer assinatura
                    do SVM.</p>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                <mat-form-field>
                    <input matInput placeholder="Endereço(s) de email" [(ngModel)]="model.enderecosEmailIndicar">
                </mat-form-field>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4" style="text-align:right;">
                <button mat-raised-button color="primary" *ngIf="model.enderecosEmailIndicar" (click)="enviarConvites()">Enviar
          convite</button>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="table-responsive">
                    <table class="table table-hover table-bordered">
                        <thead>
                            <tr>
                                <th>Nome</th>
                                <th>Email</th>
                                <th>Status</th>
                                <th>Enviado</th>
                                <th>Cadastrado</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tfoot>
                            <tr>
                                <td colspan="4" class="bold">Total dos descontos</td>
                                <td colspan="2" class="cell-valor bold">{{model.valorDescontoIndicacoes | currency: 'BRL' : 'symbol' : '1.2-2' : 'pt'}}</td>
                            </tr>
                            <tr>
                                <td colspan="4">Valor do plano</td>
                                <td colspan="2" class="cell-valor">{{model.valorPlano | currency: 'BRL' : 'symbol' : '1.2-2' : 'pt'}}</td>
                            </tr>
                            <tr>
                                <td colspan="4" class="bold">Valor a ser cobrado em {{model.dataProximoVencimento | date:'dd/MM/yyyy'}}</td>
                                <td colspan="2" class="cell-valor bold">{{(model.valorPlano - model.valorDescontoIndicacoes) | currency: 'BRL' : 'symbol' : '1.2-2' : 'pt'}}</td>
                            </tr>
                        </tfoot>
                        <tbody>
                            <tr *ngFor="let indicacao of model.indicacoes" class="{{(indicacao.status.codigo === 3) ? 'bg-danger' : ''}}">
                                <td>{{indicacao.nome}}</td>
                                <td>{{indicacao.email}}</td>
                                <td>{{indicacao.status.descricao}}</td>
                                <td>{{indicacao.dataEnvio | date: 'dd/MM/yyyy'}}</td>
                                <td>{{indicacao.dataCadastro | date: 'dd/MM/yyyy'}}</td>
                                <td><a href="#" *ngIf="indicacao.status.codigo !== 2" (click)="enviarConvites(indicacao.email)">Reenviar</a></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>

    </ng-container>
    <ng-template #elseTemplate>
        Carregando...
    </ng-template>
</mat-expansion-panel>