<div class="indice-holder">
    <br />
    <h2>ÍNDICE SISTEMÁTICO</h2>
    <div *ngIf="indiceSistematicoCompleto.length < 1">
        <h4>Não existem itens para índice neste documento :(</h4>
    </div>
    <div *ngIf="indiceSistematicoCompleto.length >= 1" class="indice-content">
        <mat-tree *ngIf="indiceSistematicoCompleto && indiceSistematicoCompleto.length > 0" [dataSource]="dataSource"
            [treeControl]="treeControl" class="tree">
            <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
                <li class="mat-tree-node">
                    <span mat-icon-button class="{{node.tipoItemCssClass}} node single-node" (click)="IrParaItem(node)">{{node.texto}}</span>
                    <span class="node-arts">{{node.rangeArtigo}}</span>
                </li>
            </mat-tree-node>
            <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                <li>
                    <div class="mat-tree-node">
                        <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.texto">
                            <mat-icon class="mat-icon-rtl-mirror">
                                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                            </mat-icon>
                        </button>
                        <span class="{{node.tipoItemCssClass}} node" (click)="IrParaItem(node)">{{node.texto}}</span>
                        <span class="node-arts">{{node.rangeArtigo}}</span>
                    </div>
                    <ul [class.tree-invisible]="!treeControl.isExpanded(node)">
                        <ng-container matTreeNodeOutlet></ng-container>
                    </ul>
                </li>
            </mat-nested-tree-node>
        </mat-tree>
    </div>
</div>