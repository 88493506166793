import { Comentario } from "./Comentario";
import { BaseObj } from "./UserdataSync";

export class ComentariosGerenciados extends BaseObj {
  idLei: string;
  dataUltimaAtualizacao: string;
  comentarios: Comentario[];
  constructor(comentario: ComentariosGerenciados) {
    super();
    this.comentarios = comentario.comentarios;
    this.dataUltimaAtualizacao = comentario.dataUltimaAtualizacao;
    this.idLei = comentario.idLei;
  }
}
