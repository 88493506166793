<table class="scheduler-placeholder">
  <tr>
    <td colspan="4" class="sel-meses-placeholder">
      <mat-form-field class="sel-meses">
        <mat-select [(value)]="mes" (selectionChange)="selMeses_change($event)">
          <mat-option value="0">Janeiro</mat-option>
          <mat-option value="1">Fevereiro</mat-option>
          <mat-option value="2">Março</mat-option>
          <mat-option value="3">Abril</mat-option>
          <mat-option value="4">Maio</mat-option>
          <mat-option value="5">Junho</mat-option>
          <mat-option value="6">Julho</mat-option>
          <mat-option value="7">Agosto</mat-option>
          <mat-option value="8">Setembro</mat-option>
          <mat-option value="9">Outubro</mat-option>
          <mat-option value="10">Novembro</mat-option>
          <mat-option value="11">Dezembro</mat-option>
        </mat-select>
      </mat-form-field>
    </td>
    <td colspan="3" class="sel-anos-placeholder">
      <mat-form-field class="sel-anos">
        <mat-select [(value)]="ano" (selectionChange)="selAnos_change($event)">
          <mat-option *ngFor="let a of anos" value="{{a}}">{{a}}</mat-option>
        </mat-select>
      </mat-form-field>
    </td>
  </tr>
  <tr>
    <th>D</th>
    <th>S</th>
    <th>T</th>
    <th>Q</th>
    <th>Q</th>
    <th>S</th>
    <th>S</th>
  </tr>
  <tr *ngFor="let semana of semanas">
    <td class="btn-dia" *ngFor="let dia of semana.dias">
      <span *ngIf="dia" (click)="dia_click(dia)" [ngClass]="{'selecionado' : dia && data && dia.getTime() === data.getTime()}">{{dia.getDate()}}</span>
    </td>
  </tr>
</table>