import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from "@angular/core";
import { UsuarioPreferenciasService } from "../../../../services/data-services/usuario.preferencias.service";
import { Subscription } from "rxjs";
import { ConfiguracoesUsuario } from "../../../../models/usuario/ConfiguracoesUsuario";
import { Comentario } from "../../../../models/Comentario";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-editor-comentario",
  templateUrl: "./editor-comentario.component.html",
  styleUrls: ["./editor-comentario.component.scss"],
})
export class EditorComentarioComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  public corComentario: string;
  public corTitulo: string;

  public comentarioGerenciadoPlus: boolean = false;

  public currentTags = [];
  public comentarioGerenciadoReadOnly: boolean = false;
  public comentarioGerenciadoColor: string = "#E6E7E8";
  public currentTexto = "";
  public comentario: Comentario;
  public editando: boolean;
  public novo: boolean;
  maxWidth: number;
  public email: string;

  @Input() set comentarioCriar(comentarioCriar: Comentario) {
    this.comentario = comentarioCriar;
    this.currentTags = [...(this.comentario?.tags ? this.comentario.tags : [])];
    this.comentarioGerenciadoReadOnly = false;
    this.editando = true;
    this.novo = true;
  }

  @Input() set comentarioEditar(comentarioEditar: Comentario) {
    this.comentario = comentarioEditar;
    this.currentTexto = this.comentario.texto;
    this.currentTags = [...(this.comentario?.tags ? this.comentario.tags : [])];
    this.comentarioGerenciadoReadOnly = false;
    this.editando = false;
    this.novo = false;
  }

  @Input() set comentarioReadOnly(comentarioReadOnly: Comentario) {
    this.comentario = comentarioReadOnly;
    this.currentTexto = this.comentario.texto;
    this.currentTags = [...(this.comentario?.tags ? this.comentario.tags : [])];
    this.comentarioGerenciadoReadOnly = true;
    this.editando = false;
    this.novo = false;
    this.comentariosPlus();
  }

  @Output("commit") onCommited = new EventEmitter();
  @Output("cancel") onCancel = new EventEmitter();

  constructor(
    private preferenciasService: UsuarioPreferenciasService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.preferenciasService.$Configuracoes.subscribe((c) =>
        this.configuracoes_changed(c)
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });

    this.subscriptions = [];
  }

  private configuracoes_changed(c: ConfiguracoesUsuario): void {
    this.corComentario = c.parametrosCaneta.corTagComentario;
    this.corTitulo = c.parametrosCaneta.corTagMnemonico;
    this.email = c.email;
  }

  private comentariosPlus() {
    if (this.comentario?.tags)
      this.comentarioGerenciadoPlus = this.comentario.tags.find(
        (c) => c === "Plus"
      )
        ? true
        : false;
  }

  commit(e) {
    this.onCommited.emit(e);
  }

  cancel() {
    this.onCancel.emit();
  }

  changeEditandoComentario() {
    this.editando = !this.editando;
  }
}
