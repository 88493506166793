import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { SimNaoDialogComponent } from "src/app/components/dialogs/sim-nao-dialog/sim-nao-dialog.component";
import { Comentario } from "src/app/models/Comentario";
import { UsuarioComentariosService } from "src/app/services/data-services/usuario.comentarios.service";
import { FeatureFlagService } from "src/app/services/feature-flag-service.service";

@Component({
  selector: "app-comentario",
  templateUrl: "./comentario.component.html",
  styleUrls: ["./comentario.component.scss"],
})
export class ComentarioComponent implements OnInit{
  @Input() public currentTagsComentario: string[] = [];
  @Input() public comentario: Comentario;
  @Input() public novoComentario: boolean;
  @Input() public corComentario: string;
  @Input() public currentTextoComentario: string;
  @Input() public editandoComentario: boolean;
  @Input() public email: string;
  @Input() public comentarioGerenciadoReadOnly: boolean;
  @Input() public comentarioGerenciadoPlus: boolean;

  private cancelled = false;
  public newtags: string[] = [];
  private limiteCaracteresComentario = 3000;

  private limiteExtendidoCaracteresComentario = 15000;
  private editorElement: HTMLDivElement = null;
  private IsAdm: boolean = false;

  @Output() comentarioCommited = new EventEmitter();
  @Output() comentarioCancel = new EventEmitter();
  @Output() changeEditando = new EventEmitter();

  @ViewChild("editor", { static: false }) set editor(editor: ElementRef) {
    this.editorElement = editor ? <HTMLDivElement>editor.nativeElement : null;
    if (this.editorElement)
      // this.editorElement.focus()
      this.placeCaretAtEnd(this.editorElement);
    else this.editorElement = null;
  }

  private viewerElement: HTMLSpanElement = null;
  @ViewChild("viewer", { static: false }) set viewer(viewer: ElementRef) {
    this.viewerElement = viewer ? <HTMLSpanElement>viewer.nativeElement : null;
    if (this.viewerElement) {
    } else this.viewerElement = null;
  }

  constructor(
    public dialog: MatDialog,
    private usuarioComentariosService: UsuarioComentariosService,
    private changeDetector: ChangeDetectorRef,
    public featureFlagService: FeatureFlagService
  ) {

  }
  ngOnInit(): void {
    this.IsAdm = this.featureFlagService.IsAdm(this.email);
  }

  enter_pressed(e: KeyboardEvent) {
    if (this.comentario) {
      e.preventDefault();
      e.stopPropagation();
      this.editorElement.blur();
    }
  }

  esc_pressed(e: KeyboardEvent) {
    this.cancelled = true;
    this.editorElement.blur();
  }

  on_blur(event: any) {
    if (event.relatedTarget?.classList.contains("comentario-tags")) {
      return;
    }

    this.cancelled =
      this.cancelled ||
      this.editorElement.innerHTML.length == 0 ||
      (this.editorElement.innerHTML == this.currentTextoComentario &&
        this.newtags == this.currentTagsComentario);

    if (!this.novoComentario) this.toggle_edicao();

    if (this.cancelled) this.cancel();
    else this.commit();
  }

  get tamanhoTexto() {
    if (!this.editorElement || !this.editorElement.innerHTML) return 0;

    let t = this.editorElement.innerText.length;
    return t;
  }

  commit() {
    if (this.editorElement) {
      const innerHTML = this.editorElement.innerHTML;
      let textData = innerHTML;

      this.comentario.texto = textData;

      // 4 = padding atual definido no css
      this.comentario.alturaExibicao = this.editorElement.clientHeight - 4;
      this.comentario.larguraExibicao = this.editorElement.clientWidth - 4;
      if (this.newtags?.length) {
        this.comentario.tags = this.newtags;
        this.currentTagsComentario = [...this.newtags];
      }
    }

    this.comentarioCommited.emit(this.comentario);
  }

  cancel() {
    this.comentarioCancel.emit();
  }

  public confirmarDelecaoComentario(event: MouseEvent, comentario: Comentario) {
    event.preventDefault();
    event.stopPropagation();

    const tipoComentario = "comentário";
    const dialogRef = this.dialog.open(SimNaoDialogComponent, {
      width: "250px",
      data: "Deseja mesmo excluir o " + tipoComentario + "?",
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.usuarioComentariosService.remover(comentario);
      }
    });
  }

  key_pressed(e: KeyboardEvent) {
    const ignoredKeycodes = [8, 46, 37, 38, 39, 40];
    if (
      !ignoredKeycodes.includes(e.keyCode) &&
      this.tamanhoTexto >= this.charLimit
    ) {
      e.preventDefault();
      e.stopPropagation();
    }

    this.changeDetector.detectChanges();
  }

  placeCaretAtEnd(el) {
    el.focus();
    if (
      typeof window.getSelection != "undefined" &&
      typeof document.createRange != "undefined"
    ) {
      var range = document.createRange();
      range.selectNodeContents(el);
      range.collapse(false);
      var sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    }
  }

  mouseup_edicao() {
    this.toggle_edicao();
  }

  toggle_edicao() {
    if (!this.editandoComentario) this.cancelled = false;

    this.changeEditando.emit();
  }

  updatetags(value: string[]) {
    this.newtags = value;
  }

  on_paste(e: ClipboardEvent) {
    e.preventDefault();
    let clipboardData = e.clipboardData.getData("text/plain");

    if (this.comentario) {
      clipboardData = clipboardData.replace(/\n|\r/g, "");
    }

    this.comentario.texto += clipboardData;
    this.comentario.texto = this.comentario.texto.substr(
      0,
      this.IsAdm
        ? this.limiteExtendidoCaracteresComentario
        : this.limiteCaracteresComentario
    );
  }

  get charLimit() {
    return this.IsAdm
      ? this.limiteExtendidoCaracteresComentario
      : this.limiteCaracteresComentario;
  }
}
