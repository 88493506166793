<div
  class="popup-area {{ popupType === 2 ? 'perfil' : '' }}
   {{ popupType === 3 ? 'preferencias' : '' }}
    {{ popupType === 4 ? 'feedback' : '' }}
    {{ popupType === 5 ? 'indice-sistematico' : '' }}"
>
  <div
    class="icon icon-close"
    *ngIf="canClose"
    (click)="tryClose($event)"
  ></div>
  <app-primeiro-login *ngIf="popupType === 1"></app-primeiro-login>
  <app-perfil
    (confirmarAlteracoes)="confirmarAlteracoes_changed($event)"
    *ngIf="popupType === 2"
  ></app-perfil>
  <app-preferencias
    (confirmarAlteracoes)="confirmarAlteracoes_changed($event)"
    *ngIf="popupType === 3"
  ></app-preferencias>
  <app-feedback *ngIf="popupType === 4"></app-feedback>
  <app-indice-sistematico *ngIf="popupType === 5"></app-indice-sistematico>
</div>
