import { HostListener, Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { Tema } from '../models/tema';
import { TemasApp } from '../constants/lista.temas';
import { UsuarioPreferenciasService } from './data-services/usuario.preferencias.service';

@Injectable()
export class UiService {
    public tema: Observable<Tema>;
    private _tema = new BehaviorSubject<Tema>(null);

    public Mobile: Observable<boolean>;
    private _mobile = new BehaviorSubject<boolean>(null);

    public larguraTela: Observable<number>;
    private _larguraTela = new BehaviorSubject<number>(null);

    public alturaTela: Observable<number>;
    private _alturaTela = new BehaviorSubject<number>(null);

    private s_prefs: Subscription;
    private idTema: number;

    constructor(
        private usuarioPreferenciasService: UsuarioPreferenciasService
    ) {
        this.larguraTela = this._larguraTela.asObservable();
        this.alturaTela = this._alturaTela.asObservable();
        this.tema = this._tema.asObservable();
        this.Mobile = this._mobile.asObservable();

        this.onResize();

        this.usuarioPreferenciasService.$Configuracoes.subscribe(config => {
            if (config && this.idTema !== config.idTema) {
                this.idTema = config.idTema;
                this.carregarTema();
            }
        });
    }

    private carregarTema(): void {
        this._tema.next(TemasApp[this.idTema]);
    }

    public AlterarTema(tema: Tema): void {
        // this.idTema = TemasApp.indexOf(tema);

        // let config = <ConfiguracoesUsuario>EntitiesHelper.Copy(this.usuarioPreferenciasService.Configuracoes);
        // config.idTema = this.idTema;

        // this.usuarioPreferenciasService.Configuracoes = config;
        this._tema.next(tema);
    }

    
    @HostListener('window:resize', ['$event'])
    onResize(event?){
        this._larguraTela.next(window.innerWidth);
        this._alturaTela.next(window.innerHeight);
        this._mobile.next(window.innerWidth < 797);
    }
}
