export class DateHelpers {
    static SegundosEntre(t1: Date, t2: Date) {
        var dif = t1.getTime() - t2.getTime()

        var Seconds_from_T1_to_T2 = dif / 1000
        var Seconds_Between_Dates = Math.abs(Seconds_from_T1_to_T2)

        return Seconds_Between_Dates
    }

    static totalSegundosEmUmDia = 86400;
}