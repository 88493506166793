import { BaseObj } from "../models/UserdataSync";
import { AppConfig } from "../app.config";

export class DbConnection {
  public bancoUserdata: IDBOpenDBRequest;
  public bancoLeis: IDBOpenDBRequest;
  public bancoBuscas: IDBOpenDBRequest;
}

export class StorageHelper {
  public static checkForSupport(): boolean {
    const notSupported = !indexedDB;
    return !notSupported;
  }

  public static connectDatabases(): DbConnection {
    const request = new DbConnection();

    request.bancoUserdata = indexedDB.open("userdata", AppConfig.versaoIndexDB);
    request.bancoBuscas = indexedDB.open("buscas", AppConfig.versaoIndexDB);

    request.bancoBuscas.onupgradeneeded = (event) => {
      StorageHelper.upgradeBancoBuscas(event);
    };

    request.bancoBuscas.onerror = (event) => {
      throw Error("Erro ao conectar à base IndexedDB" + JSON.stringify(event));
    };

    request.bancoUserdata.onupgradeneeded = (event) => {
      StorageHelper.upgradeBancoUserData(event);
    };

    request.bancoUserdata.onerror = (event) => {
      throw Error("Erro ao conectar à base IndexedDB" + JSON.stringify(event));
    };

    return request;
  }

  private static upgradeBancoBuscas(event: IDBVersionChangeEvent) {
    const db = (<IDBOpenDBRequest>event.target).result;

    if (event.oldVersion > 0) {
      StorageHelper.tryDeleteObjectStore(db, "buscas");
    }

    const objectStore = db.createObjectStore("buscas", { keyPath: "id" });
    objectStore.createIndex("id", "id", { unique: true });
    objectStore.createIndex("guia", "guiId", { unique: true });
  }

  private static upgradeBancoUserData(event: IDBVersionChangeEvent) {
    const db = (<IDBOpenDBRequest>event.target).result;
    if (event.oldVersion > 0) {
      StorageHelper.tryDeleteObjectStore(db, "userdata");
      StorageHelper.tryDeleteObjectStore(db, "guias");
      StorageHelper.tryDeleteObjectStore(db, "marcacoes");
      StorageHelper.tryDeleteObjectStore(db, "comentarios");
      StorageHelper.tryDeleteObjectStore(db, "referencias");
      StorageHelper.tryDeleteObjectStore(db, "grifos");
      StorageHelper.tryDeleteObjectStore(db, "instituicoes");
      StorageHelper.tryDeleteObjectStore(db, "bancas");
      StorageHelper.tryDeleteObjectStore(db, "anos");
      StorageHelper.tryDeleteObjectStore(db, "tipos");
      StorageHelper.tryDeleteObjectStore(db, "cargos");
      StorageHelper.tryDeleteObjectStore(db, "referencias-cabecalhos");
    }

    db.createObjectStore("userdata", { keyPath: "id" }).createIndex(
      "id",
      "id",
      { unique: true }
    );
    db.createObjectStore("guias", { keyPath: "id" }).createIndex("id", "id", {
      unique: true,
    });
    db.createObjectStore("marcacoes", { keyPath: "id" }).createIndex(
      "id",
      "id",
      { unique: true }
    );
    db.createObjectStore("comentarios", { keyPath: "id" }).createIndex(
      "id",
      "id",
      { unique: true }
    );
    db.createObjectStore("referencias", { keyPath: "id" }).createIndex(
      "id",
      "id",
      { unique: true }
    );
    db.createObjectStore("grifos", { keyPath: "id" }).createIndex("id", "id", {
      unique: true,
    });
    db.createObjectStore("apontamentos", { keyPath: "id" }).createIndex(
      "id",
      "id",
      { unique: true }
    );
    db.createObjectStore("instituicoes", { keyPath: "id" }).createIndex(
      "id",
      "id",
      { unique: true }
    );
    db.createObjectStore("bancas", { keyPath: "id" }).createIndex("id", "id", {
      unique: true,
    });
    db.createObjectStore("anos", { keyPath: "id" }).createIndex("id", "id", {
      unique: true,
    });
    db.createObjectStore("tipos", { keyPath: "id" }).createIndex("id", "id", {
      unique: true,
    });
    db.createObjectStore("cargos", { keyPath: "id" }).createIndex("id", "id", {
      unique: true,
    });

    db.createObjectStore("referencias-cabecalhos", {
      keyPath: "id",
    }).createIndex("id", "id", {
      unique: true,
    });
  }

  public static async deletarDBs() {
    const excluirBanco = (nomeBanco: string) => {
      return new Promise<void>((onsuccess, onerror) => {
        const request = indexedDB.deleteDatabase(nomeBanco);
        request.onsuccess = () => {
          onsuccess();
        };
        request.onerror = () => {
          onerror();
        };
      });
    };

    const bancosExcluir = ["buscas", "userdata", "svdm"];
    bancosExcluir.forEach(async (banco) => {
      await excluirBanco(banco);
    });

    StorageHelper.connectDatabases();
  }

  public static getByKey<T extends BaseObj>(
    id: string,
    banco: string,
    tabela: string
  ): Promise<T> {
    try {
      return new Promise((onsuccess, onerror) => {
        const connRequest = indexedDB.open(banco);

        connRequest.onsuccess = (event) => {
          const db = (<IDBOpenDBRequest>event.target).result;
          const store = db.transaction([tabela]).objectStore(tabela);
          const getRequest = store.get(id);

          getRequest.onsuccess = () => {
            onsuccess(<T>getRequest.result);
          };
          getRequest.onerror = () => onerror(getRequest.error.message);
        };

        connRequest.onerror = (err) => {
          onerror(err);
        };
      });
    } catch (err) {
      throw new Error(
        `Erro em storage.helper.getByKey. Tabela: ${tabela}. Detalhes: ${err?.message}`
      );
    }
  }

  public static list<T extends BaseObj>(
    banco: string,
    tabela: string,
    filter: (m: T) => boolean = null
  ): Promise<T[]> {
    try {
      const execute = (): Promise<T[]> => {
        const objs = new Array<T>();

        return new Promise((onsuccess, onerror) => {
          const connRequest = indexedDB.open(banco);

          connRequest.onsuccess = (event) => {
            const db = (<IDBOpenDBRequest>event.target).result;
            const store = db.transaction([tabela]).objectStore(tabela);
            const getRequest = store.openCursor();

            getRequest.onsuccess = (event) => {
              const cursor = (<IDBRequest>event.target).result;
              if (cursor) {
                const e = <T>cursor.value;

                if (!e.removido && (!filter || filter(e))) objs.push(e);

                cursor.continue();
              } else {
                onsuccess(objs);
              }
            };

            getRequest.onerror = () => onerror(getRequest.error.message);
          };

          connRequest.onerror = (err) => {
            onerror(err);
          };
        });
      };

      return new Promise((onsuccess, onerror) => {
        execute()
          .then((lista) => {
            onsuccess(lista);
          })
          .catch((err) => {
            onerror(err);
          });
      });
    } catch (err) {
      throw new Error(
        `Erro em storage.helper.list. Tabela: ${tabela}. Detalhes: ${err?.message}`
      );
    }
  }

  public static upsert<T extends BaseObj>(
    obj: T,
    banco: string,
    tabela: string,
    audit = true
  ): Promise<T> {
    try {
      return new Promise((onsuccess, onerror) => {
        const request = indexedDB.open(banco);

        request.onsuccess = (e) => {
          const db = (<IDBOpenDBRequest>e.target).result;
          const store = db
            .transaction([tabela], "readwrite")
            .objectStore(tabela);

          if (audit || !obj.dataHoraModificacao) {
            obj.dataHoraModificacao = new Date();
          }

          const request = store.put(obj);

          request.onsuccess = () => onsuccess(obj);
          request.onerror = () => onerror(request.error.message);
        };

        request.onerror = (err) => {
          onerror(err);
        };
      });
    } catch (err) {
      throw new Error(
        `Erro em storage.helper.upsert. Tabela: ${tabela}. Detalhes: ${err?.message}`
      );
    }
  }

  public static upsertMany<T extends BaseObj>(
    objs: T[],
    banco: string,
    tabela: string,
    audit = true
  ): Promise<T[]> {
    try {
      return new Promise((onsuccess, onerror) => {
        const savedObjects = new Array<T>();

        let tasks = objs.reduce((accumulatorPromise, nextID) => {
          return accumulatorPromise.then(() => {
            return this.upsert(nextID, banco, tabela, audit).then((obj) => {
              savedObjects.push(obj);
            });
          });
        }, Promise.resolve());

        tasks
          .then(() => {
            onsuccess(savedObjects);
          })
          .catch((err) => {
            onerror(err);
          });
      });
    } catch (err) {
      throw new Error(
        `Erro em storage.helper.upsertMany. Tabela: ${tabela}. Detalhes: ${err?.message}`
      );
    }
  }

  public static delete(
    banco: string,
    tabela: string,
    id: string
  ): Promise<void> {
    try {
      return new Promise((onsuccess, onerror) => {
        const request = indexedDB.open(banco);

        request.onsuccess = (e) => {
          const db = (<IDBOpenDBRequest>e.target).result;
          const store = db
            .transaction([tabela], "readwrite")
            .objectStore(tabela);

          const request = store.delete(id);

          request.onsuccess = () => onsuccess();
          request.onerror = () => onerror(request.error.message);
        };

        request.onerror = (err) => {
          onerror(err);
        };
      });
    } catch (err) {
      throw new Error(
        `Erro em storage.helper.delete. Tabela: ${tabela}. Detalhes: ${err?.message}`
      );
    }
  }

  private static tryDeleteObjectStore(db: IDBDatabase, tableName: string) {
    if (db.objectStoreNames.contains(tableName)) {
      db.deleteObjectStore(tableName);
    }
  }
}
