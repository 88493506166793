import { Component, OnInit, OnDestroy } from '@angular/core';
import { StatusService } from '../../../services/status.service';
import { Subscription } from 'rxjs';

@Component({
  selector: '[info-panel]',
  templateUrl: './info-panel.component.html',
  styleUrls: ['./info-panel.component.css']
})
export class InfoPanelComponent implements OnInit, OnDestroy {
  private s_msgStatusObservable: Subscription;

  public currentStatus: string = null;

  constructor(
    private statusService: StatusService,
  ) { }

  ngOnInit() {
    this.s_msgStatusObservable = this.statusService.msgStatusObservable.subscribe((msg: string) => {
      setTimeout(() => {
        this.currentStatus = msg; 
      });
    });
  }

  ngOnDestroy(): void {
    this.s_msgStatusObservable.unsubscribe();
  }
}
