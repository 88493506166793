import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppConfig } from "src/app/app.config";
import { ComentariosGerenciados } from "src/app/models/ComentariosGerenciados";
import { ErrorHandlerService } from "../errorHandler.service";
import { StorageHelper } from "src/app/helpers/storage.helper";

@Injectable()
export class UsuarioComentariosGerenciadosService {
  readonly COMENTARIO_GERENCIADO_CONTEUDO_STORE =
    "comentario-gereciado-conteudo";
  readonly DATABASE_NAME = "svdm";

  constructor(
    private httpClient: HttpClient,
    private errorHandlerService: ErrorHandlerService
  ) {}

  public carregarComentariosGerenciadosPorLei(
    leiId: string
  ): Promise<ComentariosGerenciados> {
    return new Promise(async (onsuccess, onerror) => {
      const url = `${AppConfig.apiEndpoint}/Comentarios/ComentariosGerenciados`;
      let requestParams = new HttpParams();

      const comentario = await StorageHelper.getByKey<ComentariosGerenciados>(
        leiId,
        this.DATABASE_NAME,
        this.COMENTARIO_GERENCIADO_CONTEUDO_STORE
      )
        .then((comentario: ComentariosGerenciados) => comentario)
        .catch((error) => {
          console.log(error);
        });

      requestParams = requestParams.append("IdLei", leiId);
      requestParams = requestParams.append(
        "DataUltimaAtualizacao",
        comentario
          ? comentario.dataUltimaAtualizacao
          : new Date(0).toISOString()
      );
      await this.httpClient
        .get(url, { params: requestParams })
        .toPromise()
        .then((result: ComentariosGerenciados) => {
          if (result) {
            const coment = new ComentariosGerenciados(result);
            this.salvarComentariosGerenciadosLocal(coment).catch((err) => {
              onerror(err);
            });
            onsuccess(coment);
          }
          if (comentario) {
            onsuccess(comentario);
          } else {
            onsuccess(
              new ComentariosGerenciados({
                id: "",
                dataHoraModificacao: null,
                removido: false,
                idLei: "",
                dataUltimaAtualizacao: "",
                comentarios: [],
              })
            );
          }
        })
        .catch((err) => {
          this.errorHandlerService.handleError(err);
          onsuccess(
            new ComentariosGerenciados({
              id: "",
              dataHoraModificacao: null,
              removido: false,
              idLei: "",
              dataUltimaAtualizacao: "",
              comentarios: [],
            })
          );
        });
    });
  }

  public salvarComentariosGerenciadosLocal(
    comentario: ComentariosGerenciados
  ): Promise<void> {
    return new Promise(async (onsuccess, onerror) => {
      await StorageHelper.upsert<ComentariosGerenciados>(
        comentario,
        this.DATABASE_NAME,
        this.COMENTARIO_GERENCIADO_CONTEUDO_STORE
      ).catch((err) => onerror(err));
    });
  }

  public buscarComentariosGerenciadosLocal(): Promise<
    ComentariosGerenciados[]
  > {
    return new Promise(async (onsuccess, onerror) => {
      await StorageHelper.list<ComentariosGerenciados>(
        this.DATABASE_NAME,
        this.COMENTARIO_GERENCIADO_CONTEUDO_STORE
      )
        .then((comentarios: ComentariosGerenciados[]) => {
          onsuccess(comentarios);
        })
        .catch((err) => {
          onerror(err);
        });
    });
  }
}
