<div class="popup-placeholder noselect" (click)="$event.stopPropagation()">
  <div class="header">
    <h3>Feedback</h3>
  </div>
  <div class="body">
    <div class="header-subtitulo">
      Ajude a melhorar o Super Vade Mecum!<br/>
      <span>Conte-nos se falta alguma lei específica ou se podemos melhorar alguma funcionalidade</span>
    </div>
    <div class="header-acoes">
      <mat-button-toggle-group #btnGroup class="header-acoes">
        <mat-button-toggle mat-fab value="Feedback_Bom" mat-button title="Bom">
          <mat-icon svgIcon="happy" style="color: #0080005e;"></mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="Feedback_Regular" mat-button title="Regular">
          <mat-icon svgIcon="ok" style="color: #7d7d8bb8;"></mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="Feedback_Ruim" mat-button title="Ruim">
          <mat-icon svgIcon="sad" style="color: #d44c4c80;"></mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <mat-card>
      <mat-card-content>
        <mat-form-field style="width: 100%;">
          <textarea matInput [(ngModel)]="textoFeedback" placeholder="Deixe seu comentário aqui" rows="9"></textarea>
        </mat-form-field>
        <button mat-button (click)="btnCancelar_click()">Cancelar</button>
        <button mat-button (click)="criarTicket()">Enviar</button>
      </mat-card-content>
    </mat-card>
  </div>
</div>
