<div class="container background-primary">
  <div class="card-wrapper" #wrapper [style.marginTop.px]="marginTopWrapper">
    <h2>O seu navegador não é compatível com o Super Vade Mecum</h2>

    <mat-card>
      <p class="info">Aparentemente você está utilizando um navegador que ou não dá suporte aos recursos utilizados pelo
        Super Vade Mecum, ou não está atualizado para tal. Abaixo, apresentamos navegadores testados e compatíveis com o
        Super Vade Mecum, você pode instalá-lo(s) clicando no botão 'instalar' localizado ao lado de cada navegador:</p>

      <table class="browser-info">
        <tr *ngFor="let browser of browsers">
          <td>
            <img class="browser-logo" [src]="browser.imageUrl" alt="" />
          </td>
          <td class="browser-name" [innerText]="browser.name"></td>
          <td class="browser-link">
            <a [href]="browser.downloadLink" mat-raised-button target="blank" color="primary">Instalar</a>
          </td>
        </tr>
      </table>
    </mat-card>
  </div>
</div>