import { Injectable } from '@angular/core';
import { Observable ,  BehaviorSubject } from 'rxjs';
import { Marcacao } from '../../models/Marcacao';
import { Comentario } from '../../models/Comentario';

@Injectable()
export class PaineisService {
    painelMarcacoesAberto: Observable<boolean>;
    painelMarcacoesAbertoSubject = new BehaviorSubject<boolean>(false);

    painelMarcacaoHover: Observable<Marcacao>;
    painelMarcacaoHoverSubject = new BehaviorSubject<Marcacao>(null);

    painelComentarioHover: Observable<Comentario>;
    painelComentarioHoverSubject = new BehaviorSubject<Comentario>(null);

    painelNovidades: Observable<boolean>;
    painelNovidadesSubject = new BehaviorSubject<boolean>(null);

    painelSpinner: Observable<boolean>;
    painelSpinnerSubject = new BehaviorSubject<boolean>(null);

    public algumPainelAberto: Observable<boolean>;
    private algumPainelAbertoSubject = new BehaviorSubject<boolean>(null);

    constructor(
    ) {
        this.painelMarcacoesAberto = this.painelMarcacoesAbertoSubject.asObservable();
        this.painelMarcacaoHover = this.painelMarcacaoHoverSubject.asObservable();
        this.painelComentarioHover = this.painelComentarioHoverSubject.asObservable();
        this.algumPainelAberto = this.algumPainelAbertoSubject.asObservable();
        this.painelNovidades = this.painelNovidadesSubject.asObservable();
        this.painelSpinner = this.painelSpinnerSubject.asObservable();
    }

    private updatePanelInfo() {
        if (this.painelAberto) {
            this.painelMarcacoesAbertoSubject.next(true);
        } else {
            this.painelMarcacoesAbertoSubject.next(false);
        }

    }

    get painelAberto() {
        return null;
    }

    abrirPainelNovidades() {
        this.fecharPaineis();
        this.painelNovidadesSubject.next(true);

        this.algumPainelAbertoSubject.next(false);
    }

    fecharPainelNovidades() {
        this.painelNovidadesSubject.next(null);
        this.algumPainelAbertoSubject.next(false);
    }

    abrirPainelSpinner() {
        this.fecharPaineis();
        this.painelSpinnerSubject.next(true);

        this.algumPainelAbertoSubject.next(false);
    }

    fecharPainelSpinner() {
        this.painelSpinnerSubject.next(null);
        this.algumPainelAbertoSubject.next(false);
    }

    fecharPaineis() {
        this.fecharPainelNovidades();
        this.fecharPainelSpinner();
    }
}
