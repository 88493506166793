<nav class="flex-row flex-1 center-start background-primary">
  <img class="logo" src="assets\ui\logo.svg" />
  <button
    class="navbar-button"
    mat-icon-button
    matTooltip="Uma nova versão está disponível, clique aqui para atualizar"
    (click)="atualizarApp()"
    *ngIf="checkForUpdateService.UpdateAvailable | async"
  >
    <mat-icon>update</mat-icon>
  </button>
  <span class="flex-1"></span>

  <app-historico-atualizacoes
    matTooltip="Histórico atualizações"
    class="bar-block flex-row center-center"
  ></app-historico-atualizacoes>

  <app-indicador-trial
    class="bar-block flex-row center-center"
  ></app-indicador-trial>
  <app-timer
    *ngIf="!mobile"
    class="bar-block timer-wrapper flex-row center-center"
  ></app-timer>
  <div class="bar-block user-menu flex-row center-center">
    <a class="flex-row center-center" [matMenuTriggerFor]="menu">
      <img
        *ngIf="model.user_foto"
        src="{{ model.user_foto }}"
        class="profile-photo"
      />
      <span class="username-wrapper">{{ model.user_nome }}</span>
      <mat-icon>arrow_drop_down</mat-icon>
    </a>
  </div>
</nav>
<mat-menu #menu="matMenu">
  <button
    mat-menu-item
    (click)="lnkPerfil_click($event)"
    *ngIf="!(statusService.$AppOffline | async)"
  >
    <span>Meu perfil</span>
    <mat-icon>person</mat-icon>
  </button>
  <button mat-menu-item (click)="lnkPreferencias_click($event)">
    <span>Preferências</span>
    <mat-icon>settings</mat-icon>
  </button>
  <button *ngIf="exibirInstalacao" mat-menu-item (click)="addToHomeScreen()">
    <span>Instalar no Desktop</span>
    <mat-icon>system_update_alt</mat-icon>
  </button>
  <button
    mat-menu-item
    (click)="logoff()"
    *ngIf="!(statusService.$AppOffline | async)"
  >
    <span>Sair</span>
    <mat-icon>power_settings_new</mat-icon>
  </button>
</mat-menu>
