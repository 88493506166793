import { Injectable } from '@angular/core';

import { Lei } from '../models/Lei';
import { ItemVerificar } from './storage-services/itens.verificar.service';
import { UserdataDTO } from '../models/UserdataSync';

@Injectable()
export class SynchronizationService {
    // private started = false;

    // private resyncTimeSeconds = AppConfig.synchronizationRefreshInterval * 1000;
    // private timer = Observable.timer(this.resyncTimeSeconds, this.resyncTimeSeconds);
    // private s_syncTimer: Subscription;

    // public Status: Observable<StatusSincronizacao>;
    // private _Status = new BehaviorSubject<StatusSincronizacao>(null);
    // private sincronizando = false;

    // constructor(
    //     private connectionService: ConnectionService,
    //     private leiService: LeiService,
    //     private indicadorSincronizacaoService: IndicadorSincronizacaoService,
    //     private usuarioPreferenciasService: UsuarioPreferenciasService,
    //     private usuarioGuiasService: UsuarioGuiasService,
    //     private usuarioMarcacoesService: UsuarioMarcacoesService,
    //     private usuarioComentariosService: UsuarioComentariosService,
    //     private usuarioGrifosService: UsuarioGrifosService,
    //     private usuarioApontamentosService: UsuarioApontamentosService,
    //     private usuarioProvasService: UsuarioProvasService,
    //     private usuarioEstatisticasService: UsuarioEstatisticasService,
    //     private usuarioAnotacoesService: UsuarioAnotacoesService,
    // ) {
    //     this.Status = this._Status.asObservable();
    // }

    // public get DataUltimaSincronizacao(): Date {
    //     let strData = localStorage.getItem('DataUltimaSincronizacao');
    //     return (strData) ? new Date(strData) : null;
    // }

    // public set DataUltimaSincronizacao(value: Date) {
    //     localStorage.setItem('DataUltimaSincronizacao', value.toString());
    // }

    // public start(sincronizarPrimeiro = false): void {
    //     if (this.started)
    //         return;

    //     if (AppConfig.synchronizationRefreshInterval > 0) {
    //         let segundosUltimaSincronizacao = -1;
    //         this.s_syncTimer = this.timer.subscribe(() => {
    //             if (this.DataUltimaSincronizacao) {
    //                 const tempoAgora = new Date().getTime();
    //                 const tempoUltimaSincronizacao = this.DataUltimaSincronizacao.getTime();

    //                 segundosUltimaSincronizacao = (tempoAgora - tempoUltimaSincronizacao) / 1000;
    //             }

    //             if (!this.sincronizando && (segundosUltimaSincronizacao === -1 || segundosUltimaSincronizacao >= AppConfig.synchronizationRefreshInterval))
    //                 this.sync().catch(err => console.log(err));
    //         });
    //     }

    //     this.started = true;
    // }

    // public stop(): void {
    //     if (!this.started)
    //         return;

    //     this.s_syncTimer.unsubscribe();
    //     this.started = false;
    // }

    // public sync(primeiraSincronizacaoSessao = false): Promise<boolean> {
    //     const primeiraSincronizacao = !this.DataUltimaSincronizacao;
    //     const dataSincronizacao = new Date();
    //     const qtdLeisLote = AppConfig.quantidadeLeisLoteDownload;

    //     let qtdLotesBaixar = 0;
    //     let qtdLotesBaixados = 0;

    //     let qtdLotesSalvar = 0;
    //     let qtdLotesSalvos = 0;

    //     const alterarMensagemIndicador = (mensagem: string) => {
    //         this.indicadorSincronizacaoService.alterarMensagem(mensagem);
    //     };

    //     const alterarProgressoIndicador = (progresso: number) => {
    //         this.indicadorSincronizacaoService.alterarProgresso(progresso);
    //     };

    //     const atualizarProgressoDownloadLei = () => {
    //         const progressoDownload = qtdLotesBaixados / qtdLotesBaixar * 100;
    //         const progressoSalvamento = qtdLotesSalvos / qtdLotesSalvar * 100;

    //         const progresso = Math.floor((progressoDownload + progressoSalvamento) / 2);
    //         alterarProgressoIndicador(progresso);
    //     }

    //     const consultarLeisDisponiveis = (): Promise<IdDataLei[]> => {
    //         alterarMensagemIndicador('Obtendo lista de leis');

    //         return new Promise((onsuccess, onerror) => {
    //             this.connectionService.getRequest('leis').then(response => {
    //                 const leis = <IdDataLei[]>JSON.parse(CompressionHelper.strUnzip(response));
    //                 onsuccess(leis);
    //             }).catch(err => {
    //                 onerror(err);
    //             });
    //         });
    //     };

    //     const excluirLei = (idLei: string): Promise<void> => {
    //         return new Promise(onsuccess => {
    //             StorageHelper.deleteLei(idLei, null, onsuccess());
    //         });
    //     }

    //     const verificarItensBaixar = (idsNuvem: IdDataLei[]): Promise<string[]> => {
    //         const ids = new Array<string>();
    //         alterarMensagemIndicador('Verificando as leis');

    //         return new Promise((onsuccess, onerror) => {
    //             this.leiService.carregarDatas()
    //                 .then(idsLocal => {
    //                     idsNuvem.forEach(idNuvem => {
    //                         const iLeiLocal = idsLocal.findIndex(id => id.id === idNuvem.id);
    //                         if (iLeiLocal === -1) {
    //                             ids.push(idNuvem.id);
    //                         } else {
    //                             const idLocal = idsLocal[iLeiLocal];
    //                             const dataIdNuvem = new Date(idNuvem.dataHoraUltimaModificacao);

    //                             if (dataIdNuvem > idLocal.dataHoraUltimaModificacao)
    //                                 ids.push(idNuvem.id);
    //                         }
    //                     });

    //                     const excluir = new Array<Promise<void>>();

    //                     idsLocal.forEach(idLocal => {
    //                         const iLeiNuvem = idsNuvem.findIndex(id => id.id === idLocal.id);
    //                         if (iLeiNuvem === -1)
    //                             excluir.push(excluirLei(idLocal.id));
    //                     });

    //                     Promise.all(excluir)
    //                         .then(() => {
    //                             onsuccess(ids);
    //                         }).catch(e => onerror(e));
    //                 })
    //                 .catch(e => onerror(e));
    //         });
    //     }

    //     const baixarLote = (lote: string[]): Promise<void> => {
    //         alterarMensagemIndicador('Baixando leis');

    //         return new Promise((onsuccess, onerror) => {
    //             this.connectionService.postRequest('leis', lote)
    //                 .then(response => {
    //                     const json = CompressionHelper.strUnzip(response);
    //                     const leisProcessadas = <Array<Lei>>JSON.parse(json);

    //                     qtdLotesBaixados++;
    //                     atualizarProgressoDownloadLei();

    //                     this.leiService.inserirLeis(leisProcessadas).then(() => {
    //                         qtdLotesSalvos++;
    //                         atualizarProgressoDownloadLei();

    //                         onsuccess();
    //                     }).catch(e => onerror(e));
    //                 }).catch(e => onerror(e));
    //         });
    //     };

    //     const processarLotesLeis = (ids: string[]): Promise<void> => {
    //         alterarProgressoIndicador(0);

    //         const lotes = new Array<Array<string>>();

    //         return new Promise((onsuccess, onerror) => {
    //             let lote = new Array<string>();

    //             ids.forEach(id => {
    //                 if (lote.length === qtdLeisLote) {
    //                     lotes.push(lote);
    //                     lote = new Array<string>();
    //                 }

    //                 lote.push(id);
    //             });

    //             lotes.push(lote);
    //             qtdLotesBaixar = lotes.length;
    //             qtdLotesSalvar = lotes.length;

    //             const promises = new Array<Promise<void>>();

    //             lotes.forEach(lote => {
    //                 promises.push(baixarLote(lote));
    //             });

    //             Promise.all(promises)
    //                 .then(() => onsuccess())
    //                 .catch(e => onerror(e));
    //         });
    //     };

    //     const baixarLeis = (): Promise<void> => {
    //         return new Promise((onsuccess, onerror) => {
    //             consultarLeisDisponiveis().then(idsNuvem => {
    //                 verificarItensBaixar(idsNuvem).then(ids => {
    //                     processarLotesLeis(ids)
    //                         .then(() => onsuccess())
    //                         .catch(e => onerror(e));
    //                 }).catch(e => onerror(e));
    //             }).catch(e => onerror(e));
    //         });
    //     };

    //     const salvarPreferenciasUsuario = (userdata: UserDataSync): Promise<void> => {
    //         return new Promise(onsuccess => {
    //             this.usuarioPreferenciasService.alterarConfiguracoes(ConfiguracoesUsuario.fromModel(new ConfiguracoesUsuario(), userdata));
    //             onsuccess();
    //         });
    //     }

    //     const baixarDadosUsuario = (): Promise<void> => {
    //         alterarMensagemIndicador('Baixando seus dados');
    //         alterarProgressoIndicador(0);

    //         return new Promise((onsuccess, onerror) => {
    //             this.connectionService.getRequest('userdata').then((response) => {
    //                 const json = CompressionHelper.strUnzip(response);
    //                 const userdata = <UserDataSync>JSON.parse(json).userData;

    //                 const promises = new Array<Promise<void>>();

    //                 promises.push(salvarPreferenciasUsuario(userdata));
    //                 promises.push(this.usuarioGuiasService.processarSincronizacao(userdata.guias, true)
    //                     .catch(err => onerror(err)));

    //                 promises.push(this.usuarioProvasService.processarSincronizacao(userdata.provaDatasource, true)
    //                     .catch(err => onerror(err)));

    //                 promises.push(this.usuarioMarcacoesService.sincronizar(userdata.marcacoesProva, true)
    //                     .catch(err => onerror(err)));

    //                 promises.push(this.usuarioComentariosService.sincronizar(userdata.comentarios, true)
    //                     .catch(err => onerror(err)));

    //                 promises.push(this.usuarioGrifosService.sincronizar(userdata.grifos, true)
    //                     .catch(err => onerror(err)));

    //                 promises.push(this.usuarioApontamentosService.sincronizar(userdata.apontamentos, true)
    //                     .catch(err => onerror(err)));

    //                 promises.push(this.usuarioEstatisticasService.calcularEstatisticas(userdata.estatisticasLeitura));

    //                 Promise.all(promises)
    //                     .then(() => {
    //                         alterarMensagemIndicador('Quase pronto');
    //                         setTimeout(() => onsuccess(), 5000);
    //                     }).catch(e => onerror(e));
    //             }).catch(e => onerror(e));
    //         });
    //     };

    //     const sincronizarDadosUsuario = (): Promise<void> => {
    //         alterarMensagemIndicador('Sincronizando seus dados');
    //         alterarProgressoIndicador(0);

    //         const syncModel = new UserDataSync();

    //         const carregarPreferenciasUsuario = (): Promise<void> => {
    //             return new Promise(onsuccess => {
    //                 const prefs = this.usuarioPreferenciasService.Configuracoes;

    //                 syncModel.authToken = prefs.authToken;

    //                 syncModel.email = prefs.email;
    //                 syncModel.nome = prefs.nome;
    //                 syncModel.sobrenome = prefs.sobrenome;
    //                 syncModel.foto = prefs.foto;

    //                 syncModel.preferencias = prefs.preferenciasUsuario;
    //                 syncModel.preferencias.idTema = prefs.idTema;
    //                 syncModel.parametrosCaneta = prefs.parametrosCaneta;

    //                 syncModel.palavrasMinuto = prefs.palavrasMinuto;
    //                 syncModel.exibirItensRevogados = prefs.exibirItensRevogados;
    //                 syncModel.perfilUsuario = prefs.perfilUsuario;
    //                 syncModel.dataUltimaAlteracao = prefs.dataUltimaAlteracao;

    //                 onsuccess();
    //             });
    //         };

    //         const carregarGuias = (): Promise<void> => {
    //             return new Promise(onsuccess => {
    //                 this.usuarioGuiasService.buscarGuias().then(guias => {
    //                     syncModel.guias = guias;
    //                     onsuccess();
    //                 });
    //             });
    //         };

    //         const carregarProvaDatasource = (): Promise<void> => {
    //             return new Promise(onsuccess => {
    //                 const buscas = new Array<Promise<any>>();

    //                 syncModel.provaDatasource = new ProvaDatasource();
    //                 buscas.push(this.usuarioProvasService.buscarInstituicao().then(i => syncModel.provaDatasource.instituicoes = i));
    //                 buscas.push(this.usuarioProvasService.buscarBanca().then(i => syncModel.provaDatasource.bancas = i));
    //                 buscas.push(this.usuarioProvasService.buscarAno().then(i => syncModel.provaDatasource.anos = i));
    //                 buscas.push(this.usuarioProvasService.buscarTipo().then(i => syncModel.provaDatasource.tipos = i));
    //                 buscas.push(this.usuarioProvasService.buscarCargo().then(i => syncModel.provaDatasource.cargos = i));

    //                 Promise.all(buscas).then(() => onsuccess());
    //             });
    //         };

    //         const carregarAnotacoes = (): Promise<void> => {
    //             return new Promise(onsuccess => {
    //                 this.usuarioAnotacoesService.BuscarAnotacoes().then(anotacoes => {
    //                     syncModel.marcacoesProva = anotacoes.marcacoes;
    //                     syncModel.comentarios = anotacoes.comentarios;
    //                     syncModel.grifos = anotacoes.grifos;

    //                     onsuccess();
    //                 });
    //             });
    //         };

    //         const carregarApontamentos = (): Promise<void> => {
    //             return new Promise(onsuccess => {
    //                 this.usuarioApontamentosService.buscar().then(apontamentos => {
    //                     syncModel.apontamentos = apontamentos;

    //                     onsuccess();
    //                 });
    //             });
    //         };

    //         const carregarEstatisticas = (): Promise<void> => {
    //             return new Promise(onsuccess => {
    //                 this.usuarioEstatisticasService.buscar().then(estatisticas => {
    //                     syncModel.estatisticasLeitura = estatisticas;

    //                     onsuccess();
    //                 });
    //             });
    //         };

    //         const enviarDados = (): Promise<UserDataSync> => {
    //             return new Promise((onsuccess, onerror) => {
    //                 this.connectionService.postRequest('userdata', syncModel).then(response => {
    //                     let userdata = null;
    //                     if (response) {
    //                         const json = CompressionHelper.strUnzip(response);
    //                         userdata = <UserDataSync>JSON.parse(json).userData;
    //                     }

    //                     onsuccess(userdata);
    //                 }).catch(e => onerror(e));
    //             });
    //         }

    //         return new Promise((onsuccess, onerror) => {
    //             const tarefas = new Array<Promise<void>>();

    //             tarefas.push(carregarPreferenciasUsuario());
    //             tarefas.push(carregarGuias());
    //             tarefas.push(carregarProvaDatasource());
    //             tarefas.push(carregarAnotacoes());
    //             tarefas.push(carregarApontamentos());
    //             tarefas.push(carregarEstatisticas());

    //             Promise.all(tarefas).then(() => {
    //                 enviarDados().then(userdata => {
    //                     const tasks = new Array<Promise<void>>();

    //                     tasks.push(this.usuarioGuiasService.clearGCRecords());

    //                     tasks.push(this.usuarioProvasService.clearGCRecords(this.usuarioProvasService.instituicoesCollectionName).catch(e => onerror(e)));
    //                     tasks.push(this.usuarioProvasService.clearGCRecords(this.usuarioProvasService.bancasCollectionName).catch(e => onerror(e)));
    //                     tasks.push(this.usuarioProvasService.clearGCRecords(this.usuarioProvasService.anosCollectionName).catch(e => onerror(e)));
    //                     tasks.push(this.usuarioProvasService.clearGCRecords(this.usuarioProvasService.tiposCollectionName).catch(e => onerror(e)));
    //                     tasks.push(this.usuarioProvasService.clearGCRecords(this.usuarioProvasService.cargosCollectionName).catch(e => onerror(e)));

    //                     tasks.push(this.usuarioMarcacoesService.clearGCRecords().catch(e => onerror(e)));
    //                     tasks.push(this.usuarioComentariosService.clearGCRecords().catch(e => onerror(e)));
    //                     tasks.push(this.usuarioGrifosService.clearGCRecords().catch(e => onerror(e)));

    //                     tasks.push(this.usuarioApontamentosService.clearGCRecords().catch(e => onerror(e)));
    //                     tasks.push(this.usuarioEstatisticasService.clearGCRecords().catch(e => onerror(e)));

    //                     if (userdata) {
    //                         tasks.push(salvarPreferenciasUsuario(userdata).catch(e => onerror(e)));
    //                         tasks.push(this.usuarioGuiasService.processarSincronizacao(userdata.guias, false).catch(e => onerror(e)));
    //                         tasks.push(this.usuarioProvasService.processarSincronizacao(userdata.provaDatasource, false).catch(e => onerror(e)));
    //                         tasks.push(this.usuarioMarcacoesService.sincronizar(userdata.marcacoesProva, false).catch(e => onerror(e)));
    //                         tasks.push(this.usuarioComentariosService.sincronizar(userdata.comentarios, false).catch(e => onerror(e)));
    //                         tasks.push(this.usuarioGrifosService.sincronizar(userdata.grifos, false).catch(e => onerror(e)));
    //                         tasks.push(this.usuarioApontamentosService.sincronizar(userdata.apontamentos, false).catch(e => onerror(e)));
    //                         tasks.push(this.usuarioEstatisticasService.calcularEstatisticas(userdata.estatisticasLeitura).catch(e => onerror(e)));
    //                     }

    //                     Promise.all(tasks).then(() => {
    //                         alterarMensagemIndicador('Dados sincronizados com sucesso!');
    //                         onsuccess();
    //                     }).catch(err => onerror(err));
    //                 }).catch(err => onerror(err));
    //             });
    //         });
    //     };

    //     const finalizarSincronizacao = (callback, err: string = null) => {
    //         alterarMensagemIndicador(null);
    //         alterarProgressoIndicador(0);

    //         if (err)
    //             callback(err);
    //         else {
    //             this.DataUltimaSincronizacao = dataSincronizacao;
    //             callback();
    //         }
    //     };

    //     const sincronizar = (): Promise<void> => {
    //         return new Promise((onsuccess, onerror) => {
    //             if (primeiraSincronizacao) {
    //                 baixarLeis().then(() => {
    //                     baixarDadosUsuario().then(() => {
    //                         finalizarSincronizacao(onsuccess);
    //                     }).catch(e => finalizarSincronizacao(onerror, e));
    //                 }).catch(e => finalizarSincronizacao(onerror, e));
    //             } else {
    //                 baixarLeis().then(() => {
    //                     sincronizarDadosUsuario().then(() => {
    //                         finalizarSincronizacao(onsuccess);
    //                     }).catch(e => finalizarSincronizacao(onerror, e));
    //                 }).catch(e => finalizarSincronizacao(onerror, e));
    //             }
    //         });
    //     };

    //     return new Promise((onsuccess, onerror) => {
    //         this.sincronizando = true;
    //         sincronizar().then(() => {
    //             this.sincronizando = false;
    //             this.indicadorSincronizacaoService.fecharPopup();
    //             onsuccess();
    //         }).catch(err => {
    //             this.sincronizando = false;
    //             this.indicadorSincronizacaoService.fecharPopup();
    //             onerror(err);
    //         });
    //     });
    // }
}

export class SyncResult {
    userData: UserdataDTO;
    leis: Lei[];
    itensVerificar: ItemVerificar[];
}

export class StatusSincronizacao {
    sincronizando: boolean;
    primeiraSincronizacao: boolean;
    progresso: number;
    mensagem: string;
    progressoIndeterminado: boolean;

    constructor() {
        this.sincronizando = true;
        this.progresso = 0;
        this.mensagem = '';
    }
}
