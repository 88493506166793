import { Item, FuncoesItem } from "../Item";
import { Versao } from "../Versao";
import { TrechoTexto } from "./TrechoTexto";
import { Marcacao } from "../Marcacao";
import { Comentario } from "../Comentario";
import { Grifo } from "../Grifo";
import { MatchBuscaTexto } from "../MatchBuscaTexto";
import { Intervalo } from "../Intervalo";
import { Referencia } from "../Referencia";
import { ReferenciaGerenciada } from "../ReferenciaGerenciada";

export class TextoPagina {
  public id: string;
  public idLei: string;

  public index: number;
  public versoes: Versao[];
  public indexVersao: number;
  public tipoTexto: string;

  public marcacoesProva: Marcacao[];
  public comentarios: Comentario[];
  public comentariosGerenciados: Comentario[];
  public referenciasGerenciado: ReferenciaGerenciada[];
  public grifos: Grifo[];
  public referencias: Referencia[];
  public resultadosBusca: MatchBuscaTexto[];
  public resultadosBuscaAtivo: MatchBuscaTexto;

  public trechosPrefixo: TrechoTexto[];
  public trechosTexto: TrechoTexto[];
  public lida: boolean;
  public breadcrumb: string;
  public tituloLei: string;
  matchBuscaRapida: Intervalo;

  constructor(item: Item) {
    this.trechosPrefixo = new Array<TrechoTexto>();
    this.trechosTexto = new Array<TrechoTexto>();
    this.versoes = new Array<Versao>();
    this.resultadosBusca = new Array<MatchBuscaTexto>();

    if (item) {
      this.id = item.id;

      this.versoes = item.versoes;

      if (this.versoes) {
        // setar a utlima versão no item texto por default
        this.indexVersao = this.versoes.length - 1;
        this.tipoTexto = `texto-${FuncoesItem.descreverTipoItem(
          item
        ).toLowerCase()}`;
      }
    }
  }

  static gerarIntroducao(lei: string): TextoPagina {
    const linha = new TextoPagina(null);
    linha.tipoTexto = "texto-introducao-busca";

    const versao = new Versao();
    versao.prefixo = lei;
    linha.versoes.push(versao);

    return linha;
  }
}

export class TextoPaginaFunctions {
  static getVersao(texto: TextoPagina): Versao {
    return texto.versoes[texto.indexVersao];
  }
}
