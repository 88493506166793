<button
  *ngIf="historicoAtualizacoes && historicoAtualizacoes.length <= 0"
  mat-icon-button
  (click)="openDialog()"
  class="button-historico-atualizacao"
>
  <mat-icon class="material-icons-outlined">notifications</mat-icon>
</button>

<button
  *ngIf="historicoAtualizacoes && historicoAtualizacoes.length > 0"
  mat-icon-button
  (click)="openDialog()"
>
  <mat-icon
    class="material-icons-outlined"
    [matBadge]="historicoAtualizacoes.length"
    matBadgeColor="warn"
    matBadgeSize="small"
    matBadgePosition="below after"
    >notifications_active</mat-icon
  >
</button>
