<h1 mat-dialog-title>{{ data.nomeLei }}</h1>

<div class="flex-column">
  <div class="info-data">
    <span
      *ngIf="
        data.dataAtualizacao.substring(0, 4) != '0001';
        else SemInformacaoData
      "
    >
      <span
        *ngIf="
          data.dataAtualizacao.substring(4, 8) == '0101';
          else DataCompleta
        "
        class="data-update"
        >Última alteração em {{ data.dataAtualizacao.substring(0, 4) }}</span
      >
      <ng-template #DataCompleta>
        <span class="data-update" [innerText]="data.dataAtualizacao"></span>
      </ng-template>
    </span>

    <ng-template #SemInformacaoData>
      <span class="data-update">Última alteração indefinida</span>
    </ng-template>
  </div>
  <div class="marcacoes flex-row center-center">
    <div
      class="marcacao flex-row"
      [style.background]="data.corTagProva"
      [style.color]="'white'"
      (click)="btnMarcacoesProva_click()"
    >
      <span class="icon icon-prova"></span>
      <span class="count">{{ data.qtdMarcacoes }}</span>
    </div>
    <div
      class="marcacao flex-row"
      [style.background]="data.corTagComentario"
      [style.color]="'white'"
      (click)="btnComentarios_click()"
    >
      <span class="icon icon-comentario"></span>
      <span class="count">{{ data.qtdComentarios }}</span>
    </div>
    <div
      class="marcacao flex-row"
      [style.background]="data.corTagMnemonico"
      [style.color]="'white'"
      (click)="btnMnemonicos_click()"
    >

      <mat-icon
      backgroundContrast
      class="icon material-icons-outlined icon-titulo"
      >title</mat-icon
    >
      <span class="count">{{ data.qtdMnemonicos }}</span>
    </div>
    <div
      class="marcacao flex-row"
      [style.background]="data.corCaneta1"
      [style.color]="'white'"
      (click)="btnMarcacoesCaneta1_click()"
    >
      <span class="icon icon-marcador"></span>
      <span class="count">{{ data.qtdGrifo1 }}</span>
    </div>
    <div
      class="marcacao flex-row"
      [style.background]="data.corCaneta2"
      [style.color]="'white'"
      (click)="btnMarcacoesCaneta2_click()"
    >
      <span class="icon icon-marcador"></span>
      <span class="count">{{ data.qtdGrifo2 }}</span>
    </div>
    <div
      class="marcacao flex-row"
      [style.background]="data.corCaneta3"
      [style.color]="'white'"
      (click)="btnMarcacoesCaneta3_click()"
    >
      <span class="icon icon-marcador"></span>
      <span class="count">{{ data.qtdGrifo3 }}</span>
    </div>
    <div
      class="marcacao flex-row"
      [style.background]="data.corCaneta4"
      [style.color]="'white'"
      (click)="btnMarcacoesCaneta4_click()"
    >
      <span class="icon icon-marcador"></span>
      <span class="count">{{ data.qtdGrifo4 }}</span>
    </div>
    <div
      class="marcacao flex-row"
      [style.background]="data.corCaneta5"
      [style.color]="'white'"
      (click)="btnMarcacoesCaneta5_click()"
    >
      <span class="icon icon-marcador"></span>
      <span class="count">{{ data.qtdGrifo5 }}</span>
    </div>
  </div>
  <div class="estatisticas shadow flex-column">
    <div class="row flex-row">
      <div class="key flex-1">% Lida</div>
      <div class="val flex-1">{{ data.progressoLido }}%</div>
    </div>
    <div class="row flex-row">
      <div class="key flex-1">Tempo restante</div>
      <div class="val flex-1">{{ data.tempoRestante }}</div>
    </div>
    <div class="row flex-row">
      <div class="key flex-1">Tempo total</div>
      <div class="val flex-1">{{ data.tempoTotal }}</div>
    </div>
    <div class="row flex-row">
      <div class="key flex-1">Tempo timer</div>
      <div class="val flex-1">{{ data.tempoTimer }}</div>
    </div>
  </div>
  <div class="acoes flex-row center-center">
    <button mat-flat-button color="warn" (click)="btnVoltar_click()">
      Voltar
    </button>
    <button mat-flat-button color="primary" (click)="btnAbrir_click()">
      Ler agora!
    </button>
  </div>
</div>
