import { Injectable } from '@angular/core';
import { Observable ,  BehaviorSubject } from 'rxjs';

@Injectable()
export class SplashService {

    public params: Observable<SplashParameters>;
    private paramsSubject = new BehaviorSubject<SplashParameters>(null);

    public progress: Observable<number>;
    private progressSubject = new BehaviorSubject<number>(null);

    public message: Observable<string>;
    private messageSubject = new BehaviorSubject<string>(null);

    public cancelationMessage: Observable<boolean>;
    private cancelationMessageSubject = new BehaviorSubject<boolean>(null);

    public constructor() {
        this.params = this.paramsSubject.asObservable();
        this.progress = this.progressSubject.asObservable();
        this.message = this.messageSubject.asObservable();
        this.cancelationMessage = this.cancelationMessageSubject.asObservable();
    }

    public mostrarSplashScreen(params: SplashParameters): void {
        this.paramsSubject.next(params);
    }

    public ocultarSplashScreen(): void {
        this.paramsSubject.next(null);
        this.progressSubject.next(null);
        this.messageSubject.next(null);
    }

    set mensagem(msg: string) {
        this.messageSubject.next(msg);
    }

    set progresso(num: number) {
        this.progressSubject.next(num);
    }

    public cancelarOperacaoEmProcesso() {
        this.cancelationMessageSubject.next(true);
    }
}

export class SplashParameters {
    public mensagens: string[];
    public origemSplash: string;
}
