<div class="container">
  <app-leitor-content-panelbusca *ngIf="mostrarPainelBusca"></app-leitor-content-panelbusca>
  <app-leitor-toolbar></app-leitor-toolbar>

  <ng-container *ngIf="mostrarResultadoBuscaAvancada; else elseTemplate">
    <app-leitor-content-panelbuscaavancada></app-leitor-content-panelbuscaavancada>
  </ng-container>
  <ng-template #elseTemplate>
    <ng-container *ngIf="mostrarNovaGuia; else elseTemplate">
      <div nova-guia></div>
    </ng-container>
    <ng-template #elseTemplate>
      <app-pagina></app-pagina>
    </ng-template>
  </ng-template>

  <app-leitor-content-panelspinner></app-leitor-content-panelspinner>
  <app-splash></app-splash>
</div>
