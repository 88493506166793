<h3 mat-dialog-title class="historico-atualizacao-title">
  Acompanhamento de atualizações
</h3>
<div mat-dialog-content class="historico-atualizacao-dialog">
  <mat-accordion
    *ngIf="data.historicoAtualizacoes && data.historicoAtualizacoes.length > 0"
  >
    <mat-expansion-panel [expanded]="true" class="expansion-atualizacao-dialog">
      <mat-expansion-panel-header class="historico-atualizacao-header">
        <mat-panel-title>Últimas atualizações não lidas</mat-panel-title>
        <mat-panel-description> </mat-panel-description>
      </mat-expansion-panel-header>

      <mat-list>
        <mat-list-item *ngFor="let item of data.historicoAtualizacoes">
          <div mat-line>
            {{ item.dataAtualizacao | date : "dd/MM/yyyy" }} -
            {{ item.descricao }}
          </div>
          <button
            class="historico-atualizacao-button-new"
            mat-icon-button
            (click)="abrirEmNovaGuia(item.tituloLei, item.idLei, item.id)"
          >
            <mat-icon
              mat-list-icon
              matTooltip=""
              class="material-icons-outlined"
            >
              open_in_new
            </mat-icon>
          </button>
        </mat-list-item>
      </mat-list>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion>
    <mat-expansion-panel class="expansion-atualizacao-dialog">
      <mat-expansion-panel-header class="historico-atualizacao-header">
        <mat-panel-title>Histórico de leis</mat-panel-title>
        <mat-panel-description> </mat-panel-description>
      </mat-expansion-panel-header>

      <mat-list>
        <mat-list-item *ngFor="let itemLeis of historicoAtualizacoesLeis">
          <div mat-line>
            {{ itemLeis.dataAtualizacao | date : "dd/MM/yyyy" }} -
            {{ itemLeis.descricao }}
          </div>
          <button
            class="historico-atualizacao-button-new"
            mat-icon-button
            (click)="
              abrirEmNovaGuia(itemLeis.tituloLei, itemLeis.idLei, itemLeis.id)
            "
          >
            <mat-icon
              mat-list-icon
              matTooltip=""
              class="material-icons-outlined"
            >
              open_in_new
            </mat-icon>
          </button>
        </mat-list-item>
      </mat-list>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion>
    <mat-expansion-panel class="expansion-atualizacao-dialog">
      <mat-expansion-panel-header class="historico-atualizacao-header">
        <mat-panel-title>Histórico de referência</mat-panel-title>
        <mat-panel-description> </mat-panel-description>
      </mat-expansion-panel-header>

      <mat-list>
        <mat-list-item
          *ngFor="let itemReferencia of historicoAtualizacoesReferencia"
        >
          <div mat-line>
            {{ itemReferencia.dataAtualizacao | date : "dd/MM/yyyy" }} -
            {{ itemReferencia.descricao }}
          </div>
          <button
            class="historico-atualizacao-button-new"
            mat-icon-button
            (click)="
              abrirEmNovaGuia(
                itemReferencia.tituloLei,
                itemReferencia.idLei,
                itemReferencia.id
              )
            "
          >
            <mat-icon
              mat-list-icon
              matTooltip=""
              class="material-icons-outlined"
            >
              open_in_new
            </mat-icon>
          </button>
        </mat-list-item>
      </mat-list>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion>
    <mat-expansion-panel class="expansion-atualizacao-dialog">
      <mat-expansion-panel-header class="historico-atualizacao-header">
        <mat-panel-title>Histórico de comentários</mat-panel-title>
        <mat-panel-description> </mat-panel-description>
      </mat-expansion-panel-header>

      <mat-list>
        <mat-list-item
          *ngFor="let itemComentarios of historicoAtualizacoesComentarios"
        >
          <div mat-line>
            {{ itemComentarios.dataAtualizacao | date : "dd/MM/yyyy" }} -
            {{ itemComentarios.descricao }}
          </div>
          <button
            class="historico-atualizacao-button-new"
            mat-icon-button
            (click)="
              abrirEmNovaGuia(
                itemComentarios.tituloLei,
                itemComentarios.idLei,
                itemComentarios.id
              )
            "
          >
            <mat-icon
              mat-list-icon
              matTooltip=""
              class="material-icons-outlined"
            >
              open_in_new
            </mat-icon>
          </button>
        </mat-list-item>
      </mat-list>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<div mat-dialog-actions class="historico-atualizacao-dialog-actions">
  <button
    mat-button
    mat-raised-button
    color="warn"
    (click)="confirmarCloseHistorico(data.historicoAtualizacoes)"
  >
    Fechar
  </button>
</div>
