<div id="paginaResultadosBusca" *ngIf="paginas && paginas.length > 0">
  <div class="paginador-placeholder" *ngIf="!carregando">
    <app-paginador
      [paginas]="paginas"
      [indexAtual]="indexPaginaAtual"
      [textoCentralPaginador]="textoBotaoPaginador"
      (retroceder)="btnLeft_click()"
      (avancar)="btnRight_click()"
      (irPara)="alterarPagina($event)"
    >
    </app-paginador>
  </div>

  <mat-progress-bar *ngIf="carregando" mode="indeterminate"> </mat-progress-bar>

  <div *ngIf="!carregando">
    <div class="viewport">
      <div *uiScroll="let item of datasource">
        <div [ngSwitch]="instanceofClass(item)">
          <div *ngSwitchCase="'CabecalhoGrupoBusca'">
            <div class="flex-row center-center cabecalho-resultado">
              <span class="span-caminho-resultado">
                <span
                  class="item-breadcrumb"
                  *ngFor="let item of item.breadcrumb"
                  >{{ item }}</span
                >
              </span>
              <span class="flex-1"></span>
              <span
                (click)="
                  abrirEmNovaGuia(paginas[indexPaginaAtual], item.idNavegar)
                "
              >
                <img
                  class="img-abrir-guia"
                  src="assets/ui/icons/ic-abrir-nova-guia.png"
                  title="Abrir em nova guia"
                />
              </span>
            </div>
          </div>
          <div *ngSwitchCase="'TextoPagina'">
            <div *ngIf="instanceofClass(item) === 'TextoPagina'">
              <app-marcacao-superior
                [linha]="item"
                [opcoesPonteiros]="opcoesCoresPonteiros"
                [posicaoAtual]="'Acima'"
              >
              </app-marcacao-superior>
              <app-linha [id]="'linha' + item.id" [linha]="item"></app-linha>
              <app-marcacao-superior
                [linha]="item"
                [opcoesPonteiros]="opcoesCoresPonteiros"
                [posicaoAtual]="'Abaixo'"
              >
              </app-marcacao-superior>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
