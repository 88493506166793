import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialogo',
  templateUrl: './dialogo.component.html',
  styleUrls: ['./dialogo.component.scss']
})
export class DialogoComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogoModel
  ) { }

  ngOnInit() { }
}

export class DialogoModel {
  titulo: string;
  mensagem: string;
  opcoes: OpcaoDialogo[];

  public incluirOpcao(descricao: string, cor: string, action: Function) {
    if (!this.opcoes) {
      this.opcoes = new Array<OpcaoDialogo>();
    }

    this.opcoes.push({ descricao: descricao, cor: cor, action: action });
  }
}

export class OpcaoDialogo {
  descricao: string;
  cor: string;
  action: Function;
}
