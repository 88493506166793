export class ItemLookupOrdenacaoNovaGuia {
    label: string
    ordenacao: TipoOrdenacaoNovaGuia
}

export enum TipoOrdenacaoNovaGuia {
    OrdemAlfabetica,
    MaisLidos,
    MenosLidos,
    DataAlteracao
}