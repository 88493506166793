<div *ngIf="exibirModalNovidades" class="modal fade in" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" style="display: block; padding-right: 17px;">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="fechar()">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h4 class="modal-title" id="myModalLabel">Novidades</h4>
            </div>

            <div class="modal-body">

                <!-- <div id="DivRevisaoAnotacao" *ngIf="this.revisoesAnotacao.revisoesAnotacao.value && this.revisaoAnotacaoDataSource.revisoesAnotacao.value.length > 0">
                    <h5>Revise suas anotações</h5>

                    <table mat-table [dataSource]="revisaoAnotacaoDataSource">

                        <div class="btn btn-primary">
                            < !-- Descricao Column -- >
                            <ng-container matColumnDef="descricaoDocumento">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let element" class="td-revisao-anotacao-esquerda">
                                    <span (click)="itemRevisao_Click(element)">{{element.descricaoDocumento}}</span>
                                </td>
                            </ng-container>
                        </div>

                        < !-- Icone Column -- >
                        <ng-container matColumnDef="icone">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element" class="td-revisao-anotacao-direita">
                                <div class="btn-image" (click)="itemRevisao_Click(element)" [title]="element.descricao"
                                    [style.backgroundImage]="element.icone" [style.backgroundColor]="element.cor"></div>
                            </td>
                        </ng-container>
                        <tr mat-row *matRowDef="let row; columns: ['descricaoDocumento', 'icone'];"></tr>


                    </table>
                </div> -->

                <div id="AtualizacaoApp" style="text-align: center" >
                    <h5>Existe uma atualização te esperando :) </h5>
                    <h5>Você poderá ser redirecionado para a tela de login, porém ao entrar novamente, tudo estará como você deixou!</h5>
                    <a (click)="update()" title="Foi detectada uma atualização do aplicativo, clique aqui para atualizar">Atualizar app</a>
                </div>

            </div>
        </div>
    </div>
</div>
