import { Marcacao } from "../Marcacao";
import { Comentario } from "../Comentario";
import { Grifo } from "../Grifo";

export class TrechoTexto {
    public texto: string;
    public backgroundColor: string;
    public modoRealce: string;
    public textDecoration: string;

    public marcacao: Marcacao;
    public comentario: Comentario;
    public grifo: Grifo;
    realceBusca: boolean;

    constructor() {
        this.texto = '';
    }
}
