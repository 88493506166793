import { BaseObj } from "./UserdataSync";

export class Referencia extends BaseObj {
  tipo: TipoReferencia;
  ref: string;
  link: string;
  links: Links[];
  conteudo: string;
  constructor(referencia?: Referencia) {
    super();
    this.conteudo = referencia?.conteudo;
    this.tipo = referencia?.tipo;
    this.ref = referencia?.ref;
    this.link = referencia?.link;
    this.links = referencia?.links;
  }

  static identificarTipoReferencia(item: TipoReferencia): string {
    switch (item) {
      case TipoReferencia.Lei:
        return "Lei ou Artigo de Lei";
      case TipoReferencia.SumulaVinculante:
        return "Súmula (STF/STJ ou Súmula Vinculante)";
      case TipoReferencia.Julgado:
        return "Julgado";
      case TipoReferencia.Informativo:
        return "Informativo";
      case TipoReferencia.ArtigoJuridico:
        return "Artigo Jurídico";
      case TipoReferencia.QuestaoProva:
        return "Questão de Prova";
      case TipoReferencia.Outros:
        return "Outros";
      case TipoReferencia.DicasEstudo:
        return "Dicas de estudo";
    }
  }
  static identificarTipoReferenciaString(item: string): TipoReferencia {
    switch (item) {
      case "0":
        return TipoReferencia.Lei;
      case "1":
        return TipoReferencia.SumulaVinculante;
      case "2":
        return TipoReferencia.Julgado;
      case "3":
        return TipoReferencia.Informativo;
      case "4":
        return TipoReferencia.ArtigoJuridico;
      case "5":
        return TipoReferencia.QuestaoProva;
      case "6":
        return TipoReferencia.Outros;
      case "7":
        return TipoReferencia.DicasEstudo;
    }
  }
}

export class Links {
  idLei: string;
  idItem: string;
  constructor(links?: Links) {
    this.idLei = links?.idLei;
    this.idItem = links?.idItem;
  }
}

export enum TipoReferencia {
  Lei = 0,
  SumulaVinculante = 1,
  Julgado = 2,
  Informativo = 3,
  ArtigoJuridico = 4,
  QuestaoProva = 5,
  Outros = 6,
  DicasEstudo = 7,
}

export class ReferenciaCabecalho extends BaseObj {
  ref: string;
}
