<div class="container flex-column">
  <div app-navbar></div>
  <div class="relative flex-1">
    <div leitor-content></div>
  </div>
  <div class="statusbar-placeholder" *ngIf="configuracoes">
    <app-leitor-statusbar></app-leitor-statusbar>
  </div>
</div>

<div class="painel-lateral-timer container" *ngIf="MostrarPainelLateral" (click)="painelLateral_dismiss()">
  <div class="wrapper shadow" (click)="$event.stopPropagation()">
    <app-painel-lateral></app-painel-lateral>
  </div>
</div>
