<div class="popup-placeholder noselect" (click)="$event.stopPropagation()">
  <div class="header">
    <h3>
      Preferências <span *ngIf="alterado" title="Alterações pendentes">*</span>
    </h3>
  </div>
  <div class="body" *ngIf="model">
    <mat-tab-group #tabGroup mat-stretch-tabs>
      <mat-tab label="Leitor">
        <mat-card>
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <mat-form-field>
                <mat-select
                  placeholder="Seu perfil de uso"
                  [(ngModel)]="model.opcaoModoUsoSelecionada"
                  name="item"
                >
                  <mat-option
                    *ngFor="let opcao of model.opcoesModoUso"
                    [value]="opcao"
                  >
                    {{ opcao.descricao }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <!-- <div *ngIf="!(statusService.$AppOffline | async)" class="flex-row" style="padding-bottom: 12px;">
            <mat-checkbox [(ngModel)]="localData.ModoOffline" (ngModelChange)="modoOffline_changed()">Utilizar 100% offline neste navegador</mat-checkbox>
          </div> -->
          <div class="row">
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Cor</th>
                  <th>Sublinhar marcações</th>
                  <th>Posicionamento marcações</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let marcacao of model.marcadores">
                  <!-- //azure card 358 Desativar botão caiu em prova -->
                  <td *ngIf="marcacao.icone!=='prova'">
                    <div
                      class="icon icon-{{ marcacao.icone }} btn-image-ico"
                      [title]="marcacao.apelido"
                      [style.color]="marcacao.cor"
                    >
                    <mat-icon *ngIf="marcacao.icone === 'titulo'"
                    backgroundContrast
                    class="icon material-icons-outlined icon-titulo"
                    >title</mat-icon
                  >
                  </div>
                  </td>
                  <!-- //azure card 358 Desativar botão caiu em prova -->
                  <td *ngIf="marcacao.icone!=='prova'">
                    <div
                      class="icon icon-color-picker btn-image-ico"
                      [style.color]="marcacao.cor"
                      (click)="colorPick(marcacao)"
                    ></div>
                  </td>
                  <!-- //azure card 358 Desativar botão caiu em prova -->
                  <td *ngIf="marcacao.icone!=='prova'">
                    <mat-slide-toggle
                      [(ngModel)]="marcacao.sublinhar"
                    ></mat-slide-toggle>
                  </td>
                  <!-- //azure card 358 Desativar botão caiu em prova -->
                  <td *ngIf="marcacao.icone!=='prova'">
                    <mat-form-field>
                      <mat-select [(ngModel)]="marcacao.posicionamento">
                        <mat-option
                          *ngFor="let p of posicionamentoMarcacoes"
                          [value]="p"
                        >
                          {{ p }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="row">
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <th>Caneta</th>
                  <th>Cor</th>
                  <th>Apelido</th>
                  <th>Modo Realce</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let grifo of model.grifos">
                  <td>
                    <div
                      class="icon btn-image-ico"
                      [class.icon-marcador]="grifo.modoRealce === 'Grifar'"
                      [class.icon-underline]="grifo.modoRealce === 'Sublinhar'"
                      [title]="grifo.apelido"
                      [style.color]="grifo.cor"
                    ></div>
                  </td>
                  <td>
                    <div
                      class="icon icon-color-picker btn-image-ico"
                      [style.color]="grifo.cor"
                      (click)="colorPick(grifo)"
                    ></div>
                  </td>
                  <td>
                    <!-- <mat-form-field>
                      <input matInput value="value">
                    </mat-form-field> -->
                    <input
                      class="txt-apelido-caneta"
                      matInput
                      [(ngModel)]="grifo.apelido"
                    />
                    <!-- <mat-form-field style="height: 47px;">
                        <input matInput [(ngModel)]="grifo.apelido">
                      </mat-form-field> -->
                  </td>
                  <td>
                    <mat-form-field>
                      <mat-select [(ngModel)]="grifo.modoRealce">
                        <mat-option
                          *ngFor="let r of modoRealceCanetas"
                          [value]="r"
                        >
                          {{ r }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="row">
            <table
              class="table table-striped table-hover"
              [style.width]="'100%'"
            >
              <thead *ngIf="!(statusService.$AppOffline | async)">
                <tr>
                  <th>
                    <button
                      mat-button
                      color=""
                      (click)="resetarConfiguracoes()"
                    >
                      Resetar Configurações
                      <mat-icon
                        aria-hidden="false"
                        aria-label="material-icons-outlined"
                        matTooltip="Resetar Configurações"
                        >restart_alt</mat-icon
                      >
                    </button>
                  </th>
                </tr>
              </thead>
            </table>
          </div>
        </mat-card>
      </mat-tab>

      <mat-tab label="Temas" *ngIf="!(statusService.$AppOffline | async)">
        <mat-card class="temas-tab">
          <div
            class="thumb"
            *ngFor="let tema of model.temas"
            (click)="alterarTema(tema)"
            [style.background]="
              tema.thumbnail ? tema.thumbnail : tema.background
            "
            [style.backgroundPosition]="'center'"
            [style.backgroundSize]="'cover'"
            [ngClass]="{
              selecionado: model.temaSelecionado.background === tema.background
            }"
          ></div>
        </mat-card>
      </mat-tab>

      <mat-tab label="Avançado">
        <mat-card>
          <mat-card-content>
            <div class="row">
              <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <label class="rating-star-label">Velocidade de leitura</label>
              </div>
              <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <app-star-rating
                  placeholder="Velocidade de leitura"
                  [rating]="model.palavrasPorMinuto"
                  (ratingUpdated)="updatedRating($event)"
                ></app-star-rating>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <mat-form-field>
                  <mat-select
                    placeholder="Proporção largura do papel"
                    [(ngModel)]="model.opcaoLarguraPapel"
                    name="item"
                  >
                    <mat-option
                      *ngFor="let opcao of model.opcoesLarguraPapel"
                      [value]="opcao"
                    >
                      {{ opcao }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <br />
            <div class="row" style="display: flex; align-items: center">
              <label>Algo não está funcionando corretamente?</label>
              <button
                mat-raised-button
                (click)="btnAutofix_click()"
                class="btn-autofix"
              >
                Autofix
              </button>
            </div>
          </mat-card-content>
        </mat-card>
      </mat-tab>

      <mat-tab label="Sobre">
        <ng-template matTabContent>
          <mat-card>
            <mat-card-header>
              <mat-card-title>Super Vademecum</mat-card-title>
              <mat-card-subtitle
                >Versão do aplicativo:
                {{ model.versaoApp.numero }}</mat-card-subtitle
              >
            </mat-card-header>
            <mat-card-content>
              <p>
                Copyright &copy; {{ this.currentYear }}
                <a href="#">Plural Apps</a>
              </p>
              <p>Todos os direitos reservados</p>
            </mat-card-content>
            <!-- <button mat-raised-button color="primary" (click)="btnNotasVersao_click()">Notas da versão</button> -->

            <div *ngIf="versoes && versoes.length > 0">
              <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>Notas de versão:</mat-panel-title>
                  </mat-expansion-panel-header>
                  <!-- <p>Que melhorou você ja sabe, mas e os detalhes?</p> -->
                  <div class="versao" *ngFor="let versao of versoes">
                    <span class="numero-versao"
                      >Versão {{ versao.numero }}</span
                    >
                    <ul class="lista-alteracoes">
                      <li *ngFor="let alteracao of versao.alteracoes">
                        {{ alteracao }}
                      </li>
                    </ul>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </mat-card>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>

  <div class="button-bar" *ngIf="alterado">
    <button
      mat-mini-fab
      (click)="cancelar()"
      title="Descartar alterações"
      color="warn"
    >
      <mat-icon>cancel</mat-icon>
    </button>
    <button mat-mini-fab (click)="salvar()" title="Salvar alterações">
      <mat-icon>save</mat-icon>
    </button>
  </div>
</div>
