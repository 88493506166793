import { Component, OnInit } from "@angular/core";
import { HistoricoAtualizacoes } from "src/app/models/HistoricoAtualizacoes";
import { HistoricoAtualizacoesService } from "src/app/services/historico-atualizacoes.service";
import { HistoricoAtualizacoesDialogComponent } from "../../popups/historico-atualizacoes-dialog/historico-atualizacoes-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { DataControleRepositorio } from "src/app/repositorios/dataControle.repositorio";
import {
  DataControle,
  EnumTipoDataControle,
} from "src/app/models/dataControle";
import { SimNaoDialogComponent } from "../../dialogs/sim-nao-dialog/sim-nao-dialog.component";

@Component({
  selector: "app-historico-atualizacoes",
  templateUrl: "./historico-atualizacoes.component.html",
  styleUrls: ["./historico-atualizacoes.component.scss"],
})
export class HistoricoAtualizacoesComponent implements OnInit {
  public historicoAtualizacoes: HistoricoAtualizacoes[];

  constructor(
    private historicoAtualizacoesService: HistoricoAtualizacoesService,
    private dataControleRepositorio: DataControleRepositorio,
    public dialog: MatDialog
  ) {}

  async ngOnInit() {
    const lastDocumentsListCheck = await this.dataControleRepositorio.buscar(
      EnumTipoDataControle.DataVisualizacaoNovidades
    );

    const historico =
      await this.historicoAtualizacoesService.getHistoricoAtualizacoes(-1);

    this.historicoAtualizacoes = historico.filter(
      (his) =>
        new Date(his.dataAtualizacao) >= new Date(lastDocumentsListCheck?.data)
    );
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(HistoricoAtualizacoesDialogComponent, {
      width: "60vw",
      data: {
        historicoAtualizacoes: this.historicoAtualizacoes,
      },
      autoFocus: false,
      panelClass: "historico-dialog-class",
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      this.historicoAtualizacoes = new Array<HistoricoAtualizacoes>();
    });
  }
}
