<div class="container flex-column center-center">
    <h4>Por favor aguarde enquanto atualizamos as leis baixadas no seu equipamento</h4>
    <div class="downloader-window" *ngIf="(downloaderService.StatusDownload | async) as status">
        <div class="status flex-row center-start">{{descricoesStatus[status.step]}}</div>
        <div class="progresso flex-row center-end">
            <span *ngIf="status.showDownloadSize">{{status.displayPackageSize}}</span>
            <mat-icon *ngIf="status.showDownloadSize">cloud_download</mat-icon>
        </div>
        <div class="span-2 progressbar-placeholder flex-row center-start">
            <mat-progress-bar [value]="status.progress" *ngIf="status.progress"></mat-progress-bar>
            <span *ngIf="status.progress">{{status.progress}}%</span>
        </div>
        <div *ngIf="status.leiBaixando" class="span-2 progresso-lei-atual flex-row">
            <div>
                <mat-spinner mode="indeterminate" diameter="32"></mat-spinner>
            </div>
            <span>Atualizando: {{status.leiBaixando.descricao}}</span>
        </div>
        <div class="span-2 lista-download" [ngClass]="{'rowSpan-2': status.leiBaixando ? false:true}">
            <ul>
                <li *ngIf="status.leisBaixar.length>0">
                    <span>Processando: {{status.leisBaixar.length}}</span>
                    <ul>
                        <li *ngFor="let lei of status.leisBaixar">{{lei.descricao}}</li>
                    </ul>
                </li>
                <li *ngIf="status.leisAtualizadas.length>0">
                    <span>Leis atualizadas: {{status.leisAtualizadas.length}}</span>
                    <ul>
                        <li *ngFor="let lei of status.leisAtualizadas">{{lei.descricao}}</li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</div>