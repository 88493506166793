<div class="painel-lateral-placeholder noselect">
  <table class="painel-header-placeholder">
    <tr (click)="painelService.toggleScheduler()" class="clickable">
      <td>
        <h3 *ngIf="(painelService.$dataSelecionada | async) as data" class="data-placeholder">
          {{data | date : 'fullDate'}}
        </h3>
      </td>
      <td class="btn-toggle-scheduler">
        <span>
          <ng-container *ngIf="(painelService.$mostrarScheduler | async); else elseTemplate">
            <div class="icon icon-up"></div>
          </ng-container>
          <ng-template #elseTemplate>
            <div class="icon icon-down"></div>
          </ng-template>
        </span>
      </td>
    </tr>
  </table>
  <hr>
  <div class="scheduler-placeholder" *ngIf="(painelService.$mostrarScheduler | async)">
    <app-scheduler></app-scheduler>
    <hr>
  </div>
  <div class="tempo-total-placeholder">
    <table>
      <tr>
        <td class="tempo-total-header">Tempo total</td>
        <td class="tempo-total">{{painelService.$tempoTotalDia | async}}</td>
      </tr>
    </table>
  </div>
  <div class="tempo-detalhado-placeholder" *ngIf="(painelService.$apontamentosDia | async)as apontamentos">
    <hr>
    <table>
      <tr>
        <td class="tempo-detalhado-header" colspan="2">Tempo detalhado</td>
      </tr>
      <tr class="linha-lista" *ngFor="let apontamento of apontamentos">
        <td>
          <div class="apontamento-descricao">{{apontamento.descricao}}</div>
          <div class="apontamento-anotacao" *ngFor="let anotacao of apontamento.anotacoes">{{anotacao}}</div>
        </td>
        <td class="tempo-total">{{apontamento.tempo || '00:00:00'}}</td>
      </tr>
    </table>
  </div>
</div>