import { Conteudo } from "./../../../../models/pagina/conteudo";
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { PopupsService } from "../../../popups/popups.service";
import { ConteudoService } from "../../../../services/conteudo.service";
import { Subscription } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { ConfigDialogComponent } from "../../../popups/config-dialog/config-dialog.component";
import { FeatureFlagService } from "src/app/services/feature-flag-service.service";
import { Referencia, TipoReferencia } from "src/app/models/Referencia";
import { UsuarioReferenciasGerenciadasService } from "src/app/services/data-services/usuario.referencias.gerenciadas.service";
import { ReferenciaGerenciada } from "src/app/models/ReferenciaGerenciada";

@Component({
  selector: "app-cabecalho",
  templateUrl: "./cabecalho.component.html",
  styleUrls: ["./cabecalho.component.scss"],
})
export class CabecalhoComponent implements OnDestroy {
  @Input() indexLinhaAtual: number;
  @Input() tituloAtual: string;

  @Output() focoChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() exibirRevogadosChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  public exibirApenasItensComentados: boolean;
  public exibirMeusComentarios: boolean;
  public exibirComentariosSvm: boolean;
  public exibirItensRevogados: boolean;

  public dicaEstudos: Array<ReferenciaGerenciada> = new Array<ReferenciaGerenciada>();
  public carregandoConteudo: boolean;
  public prefixoBusca: string;
  public tooltipBusca: string;
  public totalLinhas: number;
  public urlFonteLei: string;

  private subscriptions = new Array<Subscription>();
  constructor(
    private popupsServices: PopupsService,
    private conteudoService: ConteudoService,
    public dialog: MatDialog,
    public featureFlagService: FeatureFlagService,
    public usuarioReferenciasGerenciadasService: UsuarioReferenciasGerenciadasService
  ) {
    this.subscriptions.push(
      this.conteudoService.Conteudo.subscribe((c) => this.conteudo_subscribe(c))
    );
    this.subscriptions.push(
      this.conteudoService.Carregando.subscribe(
        (c) => (this.carregandoConteudo = c)
      )
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((x) => x.unsubscribe());
  }

  public async conteudo_subscribe(c: Conteudo) {
    if (!c) {
      return;
    }
    const configprefere = this.conteudoService.getPreferenciasUsuario();

    this.exibirItensRevogados = configprefere.exibirItensRevogados;
    this.exibirComentariosSvm = configprefere.exibirComentariosSvm;
    this.exibirApenasItensComentados =
      configprefere.exibirApenasItensComentados;
    this.exibirMeusComentarios = configprefere.exibirMeusComentarios;

    this.exibirRevogadosChange.emit(this.exibirItensRevogados);

    this.dicaEstudos = [];

    const ReferenciasGerenciadas =
      await this.usuarioReferenciasGerenciadasService.buscarReferenciasGerenciadasLocalById(
        c.idLei
      );
    const aux = ReferenciasGerenciadas?.referencias.filter(
      (y) => y.tipo == TipoReferencia.DicasEstudo
    ) ?? [];
    this.dicaEstudos.push(...aux);

    switch (c.tipoDocumento) {
      case 0:
        this.prefixoBusca = "Art. nº";
        this.tooltipBusca = "Buscar pelo número do artigo";
        break;
      case 1:
        this.prefixoBusca = "Súm. nº";
        this.tooltipBusca = "Buscar pelo número da súmula";
        break;
      case 2:
        this.prefixoBusca = "Art. nº";
        this.tooltipBusca = "Buscar pelo número do artigo";
        break;
      default:
        this.prefixoBusca = "";
        this.tooltipBusca = "";
        break;
    }

    this.totalLinhas = c.linhas.length;
    this.urlFonteLei = c.urlFonteLei;
  }

  public get tituloAtualWrapped(): string {
    if (!this.tituloAtual) {
      return "";
    }

    return this.tituloAtual.length > 75
      ? this.tituloAtual.substring(0, 75) + "..."
      : this.tituloAtual;
  }

  public alterarExibicaoPreferenciasUsuario(
    itensRevogados: boolean,
    leiSeca: boolean,
    meusComentarios: boolean,
    comentariosSvm: boolean
  ) {
    this.exibirItensRevogados = itensRevogados;
    this.exibirApenasItensComentados = leiSeca;
    this.exibirMeusComentarios = meusComentarios;
    this.exibirComentariosSvm = comentariosSvm;

    this.conteudoService.alterarPreferenciasUsuario(
      this.exibirItensRevogados ?? true,
      this.exibirComentariosSvm ?? true,
      this.exibirApenasItensComentados ?? false,
      this.exibirMeusComentarios ?? true
    );
    this.exibirRevogadosChange.emit(this.exibirItensRevogados);
  }

  public abrirIndiceSistematico() {
    this.popupsServices.abrirPopupIndiceSistematico();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ConfigDialogComponent, {
      width: "280px",
      data: {
        exibirItensRevogados: this.exibirItensRevogados,
        exibirMeusComentarios: this.exibirMeusComentarios,
        exibirApenasItensComentados: this.exibirApenasItensComentados,
        exibirComentariosSvm: this.exibirComentariosSvm,
      },
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      this.alterarExibicaoPreferenciasUsuario(
        result.exibirItensRevogados,
        result.exibirApenasItensComentados,
        result.exibirMeusComentarios,
        result.exibirComentariosSvm
      );
    });
  }
}
