import { Component, ViewChild, OnInit, OnDestroy } from "@angular/core";
import { AppConfig } from "../../../app.config";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { ConfiguracoesUsuario } from "../../../models/usuario/ConfiguracoesUsuario";
import { UsuarioPreferenciasService } from "../../../services/data-services/usuario.preferencias.service";
import { Subscription } from "rxjs";
import {
  ErroPopupDataModel,
  ErroPopUpComponent,
} from "../mensagem/erro-popup.component";
import { StatusService } from "../../../services/status.service";
import { PopupsService } from "../popups.service";
import { UsuarioEstatisticasService } from "../../../services/data-services/usuario.estatisticas.service";
import { UsuarioGuiasService } from "../../../services/data-services/usuario.guias.service";
import { MatDialog } from "@angular/material/dialog";
import { MatButtonToggleGroup } from "@angular/material/button-toggle";
import { ErrorHandlerService } from "../../../services/errorHandler.service";
import { ErrorLogParameters } from "../../../models/error/errorLogParameters";

@Component({
  selector: "app-feedback",
  templateUrl: "./feedback.component.html",
  styleUrls: ["../popups-styles.scss", "./feedback.component.scss"],
})
export class FeedbackComponent implements OnInit, OnDestroy {
  public textoFeedback: string;
  public configuracoes: ConfiguracoesUsuario;
  @ViewChild(MatButtonToggleGroup) btnGroup;

  private _subscribePreferencias: Subscription;
  constructor(
    private httpClient: HttpClient,
    private usuarioPreferenciasService: UsuarioPreferenciasService,
    private dialog: MatDialog,
    private statusService: StatusService,
    private popupSerice: PopupsService,
    private guiasService: UsuarioGuiasService,
    private errorHandlerService: ErrorHandlerService
  ) {}

  ngOnInit(): void {
    this._subscribePreferencias =
      this.usuarioPreferenciasService.$Configuracoes.subscribe((c) =>
        this.subscribePreferencias(c)
      );
  }

  ngOnDestroy(): void {
    this._subscribePreferencias.unsubscribe();
  }

  private subscribePreferencias(c: ConfiguracoesUsuario) {
    if (!c) {
      return;
    }
    this.configuracoes = c;
  }

  public criarTicket(): void {
    if (
      !this.btnGroup.value ||
      !this.textoFeedback ||
      (this.textoFeedback && this.textoFeedback.match(/^ *$/) !== null)
    ) {
      const data = new ErroPopupDataModel();
      data.titulo = "Feedback inválido";
      data.mensagem =
        "Por favor selecione uma das opções e escreva um comentário para enviar um feedback";
      this.dialog.open(ErroPopUpComponent, {
        width: "450px",
        data: data,
      });

      return;
    }

    let priority = FreshDeskTicketPriority.Low;
    switch (this.btnGroup.value) {
      case "feedback_Bom":
        priority = FreshDeskTicketPriority.Low;
        break;
      case "feedback_Regular":
        priority = FreshDeskTicketPriority.Medium;
        break;
      case "feedback_Ruim":
        priority = FreshDeskTicketPriority.High;
        break;
    }

    const idLei = this.guiasService.guiaAtiva.idLei
      ? this.guiasService.guiaAtiva.idLei
      : "Nova Guia";
    const dadosAdicionais = "<br/><br/><bold>Lei aberta: " + idLei + "</bold>";

    const url = AppConfig.freshdeskEndpoint + "tickets";
    const body = new FreshDeskTicketModel({
      email: this.configuracoes.email,
      subject: this.btnGroup.value,
      description:
        this.textoFeedback.replace("\r\n", "<br/>").replace("\n", "<br/>") +
        dadosAdicionais,
      status: FreshDeskTicketStatus.Open,
      priority: priority,
    });

    let headers = new HttpHeaders();
    headers = headers.append(
      "authorization",
      "Basic " + AppConfig.freshdeskKey64Based
    );
    this.httpClient
      .post(url, body, { headers: headers })
      .toPromise()
      .then((result) => {
        this.statusService.setMessage("Feedback enviado com sucesso!");
        this.popupSerice.fecharPopups();
      })
      .catch((error) => {
        this.statusService.setMessage(
          "Erro ao enviar feedback. Tente novamente mais tarde"
        );
        this.popupSerice.fecharPopups();

        let param = new Array<ErrorLogParameters>();
        param.push({ name: "TipoFeedback", value: this.btnGroup.value });
        param.push({ name: "mensagemFeedback", value: this.textoFeedback });
        this.errorHandlerService.handleError(error);
      });
  }

  public btnCancelar_click() {
    this.popupSerice.fecharPopups();
  }
}

export class FreshDeskTicketModel {
  constructor(init?: Partial<FreshDeskTicketModel>) {
    Object.assign(this, init);
  }

  public email: string;
  public subject: string;
  public description: string;
  public status: FreshDeskTicketStatus;
  public priority: FreshDeskTicketPriority;
}

export enum FreshDeskTicketStatus {
  Open = 2,
  Pending = 3,
  Resolved = 4,
  Closed = 5,
}

export enum FreshDeskTicketPriority {
  Low = 1,
  Medium = 2,
  High = 3,
  Urgent = 4,
}
