<div class="wrapper" *ngIf="visible">
  
  <div class="placeholder">
    <div id="app-logo">
      <img src="assets/ui/logo2.png" width="180px" />
    </div>

    <div id="busy-msg" [@msgCarregamento]="mensagemAtual" *ngIf="mensagemAtual" [innerText]="mensagemAtual"></div>

    <div id="update-progressbar" *ngIf="progress">
      <div id="update-progressbar-step" [style.width]="progress + '%'"></div>
    </div>

    <div id="update-progressbar" *ngIf="undetermined">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>

    <div id="update-msg" [innerText]="message"></div>
  </div>
</div>