import { Injectable } from "@angular/core"
import { CanActivate, Router } from "@angular/router"
import { LeiService } from "../../../services/lei.service"

@Injectable({
  providedIn: 'root'
})
export class DownloadNotRequiredGuard implements CanActivate {
  constructor(
    private router: Router,
    private leiService: LeiService
  ) { }

  async canActivate(): Promise<boolean> {
    const allow = !(await this.leiService.verificarAtualizacoesModoOffline())

    if (!allow)
      this.router.navigate(['/downloader'])

    return allow
  }
}

@Injectable({
  providedIn: 'root'
})
export class DownloadRequiredGuard implements CanActivate {
  constructor(
    private router: Router,
    private leiService: LeiService
  ) { }

  async  canActivate(): Promise<boolean> {
    const allow = (await this.leiService.verificarAtualizacoesModoOffline())

    if (!allow)
      this.router.navigate(['/'])

    return allow
  }
}