<app-comentario
*ngIf="!comentario.mnemonico"
  [comentario]="comentario"
  [corComentario]="corComentario"
  [novoComentario]="novo"
  [currentTextoComentario]="currentTexto"
  [currentTagsComentario]="currentTags"
 [email]="email"
 [editandoComentario]="editando"
 [comentarioGerenciadoReadOnly]="comentarioGerenciadoReadOnly"
 [comentarioGerenciadoPlus]="comentarioGerenciadoPlus"
 (comentarioCancel)="cancel()"
 (comentarioCommited)="commit($event)"
 (changeEditando)="changeEditandoComentario()"
></app-comentario>

<app-titulo
*ngIf="comentario.mnemonico"
  [titulo]="comentario"
  [corTitulo]="corTitulo"
  [novoTitulo]="novo"
  [currentTextoTitulo]="currentTexto"
  [editandoTitulo]="editando"
  (tituloCancel)="cancel()"
  (tituloCommited)="commit($event)"
  (changeEditando)="changeEditandoComentario()"
></app-titulo>
