<div
  class="marcacoes"
  [style.marginTop.px]="marginTop"
  [style.marginBottom.px]="marginBottom"
>
  <span
    id="hide-resize-helper"
    style="
      position: absolute !important;
      font-size: 10pt;
      height: 0 !important;
      overflow: hidden !important;
    "
  ></span>

  <!-- REFERENCIAGERENCIADOS-->
  <span
    *ngIf="
      posicaoAtual === 'Abaixo' && this.linha.referenciasGerenciado?.length > 0
    "
  >
    <div>
      <app-editor-referencia
        [referenciaReadOnly]="referenciagerenciadasReadOnly"
        [referencias]="ReferenciagerenciadasLinhaAtual"
        class="referencias"
      ></app-editor-referencia>
    </div>
  </span>

  <!-- REFERENCIA-->
  <span
    *ngIf="
      posicaoAtual === 'Abaixo' &&
      (this.linha.referencias?.length > 0 || isCriarReferencia)
    "
  >
    <div *ngIf="IsAdm">
      <app-editor-referencia
        [referencia]="criarReferencia"
        [referencias]="ReferenciaLinhaAtual"
        class="referencias"
        (on_salvarReferencia)="salvarReferencia($event)"
        (on_fechaReferencia)="fechaReferencia()"
        (on_deletaReferencia)="deletaReferencia($event)"
      ></app-editor-referencia>
    </div>
  </span>

  <!-- COMENTARIOSGERENCIADOS-->
  <span
    *ngIf="
      ((posicaoAtual === 'Acima' &&
        (!opcoesPonteiros.posicionamentoComentario ||
          opcoesPonteiros.posicionamentoComentario === 'Acima')) ||
        (posicaoAtual === 'Abaixo' &&
          opcoesPonteiros.posicionamentoComentario === 'Abaixo')) &&
      exibirComentariosSvm
    "
  >
    <app-editor-comentario
      id="coment-sup-input-{{ comentario.id }}"
      *ngFor="let comentario of comentariosGerenciados"
      [comentarioReadOnly]="comentario"
    ></app-editor-comentario>
  </span>
  <!-- MNEMONICOGERENCIADOS -->
  <span
    *ngIf="
      ((posicaoAtual === 'Acima' &&
        (!opcoesPonteiros.posicionamentoMnemonico ||
          opcoesPonteiros.posicionamentoMnemonico === 'Acima')) ||
        (posicaoAtual === 'Abaixo' &&
          opcoesPonteiros.posicionamentoMnemonico === 'Abaixo')) &&
      exibirComentariosSvm
    "
  >
    <app-editor-comentario
      id="mne-sup-input-{{ mnemonico.id }}"
      *ngFor="let mnemonico of mnemonicosGerenciados"
      [comentarioReadOnly]="mnemonico"
    ></app-editor-comentario>
  </span>

  <!-- COMENTARIOS -->
  <span
    *ngIf="
      ((posicaoAtual === 'Acima' &&
        (!opcoesPonteiros.posicionamentoComentario ||
          opcoesPonteiros.posicionamentoComentario === 'Acima')) ||
        (posicaoAtual === 'Abaixo' &&
          opcoesPonteiros.posicionamentoComentario === 'Abaixo')) &&
      exibirMeusComentarios
    "
  >
    <app-editor-comentario
      id="coment-sup-input-{{ comentario.id }}"
      *ngFor="let comentario of comentarios"
      [comentarioEditar]="comentario"
      (commit)="salvarComentario($event)"
    ></app-editor-comentario>
  </span>
  <!-- MNEMONICO -->
  <span
    *ngIf="
      ((posicaoAtual === 'Acima' &&
        (!opcoesPonteiros.posicionamentoMnemonico ||
          opcoesPonteiros.posicionamentoMnemonico === 'Acima')) ||
        (posicaoAtual === 'Abaixo' &&
          opcoesPonteiros.posicionamentoMnemonico === 'Abaixo')) &&
      exibirMeusComentarios
    "
  >
    <app-editor-comentario
      id="mne-sup-input-{{ mnemonico.id }}"
      *ngFor="let mnemonico of mnemonicos"
      [comentarioEditar]="mnemonico"
      (commit)="salvarComentario($event)"
    ></app-editor-comentario>
  </span>

  <!-- PROVA-->

  <!-- // azure card 358 Desativar botão caiu em prova-->
  <span
    *ngIf="
      ((posicaoAtual === 'Acima' &&
        (!opcoesPonteiros.posicionamentoProva ||
          opcoesPonteiros.posicionamentoProva === 'Acima')) ||
        (posicaoAtual === 'Abaixo' &&
          opcoesPonteiros.posicionamentoProva === 'Abaixo')) &&
      exibirMeusComentarios
    "
  >
    <div
      *ngFor="let prova of marcacoesLinhaAtual"
      id="prov-sup-div-{{ prova.id }}"
      class="custom-chip border-light prova"
      backgroundContrast
      [backgroundColor]="opcoesPonteiros.corTagProva"
      [style.border-color]="opcoesPonteiros.corTagProva"
      appAutoWidht
      [textoAutoWidhtDirective]="funcoesProva.getLabelProva(prova.dados)"
      (mouseenter)="realcarProva(prova.id)"
      (mouseleave)="blurRealce()"
    >
      <!-- [class.prova-editando]="provaEditando && provaEditando.id == prova.id" -->
      <ng-container>
        <!--  *ngIf="
          !provaEditando || provaEditando.id != prova.id;
          else elseEditando
        " -->
        <input
          id="prov-sup-input-{{ prova.id }}"
          [ngModel]="funcoesProva.getLabelProva(prova.dados)"
        />
        <!-- (focusin)="focusEnterProva($event.target, prova)" -->
        <mat-icon
          class="statusbar-icon"
          svgIcon="close"
          title="Excluir"
          [style.background]="opcoesPonteiros.corTagProva"
          (click)="confirmarDelecaoProva(prova)"
        ></mat-icon>
      </ng-container>
      <!--    <ng-template #elseEditando>
       <ng-container *ngIf="carregandoOpcoesProva; else elseTemplate">
          <mat-progress-bar mode="indeterminate"> </mat-progress-bar>
        </ng-container>
        <ng-template #elseTemplate>
          <div
            id="prov-sup-input-{{ prova.id }}"
            #idBgProva
            class="prova-bgd"
            (click)="focusLeaveProva($event.target)"
          ></div>
          <span style="position: relative; z-index: 100; width: 97%">
            <ng-container *ngIf="!provaNova; else elseProvaNova">
              <mat-form-field class="provas-existentes">
                <input
                  type="text"
                  #controlProvaExistente
                  matInput
                  [(ngModel)]="provaTextoBuscando"
                  (keyup)="filtroMarcacaoProva(provaTextoBuscando)"
                  placeholder="Busque por uma prova já existente"
                  [matAutocomplete]="auto"
                  autocomplete="false"
                />
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-optgroup
                    *ngFor="let provaG of provasExistentes"
                    [label]="provaG.tipo"
                  >
                    <mat-option
                      *ngFor="let provaExis of provaG.marcacoes"
                      [value]="funcoesProva.getLabelProva(provaExis)"
                      (onSelectionChange)="
                        selecionarProvaExistente(provaExis, idBgProva)
                      "
                    >
                      <span>{{ funcoesProva.getLabelProva(provaExis) }}</span>
                    </mat-option>
                  </mat-optgroup>
                </mat-autocomplete>
              </mat-form-field>
              <button
                mat-raised-button
                class="add-prova-button"
                [style.color]="opcoesPonteiros.corTagProva"
                (click)="gerarNovaProva()"
              >
                <mat-icon>add</mat-icon>
              </button>
            </ng-container>
            <ng-template #elseProvaNova>
              <app-tag-picker
                #instituicaoElement
                class="prova-picker"
                [style.color]="opcoesPonteiros.corTagProva"
                [nullText]="'Instituição'"
                [options]="opcoesProva.instituicoes"
                [selected]="provaEditando.instituicao"
                (onSelect)="provaEditando.dados.instituicao = $event"
              >
              </app-tag-picker>
              <app-tag-picker
                class="prova-picker"
                [nullText]="'Banca'"
                [style.color]="opcoesPonteiros.corTagProva"
                [options]="opcoesProva.bancas"
                [selected]="provaEditando.banca"
                (onSelect)="provaEditando.dados.banca = $event"
              ></app-tag-picker>
              <app-tag-picker
                class="prova-picker"
                [nullText]="'Ano'"
                [style.color]="opcoesPonteiros.corTagProva"
                [options]="opcoesProva.anos"
                [selected]="provaEditando.ano"
                (onSelect)="provaEditando.dados.ano = $event"
              ></app-tag-picker>
              <app-tag-picker
                class="prova-picker"
                [nullText]="'Tipo'"
                [style.color]="opcoesPonteiros.corTagProva"
                [options]="opcoesProva.tipos"
                [selected]="provaEditando.tipo"
                (onSelect)="provaEditando.dados.tipo = $event"
              ></app-tag-picker>
              <app-tag-picker
                class="prova-picker"
                [nullText]="'Cargo'"
                [style.color]="opcoesPonteiros.corTagProva"
                [options]="opcoesProva.cargos"
                [selected]="provaEditando.cargo"
                (onSelect)="provaEditando.dados.cargo = $event"
                (keyUp.enter)="picker_keyup_enter(prova.id)"
              >
              </app-tag-picker>
            </ng-template>
          </span>
        </ng-template>
      </ng-template>-->
    </div>
  </span>
</div>
