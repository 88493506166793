import { Injectable, ApplicationRef } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval, concat, BehaviorSubject, Observable } from 'rxjs';
import { first } from 'rxjs/operators'
import { DadosService } from './dados.service';
import { StatusService } from './status.service';

@Injectable()
export class CheckForUpdateService {
    UpdateAvailable: Observable<boolean>
    private updateAvailable = new BehaviorSubject<boolean>(false)

    constructor(
        private appRef: ApplicationRef,
        private updates: SwUpdate,
        private statusService: StatusService,
        private dadosService: DadosService
    ) {
        this.UpdateAvailable = this.updateAvailable.asObservable()
    }

    initialize() {
        const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true))
        const everyThirdMinutes$ = interval(0.5 * 60 * 60 * 1000)
        const everyThirdMinutesOnceAppIsStable$ = concat(appIsStable$, everyThirdMinutes$)

        everyThirdMinutesOnceAppIsStable$.subscribe(() => {
            if (this.updates.isEnabled)
                this.updates.checkForUpdate()
        })

        this.updates.available.subscribe(() => {
            this.updates.activateUpdate().then(() => this.updateAvailable.next(true))
        });
    }

    async updateApplication() {
        const taskname = 'app-update';
        this.statusService.mostrarMensagemProgresso('sincronizando dados...', taskname);
        await this.dadosService.baixar();
        this.statusService.mostrarMensagemProgresso('Tudo pronto!', taskname);
        document.location.reload()
    }
}