import { Component, Input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Referencia, TipoReferencia } from "src/app/models/Referencia";
import { ReferenciaDialogComponent } from "../referencia-dialog/referencia-dialog.component";

@Component({
  selector: "app-dica-estudos",
  templateUrl: "./dica-estudos.component.html",
  styleUrls: ["./dica-estudos.component.scss"],
})
export class DicaEstudosComponent {
  @Input() dicasEstudo = new Array<Referencia>();

  constructor(public dialog: MatDialog) {}

  openDialog(referencia: Referencia): void {
    const dialogRef = this.dialog.open(ReferenciaDialogComponent, {
      data: {
        referencia: referencia,
        tipo: Referencia.identificarTipoReferencia(referencia.tipo),
      },
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result: any) => {});
  }
  tipoReferencia(item: TipoReferencia) {
    return Referencia.identificarTipoReferencia(item);
  }
}
