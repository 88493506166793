export enum TipoIntervalo {
    MarcacaoProva = 0,
    Comentario = 1,
    // Grifo = 2,
    ResultadoBusca = 3,
    Mnemonico = 4,
    Caneta1 = 5,
    Caneta2 = 6,
    Caneta3 = 7,
    Caneta4 = 8,
    Caneta5 = 9
  }