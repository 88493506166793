import { Injectable } from "@angular/core";
import { Intervalo } from "../../../models/Intervalo";
import { Observable, BehaviorSubject } from "rxjs";
import { ConteudoService } from "../../../services/conteudo.service";
import { UsuarioGuiasService } from "../../../services/data-services/usuario.guias.service";
import { KeyValue } from "@angular/common";
import { EntitiesHelper } from "../../../helpers/entities.helper";
import { TipoIntervalo } from "../../../enums/tipo.intervalo";
import { Guia } from "../../../models/Guia";

@Injectable({ providedIn: 'root' })
export class BuscaRapidaService {
    private indice: KeyValue<string, number>[];
    private idGuiaAtiva: string;
    private numeroBuscar: number;

    MatchBuscaRapida: Observable<Intervalo>
    private matchBuscaRapida = new BehaviorSubject<Intervalo>(null)

    constructor(
        private conteudoService: ConteudoService,
        private guiaService: UsuarioGuiasService
    ) {
        this.MatchBuscaRapida = this.matchBuscaRapida.asObservable()

        this.conteudoService.IndiceLei.subscribe(i => this.indice_changed(i))
        this.guiaService.$GuiaAtiva.subscribe(g => this.guiaAtiva_changed(g))
    }

    buscar(numero: number = null): void {
        this.matchBuscaRapida.next(null)

        if (numero)
            this.numeroBuscar = numero

        if (!this.indice || !this.numeroBuscar)
            return

        const linha = this.indice.find(i => i.value == this.numeroBuscar)
        if (!linha)
            return

        const conteudo = EntitiesHelper.Copy(this.conteudoService.getConteudo())

        const iLinha = conteudo.linhas.findIndex(l => l.id === linha.key)
        if (iLinha === -1 || conteudo.linhas[iLinha].versoes.length === 0)
            return

        const iVersao = conteudo.linhas[iLinha].versoes.length - 1
        const trimPrefixo = conteudo.linhas[iLinha].versoes[iVersao].prefixo.trim()
        const match = new Intervalo(conteudo.linhas[iLinha].id, TipoIntervalo.ResultadoBusca, true, 0, trimPrefixo.length - 1, new Date(), null)
        this.matchBuscaRapida.next(match)
    }

    private indice_changed(indice: KeyValue<string, number>[]): void {
        this.indice = indice
        this.buscar()
    }

    private guiaAtiva_changed(g: Guia): void {
        if (!g || this.idGuiaAtiva === g.id)
            return

        this.idGuiaAtiva = g.id
        this.matchBuscaRapida.next(null)
        this.numeroBuscar = null
    }
}