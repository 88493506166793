import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { DialogoModel } from './dialogo.component';

@Injectable()
export class DialogoService {
  public $model: Observable<DialogoModel>;
  private _model = new BehaviorSubject<DialogoModel>(null);

  constructor() {
    this.$model = this._model.asObservable();
  }

  public mostrarDialogo(model: DialogoModel): void {
    setTimeout(() => this._model.next(model));
  }

  public fecharDialogo(): void {
    setTimeout(() => this._model.next(null));
  }
}
