import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DadosPessoaisModel, PagamentoModel } from './form-pagamento/form-pagamento.component';
import { ContatoIndicacao, StatusIndicacaoModel } from './form-indicacoes/form-indicacoes.models';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../../app.config';
import { EntitiesHelper } from '../../../helpers/entities.helper';
import { LoggingService } from '../../../services/logging.service';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: [
    '../popups-styles.scss',
    './perfil.component.scss'
  ]
})
export class PerfilComponent implements OnInit, OnDestroy {
  constructor(
    public snackBar: MatSnackBar,
    private httpClient: HttpClient,
    private loggingService: LoggingService,
  ) {
  }

  public email: string;
  public model: DadosPessoaisServidorModel;
  public modelOriginal: DadosPessoaisServidorModel;
  public carregando: boolean;
  public salvando: boolean;


  private _validarMudancas = false;
  public set validarMudancas(value) {
    this._validarMudancas = value;
    this.confirmarAlteracoes.next(value);
  };
  public get validarMudancas() {
    return this._validarMudancas;
  }
  @Output() confirmarAlteracoes = new EventEmitter<boolean>(false);

  public ngOnInit(): void {
    this.carregarModel();
  }
  public ngOnDestroy(): void {
    this.model = null;
    this.modelOriginal = null;
  }

  public carregarModel(): any {
    const carregarWebService = (): Promise<DadosPessoaisServidorModel> => {
      return new Promise((onSuccess) => {
        this.httpClient.get(AppConfig.apiEndpoint + '/DadosCadastroUsuario').toPromise().then((response: any) => {
          const retorno = new DadosPessoaisServidorModel();
          retorno.trial = Number(response.valorCobranca) < 0;

          retorno.resumoCartao.bandeiraCartao = response.bandeira;
          retorno.resumoCartao.finalCartao = response.finalCartao;

          retorno.dadosPessoais.cpf = response.cpf;
          retorno.dadosPessoais.telefone = response.telefone;
          retorno.dadosPessoais.planoContratadoId = response.planoContratadoId;
          retorno.dadosPessoais.planoCancelado = response.planoCancelado;
          retorno.dadosPessoais.dataProximaCobranca = new Date(response.dataProximaCobranca);
          retorno.dadosPessoais.ValorDescontoIndicacao = response.valorDescontoIndicacao;
          retorno.dadosPessoais.ValorCobranca = response.valorCobranca;

          if (response.indicacoes) {
            retorno.dadosPessoais.indicacoes = new Array<ContatoIndicacao>();
            response.indicacoes.forEach(indicacao => {
              const i = new ContatoIndicacao();

              i.nome = indicacao.nome;
              i.email = indicacao.email;
              i.dataEnvio = (indicacao.dataEnvio) ? new Date(indicacao.dataEnvio) : null;
              i.dataCadastro = (indicacao.dataCadastro) ? new Date(indicacao.dataCadastro) : null;
              i.status = StatusIndicacaoModel.status.find(s => s.codigo === indicacao.status);

              retorno.dadosPessoais.indicacoes.push(i);
            });
          }

          onSuccess(retorno);
        }).catch((err) => {
          throw err;
        });
      });
    };

    this.carregando = true;
    this.model = null;
    this.modelOriginal = null;

    carregarWebService().then((result) => {
      this.model = result;
      this.modelOriginal = EntitiesHelper.Copy(result);
      this.carregando = false;
    }).catch((err) => {
      this.snackBar.open(err, 'OK!');
      this.carregando = false;
      throw err;
    });
  }

  public get ocupado(): boolean {
    return this.salvando || this.carregando ? true : false;
  }

  public childForm_saving(msgCarregamento = false): void {
    if (msgCarregamento) {
      this.carregando = true;
    } else {
      this.salvando = true;
    }
  }

  public childForm_saved(msg, msgCarregamento = false): void {
    this.loggingService.LogEvent("Editar Pefil", null, null);

    this.validarMudancas = false;
    this.snackBar.open(msg, 'OK!', {
      duration: 10000
    });

    if (msgCarregamento) {
      this.carregando = false;
    } else {
      this.salvando = false;
    }

    this.carregarModel();
  }

}

export class DadosPessoaisServidorModel {
  constructor() {
    this.resumoCartao = new ResumoCartaoCredito();
    this.dadosPessoais = new DadosPessoais();
  }

  public trial: boolean;
  public resumoCartao: ResumoCartaoCredito;
  public dadosPessoais: DadosPessoais;
}

export class ResumoCartaoCredito {
  public bandeiraCartao: string;
  public finalCartao: string;
}

export class SolicitacaoAlteracaoDados {
  public dadosPessoais: DadosPessoais;
  public dadosCartaoCredito: DadosCartaoCredito;
  public planoContratadoId: number;
}

export class DadosPessoais {

  public cpf: string;
  public telefone: string;
  public dataProximaCobranca: Date;
  public ValorDescontoIndicacao: number;
  public ValorCobranca: number;
  public indicacoes: ContatoIndicacao[];
  public planoContratadoId: number;
  public planoCancelado: boolean;

  public static fromDadosPessoaisModel(model: DadosPessoaisModel): DadosPessoais {
    const retorno = new DadosPessoais();

    retorno.cpf = model.cpf;
    retorno.telefone = model.telefone;

    return retorno;
  }
}

export class DadosCartaoCredito {

  public cpfTitular: string;
  public nomeImpressoCartao: string;
  public numeroCartao: string;
  public codigoSeguranca: string;
  public dataVencimento: string;
  public cep: string;
  public logradouroEndereco: string;
  public numeroEndereco: string;
  public complemento: string;
  public bairro: string;
  public cidadeEndereco: string;
  public uf: string;
  public siglaPais: string;
  public meioPagamento: number;

  public static fromPagamentoModel(model: PagamentoModel, cpf: string): DadosCartaoCredito {
    const retorno = new DadosCartaoCredito();

    retorno.cpfTitular = cpf;
    retorno.nomeImpressoCartao = model.nomeCartao;
    retorno.numeroCartao = model.numero;
    retorno.codigoSeguranca = model.codigo;
    retorno.dataVencimento = model.vencimento;
    retorno.cep = model.cep;
    retorno.logradouroEndereco = model.logradouroEndereco;
    retorno.numeroEndereco = model.numeroEndereco;
    retorno.complemento = model.complemento;
    retorno.bairro = model.bairro;
    retorno.cidadeEndereco = model.cidadeEndereco;
    retorno.uf = model.uf;
    retorno.siglaPais = 'BR';
    retorno.meioPagamento = model.bandeira.id;

    return retorno;
  }
}
