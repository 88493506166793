<h1 mat-dialog-title>Atenção!</h1>
<div mat-dialog-content>
  <p>{{ data }}</p>
</div>
<div mat-dialog-actions>
  <button mat-raised-button color="warn" (click)="btnNao_click()">Não</button>
  <button mat-raised-button color="primary" (click)="btnSim_click()">
    Sim
  </button>
</div>
