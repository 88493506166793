import { Component, OnInit, OnDestroy } from '@angular/core';
import { PainelLateralService } from '../painel-lateral/painel-lateral.service';
import { MatSelectChange } from '@angular/material/select';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-scheduler',
  templateUrl: './scheduler.component.html',
  styleUrls: ['./scheduler.component.scss']
})
export class SchedulerComponent implements OnInit, OnDestroy {
  private s_dataSelecionada: Subscription;
  public mes;
  public ano;

  public data: Date;
  public semanas: Semana[];
  public anos: string[];

  constructor(
    private painelLateralService: PainelLateralService
  ) { }

  ngOnInit() {
    this.s_dataSelecionada = this.painelLateralService.$dataSelecionada.subscribe(data => this.carregarData(data));
  }

  ngOnDestroy(): void {
    this.s_dataSelecionada.unsubscribe();
  }

  public carregarData(data: Date): void {
    const semanas = new Array<Semana>();

    const mes = data.getMonth();
    const ano = data.getFullYear();

    this.data = new Date(ano, mes, data.getDate());

    const diaInicio = new Date(ano, mes, 1).getDate();
    const diaTermino = new Date(ano, mes + 1, 0).getDate();

    let i = diaInicio;
    let semana = new Semana();
    while (i <= diaTermino) {
      const d = new Date(ano, mes, i);
      semana.dias.push(d);

      if (d.getDay() === 6) {
        semanas.push(semana);
        semana = new Semana();
      }

      i++;
    }
    semanas.push(semana);

    semanas.filter(s => s.dias.length < 7).forEach(s => {
      const primeiro = s.dias[0];
      const ultimo = s.dias[s.dias.length - 1];

      if (primeiro && primeiro.getDay() > 0) {
        const t = s.dias;
        s.dias = new Array<Date>();
        for (let j = 0; j < primeiro.getDay(); j++) {
          s.dias.push(null);
        }

        t.forEach(k => {
          s.dias.push(k);
        });
      };

      if (ultimo && ultimo.getDay() < 6) {
        while (s.dias.length < 7) {
          s.dias.push(null);
        }
      }
    });

    this.semanas = semanas;
    this.mes = `${mes}`;
    this.ano = `${ano}`;

    this.anos = new Array<string>();
    for (let a = 2000; a <= new Date().getFullYear(); a++) {
      this.anos.push(`${a}`);
    }
  }

  public selMeses_change(e: MatSelectChange): void {
    this.painelLateralService.alterarData(this.ajustarData(this.data.getDate(), e.value, this.ano));
  }

  public selAnos_change(e: MatSelectChange): void {
    this.painelLateralService.alterarData(this.ajustarData(this.data.getDate(), this.mes, e.value));
  }

  private ajustarData(dia, mes, ano): Date {
    const diaTermino = new Date(ano, Number(mes) + 1, 0).getDate();
    dia = (dia < diaTermino) ? dia : diaTermino;

    const date = new Date(ano, mes, dia);
    return date;
  }

  public dia_click(dia: Date): void {
    this.painelLateralService.alterarData(dia);
  }
}

export class Semana {
  public dias: Date[];

  constructor() {
    this.dias = new Array<Date>();
  }
}