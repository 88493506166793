import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { QuizQuestions } from "src/app/services/quiz.service";

@Component({
  selector: "app-quiz",
  templateUrl: "./quiz.component.html",
  styleUrls: ["./quiz.component.scss"],
})
export class QuizComponent implements OnInit {
  quizResponse: string;
  @Input() quizList: QuizQuestions;
  @Output() on_closeQuiz = new EventEmitter();
  Response: string = "";
  isDisabled: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  closeQuiz() {
    this.on_closeQuiz.emit();
  }

  public quizCorrect() {
    if (this.Response === "correto") {
      const element = document.getElementById("blink-close");

      element.classList.remove("blink-bg"); // reset animation
      void element.offsetWidth; // trigger reflow
      element.classList.add("blink-bg"); // start animation
    } else {
      if (this.quizResponse == this.quizList.answer) {
        this.Response = "correto";
        this.isDisabled = true;
      } else {
        this.Response = "errado";
      }
    }
  }
  public quizReset() {
    if (this.Response === "errado") {
      this.Response = null;
    }
  }
}
