<mat-expansion-panel>
  <mat-expansion-panel-header> Cancelar assinatura </mat-expansion-panel-header>

  <ng-container *ngIf="model; else elseTemplate">
    <ng-container
      *ngIf="model.assinaturaCancelada; else templateFormCancelamento"
    >
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <h4>O seu plano Super Vademecum foi cancelado</h4>
          <p>
            Você poderá continuar utilizando o aplicativo até a data da próxima
            renovação
            <b>({{ model.dataProximoPagamento | date : "dd/MM/yyyy" }})</b>.
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <button
            mat-raised-button
            color="primary"
            (click)="reativarAssinatura()"
          >
            Desejo reativar!
          </button>
        </div>
      </div>
    </ng-container>
    <ng-template #templateFormCancelamento>
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <h4>Tem certeza?</h4>
          <ul>
            <li>
              O cancelamento da assinatura cancela a renovação mensal da sua
              conta Super Vademecum.
            </li>
            <li>
              Você poderá continuar utilizando o aplicativo até a data da
              próxima renovação
              <b>({{ model.dataProximoPagamento | date : "dd/MM/yyyy" }})</b>.
            </li>
            <li>
              Os seus dados (marcações, comentários, estatísticas e
              preferências) serão mantidos por trinta dias, caso você queira
              voltar a utilizar o aplicativo.
            </li>
          </ul>
        </div>
      </div>

      <div *ngIf="!model.flagCancelarAssinatura">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <h4>Antes de cancelar...</h4>
            <p>
              Sentiu falta de algum recurso ou algo não está funcionando como
              deveria?
            </p>
            <p>Conte com o nosso suporte para resolver qualquer problema!</p>
            <p>
              Digite sua mensagem com dúvidas, sugestões e/ou reclamações,
              entraremos em contato o mais breve possível.
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <mat-form-field>
              <textarea
                matInput
                placeholder="Sua mensagem"
                [(ngModel)]="model.mensagemSuporte"
              ></textarea>
            </mat-form-field>

            <button
              mat-raised-button
              [disabled]="!model.mensagemSuporte"
              color="primary"
              (click)="enviarMensagemSuporte()"
            >
              Enviar mensagem
            </button>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
          <mat-checkbox [(ngModel)]="model.flagCancelarAssinatura"
            >Desejo cancelar mesmo assim</mat-checkbox
          >
        </div>

        <div
          class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
          style="text-align: right"
          *ngIf="model.flagCancelarAssinatura"
        >
          <mat-form-field>
            <textarea
              matInput
              placeholder="Por favor, poderia informar o motivo do cancelamento?"
              maxlength="2000"
              minlength="10"
              *ngIf="model.flagCancelarAssinatura"
              [(ngModel)]="model.motivoCancelamento"
            ></textarea>
          </mat-form-field>

          <button
            mat-raised-button
            color="warn"
            [disabled]="!model.motivoCancelamento"
            (click)="solicitarCancelamento()"
          >
            Cancelar assinatura
          </button>
        </div>
      </div>
    </ng-template>
  </ng-container>
  <ng-template #elseTemplate> Carregando... </ng-template>
</mat-expansion-panel>
