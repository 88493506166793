import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { LeitorContentParameters } from './leitor-content.parameters';
import { UiService } from '../../services/ui.service';
import { Subscription } from 'rxjs';
import { Tema, TipoFundo } from '../../models/tema';

@Component({
  selector: '[leitor-content]',
  templateUrl: './leitor-content.component.html',
  styleUrls: ['./leitor-content.component.css']
})
export class LeitorContentComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  @Input('params')
  params: LeitorContentParameters;

  backgroundImage: string;
  backgroundColor: string;
  foreground: string;

  constructor(
    private uiService: UiService
  ) { }

  ngOnInit() {
    this.subscriptions.push(this.uiService.tema.subscribe(t => this.tema_changed(t)));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions = [];
  }

  private tema_changed(t: Tema): void {
    this.backgroundImage = (t && t.tipoFundo === TipoFundo.Imagem) ? t.background : '';
    this.backgroundColor = (t && t.tipoFundo === TipoFundo.Cor) ? t.background : '';
    this.foreground = (t) ? t.foreground : '';
  }
}