<div class="statusbar-wrapper flex-row center-start background-primary">
  <div class="flex-row center-center status-icons" *ngIf="offline || pendingChanges > 0">
    <mat-icon svgIcon="offline" *ngIf="offline"
      matTooltip="Não foi possível se conectar ao servidor, tentaremos automaticamente mais tarde">
    </mat-icon>
    <mat-icon svgIcon="pendente" *ngIf="pendingChanges > 0"
      matTooltip="Existem informações pendentes para sincronizar com o servidor">
    </mat-icon>
  </div>

  <div info-panel></div>
  <div class="flex-1"></div>
  <controle-fonte *ngIf="(conteudo | async) && !(conteudo | async).novaGuia"></controle-fonte>
  <div (click)="btnFeedback_click()" class="flex-row center-center clickable" *ngIf="!offline">
    <mat-icon svgIcon="smile" matTooltip="Dê seu feedback e nos ajude a melhorar">
    </mat-icon>
  </div>
  <div class="spacer" style="width: 16px;"></div>
  <div class="placeholder-indicador-leitura flex-row" indicador-leitura *ngIf="progresso || tempoRestante"
    [progresso]="progresso" [tempoRestante]="tempoRestante"></div>
</div>