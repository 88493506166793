import { Guia } from "./Guia";
import {
  ParametrosCaneta,
  PerfilUsuario,
  PreferenciasUsuario,
  ProvaDatasource,
} from "./UserData";
import { Apontamento } from "../services/timer.service";
import { Marcacao } from "./Marcacao";
import { EstatisticasLeitura } from "./usuario/EstatisticasLeitura";
import { EntitiesHelper } from "../helpers/entities.helper";

export class UserdataDTO {
  public id: string;
  public email: string;
  public nome: string;
  public sobrenome: string;
  public foto: string;

  public palavrasMinuto: number;
  public perfilUsuario: PerfilUsuario;

  public parametrosCaneta: ParametrosCaneta;

  public guias: Guia[];
  public marcacoesProva: Marcacao[];
  public apontamentos: Apontamento[];
  public preferencias: PreferenciasUsuario;
  public provaDatasource: ProvaDatasource;
  public estatisticasLeitura: EstatisticasLeitura[];

  fontSize: number;

  //Campos de controle
  public authToken: string;
  dataUltimaAlteracao: Date;
}

export class BaseObj {
  public id: string;
  public dataHoraModificacao: Date;
  public removido: boolean;

  constructor() {
    this.id = EntitiesHelper.generateGuid();
  }
}
