import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { DadosPessoaisServidorModel } from "../perfil.component";
import {
  IndicacoesModel,
  StatusIndicacaoModel,
} from "./form-indicacoes.models";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppConfig } from "../../../../app.config";
import { ErrorHandlerService } from "../../../../services/errorHandler.service";
import { ErrorLogParameters } from "../../../../models/error/errorLogParameters";

@Component({
  selector: "app-form-indicacoes",
  templateUrl: "./form-indicacoes.component.html",
  styleUrls: ["./form-indicacoes.component.scss"],
})
export class FormIndicacoesComponent implements OnInit {
  private headers = new HttpHeaders({
    "content-type": "application/json",
  });

  @Output() public onsaving = new EventEmitter();
  @Output() public onsaved = new EventEmitter<string>();

  @Input() public dadosServidor: DadosPessoaisServidorModel;
  public model: IndicacoesModel;

  constructor(
    private httpClient: HttpClient,
    private errorHandlerService: ErrorHandlerService
  ) {}

  public ngOnInit() {
    this.carregarModel();
  }

  private carregarModel(): void {
    this.model = null;

    const atualizarModel = (): Promise<IndicacoesModel> => {
      return new Promise((onSuccess, onError) => {
        const model = new IndicacoesModel();

        model.valorPlano = this.dadosServidor.dadosPessoais.ValorCobranca;
        model.valorTabelaDesconto =
          this.dadosServidor.dadosPessoais.ValorDescontoIndicacao;

        model.valorMaximoDesconto = model.valorPlano;

        model.indicacoes = this.dadosServidor.dadosPessoais.indicacoes;
        model.dataProximoVencimento =
          this.dadosServidor.dadosPessoais.dataProximaCobranca;

        onSuccess(model);
      });
    };

    atualizarModel().then((model) => (this.model = model));
  }

  public enviarConvites(email: string = null): void {
    this.onsaving.emit();

    const emailsVerificar = email
      ? [email]
      : this.model.enderecosEmailIndicar.split(";");
    const emails = new Array<String>();
    const indicacoesCadastradas = this.model.indicacoes.filter(
      (i) => i.dataCadastro
    );

    emailsVerificar.forEach((email) => {
      if (indicacoesCadastradas.findIndex((ic) => ic.email === email) === -1) {
        emails.push(email);
      }
    });

    if (emailsVerificar.length > 0) {
      this.httpClient
        .post(
          AppConfig.apiEndpoint + "/indicacoes/enviar-convites",
          { convites: emails },
          { headers: this.headers }
        )
        .toPromise()
        .then(() => {
          this.onsaved.emit("Seus convites foram enviados com sucesso!");
          this.carregarModel();
        })
        .catch((err) => {
          this.onsaved.emit(
            "Ocorreu um erro ao enviar seus convites, tente novamente mais tarde"
          );

          let params = new Array<ErrorLogParameters>();
          params.push({
            name: "emailsIndicados",
            value: JSON.stringify(emails),
          });
          this.errorHandlerService.handleError(err);
        });
    } else {
      this.onsaved.emit(
        "As indicações informadas já estão ativas, não é necessário enviar novamente"
      );
    }
  }
}
