<div
  class="placeholder flex-row center-center"
  *ngIf="plano && plano.trialGratuito"
  (click)="indicador_clicked()"
>
  <mat-progress-spinner
    [value]="percentualIndicador"
    [diameter]="18"
  ></mat-progress-spinner>
  <span>{{ diasRestantesAvaliacao }} dias restantes</span>
</div>

<div
  class="modal flex-column center-center"
  (click)="modal_clicked($event)"
  *ngIf="showModal"
>
  <div class="dialog flex-row" (click)="dialog_clicked($event)">
    <div class="flex-column coluna-texto start-center" *ngIf="formStep != 3">
      <h2 style="margin-bottom: 8px" *ngIf="diasRestantesAvaliacao > 0">
        Sua avaliação do Super Vade Mecum termina em
        {{ diasRestantesAvaliacao }} {{ strDiasRestantes }}
      </h2>
      <h2
        style="margin-bottom: 8px"
        *ngIf="!problemaPagamento && diasRestantesAvaliacao == 0"
      >
        Sua avaliação do Super Vade Mecum terminou
      </h2>
      <h2 style="margin-bottom: 8px" *ngIf="problemaPagamento">
        Sua assinatura do Super Vade Mecum terminou
      </h2>

      <p *ngIf="!problemaPagamento">
        Para continuar usando após o período de testes será necessário fazer uma
        assinatura paga.
      </p>
      <p *ngIf="!problemaPagamento">
        Você pode adquirir a sua assinatura a qualquer momento durante o período
        de testes, porém ao final dele você deverá realizar a assinatura.
      </p>
      <p *ngIf="!problemaPagamento">
        Utilize o formulário ao lado para se inscrever.
      </p>

      <p *ngIf="problemaPagamento">
        Não foi possível realizar a renovação automática da sua assinatura.
      </p>
      <p *ngIf="problemaPagamento">
        Por favor, cadastre um novo cartão de crédito para o pagamento.
      </p>
    </div>
    <form
      class="flex-column coluna-form"
      (submit)="form_submit($event)"
      [formGroup]="applySubscriptionForm"
    >
      <div [ngSwitch]="formStep">
        <div
          class="flex-column"
          *ngSwitchCase="1"
          [formGroup]="applySubscriptionForm.controls['dadosPessoais']"
        >
          <div class="flex-column stepTitle">
            <h5>Etapa 1 de 2</h5>
            <h3>Dados pessoais</h3>
          </div>

          <mat-form-field appearance="outline">
            <mat-label>Seu telefone</mat-label>
            <input
              matInput
              type="tel"
              formControlName="telefone"
              autocomplete="tel"
              [textMask]="masks.celular"
            />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>CEP</mat-label>
            <input
              matInput
              type="text"
              formControlName="cep"
              name="endereco-cep"
              (blur)="buscarCep()"
              [textMask]="masks.cep"
            />
          </mat-form-field>

          <div class="flex-row linha-form">
            <mat-form-field appearance="outline" class="campo-endereco">
              <mat-label>Endereço</mat-label>
              <input
                matInput
                type="text"
                formControlName="logradouro"
                name="logradouro"
              />
            </mat-form-field>
            <mat-form-field appearance="outline" class="campo-numero">
              <mat-label>Nº</mat-label>
              <input matInput type="text" formControlName="numeroLogradouro" />
            </mat-form-field>
          </div>

          <mat-form-field appearance="outline">
            <mat-label>Complemento</mat-label>
            <input
              matInput
              type="text"
              formControlName="complemento"
              name="complemento"
            />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Bairro</mat-label>
            <input
              matInput
              type="text"
              formControlName="bairro"
              name="bairro"
            />
          </mat-form-field>

          <div class="flex-row linha-form">
            <mat-form-field appearance="outline" class="campo-cidade">
              <mat-label>Cidade</mat-label>
              <input
                matInput
                type="text"
                formControlName="cidade"
                name="cidade"
              />
            </mat-form-field>

            <mat-form-field appearance="outline" class="campo-estado">
              <mat-label>UF</mat-label>
              <mat-select formControlName="uf">
                <mat-option *ngFor="let estado of listaUfs" [value]="estado">
                  {{ estado }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="flex-row center-end button-row">
            <button
              mat-raised-button
              type="button"
              *ngIf="diasRestantesAvaliacao > 0"
              (click)="fecharPopup()"
            >
              Agora não
            </button>
            <button
              mat-raised-button
              color="primary"
              type="button"
              [disabled]="
                !applySubscriptionForm.controls['dadosPessoais'].valid
              "
              (click)="formNext()"
            >
              Avançar
            </button>
          </div>
        </div>
        <div
          class="flex-column"
          *ngSwitchCase="2"
          [formGroup]="applySubscriptionForm.controls['pagamento']"
        >
          <div class="flex-row stepTitle center-start">
            <button mat-icon-button (click)="formPrevious()">
              <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
            <div class="flex-column">
              <h5>Etapa 2 de 2</h5>
              <h3>Pagamento</h3>
            </div>
          </div>

          <mat-form-field appearance="outline">
            <mat-label>CPF</mat-label>
            <input
              matInput
              type="text"
              formControlName="cpf"
              name="cpf"
              [textMask]="masks.cpf"
            />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Data de nascimento</mat-label>
            <input
              matInput
              type="text"
              formControlName="dataNascimento"
              name="dataNascimento"
              [textMask]="masks.diaMesAno"
            />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Plano</mat-label>
            <mat-select formControlName="idPlano">
              <mat-option *ngFor="let plano of planos" [value]="plano.id">
                {{ plano.descricao }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <app-bandeiras-cartao></app-bandeiras-cartao>

          <mat-form-field appearance="outline">
            <mat-label>Número do cartão</mat-label>
            <input
              matInput
              type="text"
              formControlName="numeroCartao"
              name="numeroCartao"
              [textMask]="masks.cartaoCredito"
            />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Bandeira do cartão</mat-label>
            <mat-select formControlName="meioPagamento">
              <mat-option
                *ngFor="let bandeira of bandeirasCartao"
                [value]="bandeira.id"
              >
                {{ bandeira.descricao }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Nome do titular</mat-label>
            <input
              matInput
              type="text"
              formControlName="nomeTitularCartao"
              name="nomeTitularCartao"
            />
          </mat-form-field>

          <div class="flex-row linha-form">
            <mat-form-field appearance="outline" class="campo-vencimento">
              <mat-label>Vencimento</mat-label>
              <input
                matInput
                type="text"
                formControlName="vencimentoCartao"
                name="vencimentoCartao"
                [textMask]="masks.mesAno"
              />
            </mat-form-field>

            <mat-form-field appearance="outline" class="campo-cvv">
              <mat-label>CVV</mat-label>
              <input
                matInput
                type="text"
                formControlName="codigoSegurancaCartao"
                name="cvv"
              />
            </mat-form-field>
          </div>

          <mat-checkbox formControlName="deAcordoTermoCompra"
            >Estou de acordo com o
            <a href="#" target="_blank"
              >termo de condições de compra</a
            ></mat-checkbox
          >

          <div class="flex-row center-end button-row">
            <button
              mat-raised-button
              color="primary"
              type="submit"
              [disabled]="!applySubscriptionForm.controls['pagamento'].valid"
            >
              Concluir
            </button>
          </div>
        </div>
        <div class="flex-column center-center" *ngSwitchCase="3">
          <img
            src="../../../../../assets/ui/icons8-aprovação-128 1.png"
            alt=""
          />
          <h2>Muito obrigado!</h2>
          <p style="text-align: center">
            Você realizou com sucesso a sua assinatura do Super Vade Mecum, bons
            estudos!
          </p>
          <div class="flex-row center-center button-row">
            <button
              *ngIf="!problemaPagamento"
              mat-raised-button
              color="primary"
              type="button"
              (click)="fecharPopup()"
            >
              Fechar
            </button>
            <button
              *ngIf="problemaPagamento"
              mat-raised-button
              color="primary"
              type="button"
              (click)="fecharProblemaPopup()"
            >
              Fechar
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
