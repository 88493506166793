import { Component, OnInit, OnDestroy } from '@angular/core';
import { DownloaderService } from './downloader.service';
import { Observable, Subscription } from 'rxjs';
import { StatusService } from '../../services/status.service';
import { Router } from '@angular/router';
import { LeiDownload } from '../../arguments/lei/lei.download';
import { LeiUpdateStep, LeiUpdateStatus } from '../../repositorios/lei.repositorio';
import { DataControleRepositorio } from '../../repositorios/dataControle.repositorio';
import { EnumTipoDataControle } from '../../models/dataControle';

@Component({
  selector: 'app-downloader',
  templateUrl: './downloader.component.html',
  styleUrls: ['./downloader.component.scss']
})
export class DownloaderComponent implements OnInit, OnDestroy {
  descricoesStatus: string[] = [
    "Verificando leis baixadas...",
    "Procurando atualizações...",
    "Baixando atualização...",
    "Atualizando leis...",
    "Concluído"
  ]

  redirectOnEnd = false
  done = false

  private subscriptions: Subscription[] = [];

  constructor(
    private dataControleRepositorio: DataControleRepositorio,
    public downloaderService: DownloaderService,
    private statusService: StatusService,
    private router: Router
  ) {
    this.subscriptions.push(this.statusService.$AppOffline.subscribe(s => this.continuarParaAppOffline(s)))
    this.subscriptions.push(this.downloaderService.StatusDownload.subscribe(status => this.status_changed(status)))
  }

  async ngOnInit() {
    if (this.statusService.isAppOnline) {
      try {
        console.time('download')
        await this.downloaderService.processar();
        console.timeEnd('download')

        this.done = true
        if (this.redirectOnEnd)
          this.router.navigate(['/'])
      } catch (error) {
        this.router.navigate(['/leitor'])
      }
    }
    else
      this.router.navigate(['/leitor'])
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe())
    this.subscriptions = [];
  }

  public continuarParaAppOffline(off: boolean) {
    if (off) {
      this.router.navigate(['/leitor']);
    }
  }

  async status_changed(status: LeiUpdateStatus) {
    switch (status.step) {
      case LeiUpdateStep.concluido:
        await this.dataControleRepositorio.salvar({ tipo: EnumTipoDataControle.DataVerificacaoModoOffline, data: new Date() })
        this.router.navigate(['/leitor'])
        break
    }
  }
}
