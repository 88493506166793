<div align="end" *ngIf="referencia">
  <mat-icon
    class="close-icon"
    svgIcon="close"
    matTooltip="Fechar"
    (click)="confirmarCloseReferencia()"
  ></mat-icon>
</div>

<div class="editor-referencia-container" *ngIf="referencia; else readonly">
  <ng-container *ngIf="!carregandoReferencias; else Referenciasexistente">
    <mat-progress-bar mode="indeterminate">
      {{ buscarReferencias() }}
    </mat-progress-bar>
  </ng-container>

  <ng-template #Referenciasexistente>
    <ng-container *ngIf="!NovaReferencia; else elseNovaReferencia">
      <div class="form-row-select">
        <mat-form-field class="select-item-row">
          <mat-label [style.fontSize]="10 + (fontIncrement | async) + 'pt'"
            >Referência</mat-label
          >

          <input
            type="text"
            class="referencia-focus"
            matInput
            [formControl]="referenciaControl"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete
            #auto="matAutocomplete"
            [displayWith]="displayFn"
            (optionSelected)="
              selecionarReferenciaExistente($event.option.value)
            "
          >
            <mat-option
              *ngFor="let option of referenciaOptions | async"
              [value]="option"
            >
              {{ option.ref }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <button
          mat-button
          class="add-referencia-button"
          (click)="gerarNovareferencia()"
        >
          <mat-icon>add</mat-icon>
        </button>
      </div>
      <mat-progress-bar *ngIf="carregandoReferenciasPorId" mode="indeterminate">
      </mat-progress-bar>
    </ng-container>
    <ng-template #elseNovaReferencia>
      <div class="form-row">
        <mat-form-field class="form-item-row">
          <mat-label [style.fontSize]="10 + (fontIncrement | async) + 'pt'"
            >Tipo</mat-label
          >
          <mat-select
            required
            [ngModel]="referencia.tipo + ''"
            (selectionChange)="selecionarTipoReferenciaExistente($event)"
            class="referencia-focus"
            [style.fontSize]="10 + (fontIncrement | async) + 'pt'"
          >
            <mat-option
              value="0"
              class="referencia-focus"
              [style.fontSize]="10 + (fontIncrement | async) + 'pt'"
              >Lei</mat-option
            >
            <mat-option
              value="1"
              class="referencia-focus"
              [style.fontSize]="10 + (fontIncrement | async) + 'pt'"
              >Súmula</mat-option
            >
            <mat-option
              value="2"
              class="referencia-focus"
              [style.fontSize]="10 + (fontIncrement | async) + 'pt'"
              >Julgado</mat-option
            >
            <mat-option
              value="3"
              class="referencia-focus"
              [style.fontSize]="10 + (fontIncrement | async) + 'pt'"
              >Informativo</mat-option
            >
            <mat-option
              value="4"
              class="referencia-focus"
              [style.fontSize]="10 + (fontIncrement | async) + 'pt'"
              >Artigo Jurídico</mat-option
            >
            <mat-option
              value="5"
              class="referencia-focus"
              [style.fontSize]="10 + (fontIncrement | async) + 'pt'"
              >Questão de Prova</mat-option
            >
            <mat-option
              value="7"
              class="referencia-focus"
              [style.fontSize]="10 + (fontIncrement | async) + 'pt'"
              >Dicas de estudo</mat-option
            >
            <mat-option
              value="6"
              class="referencia-focus"
              [style.fontSize]="10 + (fontIncrement | async) + 'pt'"
              >Outros</mat-option
            >
          </mat-select>
        </mat-form-field>
        <mat-form-field class="form-item-row">
          <mat-label [style.fontSize]="10 + (fontIncrement | async) + 'pt'"
            >Referência</mat-label
          >
          <input
            [style.fontSize]="10 + (fontIncrement | async) + 'pt'"
            required
            matInput
            class="referencia-focus"
            [(ngModel)]="referencia.ref"
            type="text"
          />
        </mat-form-field>
        <mat-form-field class="form-item-row">
          <mat-label [style.fontSize]="10 + (fontIncrement | async) + 'pt'"
            >Link interno ou externo</mat-label
          >
          <input
            [style.fontSize]="10 + (fontIncrement | async) + 'pt'"
            matInput
            class="referencia-focus"
            [(ngModel)]="referencia.link"
            type="url"
          />
        </mat-form-field>
      </div>
      <div class="form-column">
        <mat-form-field class="form-item-column">
          <mat-label [style.fontSize]="10 + (fontIncrement | async) + 'pt'"
            >Conteúdo</mat-label
          >

          <textarea matInput hidden></textarea>

          <app-rich-text
            [editorDicaEstudo]="true"
            [dicaEstudo]="referencia.conteudo"
            (on_editDicas)="atualizarDicas($event)"
            class="referencia-focus"
          ></app-rich-text>
        </mat-form-field>
      </div>
    </ng-template>
  </ng-template>
</div>

<div align="end" class="button-bar" *ngIf="referenciaAlterada">
  <button
    mat-mini-fab
    (click)="confirmarCloseReferencia()"
    matTooltip="Descartar alterações"
    color="warn"
    style="margin-right: 10px"
  >
    <mat-icon>cancel</mat-icon>
  </button>

  <button
    mat-mini-fab
    (click)="salvarReferencia()"
    matTooltip="Salvar referência"
  >
    <mat-icon>save</mat-icon>
  </button>
</div>

<ng-template #readonly>
  <div class="mat-chip-list-container">
    <mat-chip-list class="mat-chip-list-referencia" [tabIndex]="-1">
      <p
        class="mat-chip-indicador"
        [style.fontSize]="10 + (fontIncrement | async) + 'pt'"
      >
        {{ referenciaReadOnly ? "Referências SVM:" : "Referências:" }}
      </p>

      <div
        class="mat-chip-referencias"
        *ngFor="let referencia of referencias; index as i"
      >
        <mat-chip [disableRipple]="true">
          <a
            [style.fontSize]="10 + (fontIncrement | async) + 'pt'"
            [ngClass]="{
              'mat-chip-referencias-link': !referenciaReadOnly,
              'mat-chip-referencias-link-p': referenciaReadOnly
            }"
            [matTooltip]="tipoReferencia(referencia.tipo)"
            (click)="openDialog(referencia)"
            >{{ referencia.ref }}</a
          >
        </mat-chip>
        <mat-icon
          *ngIf="!referenciaReadOnly"
          class="excluir-icon"
          svgIcon="close"
          matTooltip="Excluir"
          (click)="confirmarDelecaoReferencia(referencia)"
        ></mat-icon>
        <p class="mat-chip-separador" *ngIf="i != referencias.length - 1">|</p>
      </div>
    </mat-chip-list>
  </div>
</ng-template>
