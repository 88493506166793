import { Component, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import { Guia } from '../../../models/Guia';
import { UiService } from '../../../services/ui.service';
import { EntitiesHelper } from '../../../helpers/entities.helper';
import { Subscription } from 'rxjs';
import { UsuarioGuiasService } from '../../../services/data-services/usuario.guias.service';


@Component({
  selector: 'app-barra-navegacao',
  templateUrl: './barra-navegacao.component.html',
  styleUrls: ['./barra-navegacao.component.scss']
})
export class BarraNavegacaoComponent implements OnInit, OnDestroy {
  public guias: Guia[];

  private larguraTela: number;
  public larguraAba: string;

  public foreground: string;

  private subscriptions: Subscription[] = []

  @Output()
  expandirPainelNovaGuia = new EventEmitter<boolean>();

  constructor(
    private usuarioGuiasService: UsuarioGuiasService,
    private uiService: UiService
  ) { }

  ngOnInit() {
    this.subscriptions.push(this.usuarioGuiasService.$Guias.subscribe(guias => this.guias_changed(guias)))
    this.subscriptions.push(this.uiService.larguraTela.subscribe(width => this.width_subscribe(width)))

    this.usuarioGuiasService.carregar()
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe())
    this.subscriptions = []
  }

  private guias_changed(guias: Guia[]) {
    if (guias && !EntitiesHelper.equals(this.guias, guias)) {
      this.guias = guias.sort((a, b) => a.ordem - b.ordem);
      this.ajustarLarguraAba();
    }
  }

  public btnNovaGuia_click(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    this.usuarioGuiasService.novaGuia(null, true).then(() => {
      this.ajustarLarguraAba();
    });
  }

  public alternarPara(guia: Guia) {
    this.usuarioGuiasService.alterarGuiaAtiva(guia.id);
  }

  public fecharGuia(idGuia: string) {
    this.usuarioGuiasService.fecharGuia(idGuia);
  }

  private width_subscribe(width: number): void {
    if (!width)
      return;

    this.larguraTela = width;
    this.ajustarLarguraAba();
  }

  private ajustarLarguraAba(): void {
    if (this.guias && this.guias.length > 0) {
      const larguraBarraAba = this.larguraTela - 32;

      const larguraBarra = (larguraBarraAba / this.guias.length);
      const larguraAbaPadrao = 180;

      this.larguraAba = `${(larguraBarra < larguraAbaPadrao) ? larguraBarra : larguraAbaPadrao}px`;
    }
  }

  public mostrarBefore(guia: Guia) {
    const indexGuia = this.guias.findIndex(g => g.id === guia.id);
    const indexAtiva = this.guias.findIndex(g => g.ativa);
    const indexHover = this.guias.findIndex(g => g.hover);

    if (indexGuia == indexAtiva)
      return true;

    if (indexGuia === indexAtiva + 1)
      return false;

    if (indexGuia === indexHover + 1)
      return false;

    return true;
  }

  public mostrarAfter(guia: Guia) {
    const indexGuia = this.guias.findIndex(g => g.id === guia.id);
    const indexAtiva = this.guias.findIndex(g => g.ativa);
    const indexHover = this.guias.findIndex(g => g.hover);

    if (indexGuia == indexAtiva)
      return true;

    if (indexGuia === indexAtiva - 1)
      return false;

    if (indexGuia === indexHover - 1)
      return false;

    return true;
  }

  public index(guia: Guia) {
    return this.guias.findIndex(g => g.id === guia.id);
  }

  public get indexGuiaAtiva() {
    return this.guias.findIndex(g => g.ativa);
  }

  public get indexMouseOver() {
    return this.guias.findIndex(g => g.hover);
  }
}
