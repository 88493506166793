<!-- <mat-expansion-panel [expanded]="true" (opened)="abrir()" (closed)="fechar()">
  <mat-expansion-panel-header>
    <mat-panel-title>
      Identificação
    </mat-panel-title>
    <mat-panel-description>
      Nome & foto
    </mat-panel-description>
  </mat-expansion-panel-header> -->

  <div class="row">
    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
      <input style="display: none" type="file" (change)="onFileChanged($event)" #fileInput>
      <img src="{{(model.foto) ? model.foto : defaultPhoto}}" (click)="pickPhoto()" class="user-photo img-responsive">
    </div>

    <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
      <mat-form-field>
        <input matInput placeholder="email" disabled [(ngModel)]="email">
      </mat-form-field>

      <mat-form-field>
        <input matInput placeholder="Nome" [(ngModel)]="model.nome">
      </mat-form-field>

      <mat-form-field>
        <input matInput placeholder="Sobrenome" [(ngModel)]="model.sobrenome">
      </mat-form-field>

      <div class="button-bar" *ngIf="model.alterado">
        {{this.confirmarAlteracoes.next(true)}}
        <button mat-raised-button color="warn" (click)="cancelar()">Cancelar</button>
        <button mat-raised-button color="primary" *ngIf="model.valido" (click)="salvar()">Salvar</button>
      </div>
    </div>
  </div>
<!-- </mat-expansion-panel> -->