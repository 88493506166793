import { UserdataDTO } from "../UserdataSync";
import { ParametrosCaneta, PerfilUsuario, PreferenciasUsuario } from "../UserData";

export class ConfiguracoesUsuario {
    //Preferências
    public palavrasMinuto: number;
    public perfilUsuario: PerfilUsuario;

    //Aparencia
    public idTema: number;
    public parametrosCaneta: ParametrosCaneta;

    //Informações pessoais
    public id: string;
    public email: any;
    public nome: string;
    public sobrenome: string;
    public foto: string;

    //Re-Autenticação
    public authToken: string;
    public preferenciasUsuario: PreferenciasUsuario;
    public dataUltimaAlteracao: Date;
    
    fontSize: number;

    public static fromModel(model: UserdataDTO, atual: ConfiguracoesUsuario = null): ConfiguracoesUsuario {
        if (!atual)
            atual = new ConfiguracoesUsuario();

        atual.authToken = model.authToken;

        atual.id = model.id;
        atual.email = model.email;
        atual.nome = model.nome;
        atual.sobrenome = model.sobrenome;
        atual.foto = model.foto;

        atual.idTema = model.preferencias.idTema;
        atual.parametrosCaneta = model.parametrosCaneta;

        atual.palavrasMinuto = model.palavrasMinuto;
        atual.perfilUsuario = model.perfilUsuario;
        atual.preferenciasUsuario = model.preferencias;

        atual.fontSize = model.fontSize;
        return atual;
    }
}