import { BaseObj } from "../UserdataSync";

export class EstatisticasLeitura extends BaseObj {

    // idConjunto: null
    // idLei: "5d642cc8c93a1e09bc8297a1"
    // id: "5d642cc8c93a1e09bc8297a1"
    // idUltimoItemLido: ""
    // quantidadeArtigos: 0
    // quantidadePalavras: 0
    // totalPalavras: 0
    // progresso: 0
    // totalMinutos: 0
    // horasRestantes: 0
    // minutosRestantes: 0
    // segundosRestantes: 0
    // favorito: false
    // linhasLidas: []

    public idUltimoItemLido: string;
    public favorito: boolean;
    public linhasLidas: string[];

    constructor() {
        super();

        this.linhasLidas = new Array<string>();
        this.favorito = false;
        this.idUltimoItemLido = '';
    }

    public static CalcularProgresso(totalLinhas: number, linhasLidas: number): number {
        let retorno = 0;

        if (linhasLidas > 0 && totalLinhas > 0) {
            retorno = Math.round((linhasLidas / totalLinhas) * 100 * 100) / 100;
            retorno = retorno > 100 ? 100 : retorno;
        }

        return retorno;
    }

    static CalcularTempoTotal(palavrasMinuto: number, totalPalavras: number): Tempo {
        const retorno = new Tempo();

        const totalSegundosLeitura = Math.round((totalPalavras / palavrasMinuto) * 60);

        const numHorasRestantes = Math.floor(totalSegundosLeitura / 60 / 60);
        const numMinutosRestantes = Math.floor((totalSegundosLeitura - (numHorasRestantes * 60 * 60)) / 60);
        const numSegundosRestantes = Math.floor(totalSegundosLeitura - (numHorasRestantes * 60 * 60) - (numMinutosRestantes * 60));

        retorno.horas = numHorasRestantes < 0 ? 0 : numHorasRestantes;
        retorno.minutos = numMinutosRestantes < 0 ? 0 : numMinutosRestantes;
        retorno.segundos = numSegundosRestantes < 0 ? 0 : numSegundosRestantes;

        return retorno;
    }

    public static CalcularTempoRestante(palavrasMinuto: number, progresso: number, totalPalavras: number): Tempo {
        const retorno = new Tempo();

        if (!palavrasMinuto || !(progresso >= 0) || !(totalPalavras >= 0))
            return null

        const palavrasRestantes = totalPalavras * ((100 - progresso) / 100);
        const totalSegundosLeitura = Math.round((palavrasRestantes / palavrasMinuto) * 60);

        const numHorasRestantes = Math.floor(totalSegundosLeitura / 60 / 60);
        const numMinutosRestantes = Math.floor((totalSegundosLeitura - (numHorasRestantes * 60 * 60)) / 60);
        const numSegundosRestantes = Math.floor(totalSegundosLeitura - (numHorasRestantes * 60 * 60) - (numMinutosRestantes * 60));

        retorno.horas = numHorasRestantes < 0 ? 0 : numHorasRestantes;
        retorno.minutos = numMinutosRestantes < 0 ? 0 : numMinutosRestantes;
        retorno.segundos = numSegundosRestantes < 0 ? 0 : numSegundosRestantes;

        return retorno;
    }
}

export class Tempo {
    public horas: number;
    public minutos: number;
    public segundos: number;

    public static toTimeString(tempo: Tempo): string {
        let strHoras = `${tempo.horas}`;
        if (strHoras.length === 1)
            strHoras = '0' + strHoras;

        let strMinutos = `${tempo.minutos}`;
        if (strMinutos.length === 1)
            strMinutos = '0' + strMinutos;

        let strSegundos = `${tempo.segundos}`;
        if (strSegundos.length === 1)
            strSegundos = '0' + strSegundos;

        return `${strHoras}:${strMinutos}:${strSegundos}`;
    }

    public static toTimeStringFromSeconds(seconds: number): string {
        if (!seconds || seconds < 0) {
            return '00:00:00'
        }

        const date = new Date(null);
        date.setSeconds(seconds);
        const result = date.toISOString().substr(11, 8);

        return result;
    }
}