import { Apontamento } from "../../../services/timer.service";

export class DetalheTimer {
    public descricao: string;
    public segundos: number;
    public tempo: string;
    public anotacoes: string[];
    idLei: string;

    public constructor() {
        this.anotacoes = new Array<string>();
    }

    public static fromApontamento(apontamento: Apontamento, descricao: string): DetalheTimer {
        const detalhe = new DetalheTimer();
        const ap = Apontamento.fromJSON(JSON.stringify(apontamento));

        detalhe.idLei = ap.idLei;
        detalhe.descricao = descricao;
        detalhe.segundos = ap.tempoTotalSegundos;
        detalhe.tempo = ap.strTempoTotal;
        detalhe.anotacoes = ap.anotacoes;

        return detalhe;
    }
}