import { LeiStorage } from "./lei.storage"

export class LeiConteudo {
    id: string
    dados: string
    dataHoraUltimaModificacaoTextoLei: Date;

    static fromStorage(lei: LeiStorage): LeiConteudo {
        return {
            id: lei.id,
            dados: lei.dados,
            dataHoraUltimaModificacaoTextoLei: lei.dataHoraUltimaModificacaoTextoLei
        }
    }
}