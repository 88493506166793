import { RangeSelecao } from '../models/RangeSelecao';
import { Comentario } from '../models/Comentario';
import { Marcacao } from '../models/Marcacao';
import { ItemVersaoRangeSelecao } from '../models/ItemVersaoRangeSelecao';

export class TagConteudo {
    ids: ItemVersaoRangeSelecao[];
    tipoTag: TipoTag;
    idTag: string;
    tag: any;

    public constructor(marcacao: Marcacao = null, comentario: Comentario = null) {
        if (marcacao != null) {
            this.ids = marcacao.range.idItens;
            this.tipoTag = TipoTag.Marcacao;
            this.tag = marcacao;
            this.idTag = marcacao.id;
        } else if (comentario != null) {
            this.ids = comentario.range.idItens;
            this.tipoTag = TipoTag.Comentario;
            this.tag = comentario;
            this.idTag = comentario.id;
        }
    }
}

export enum TipoTag {
    Marcacao = 0,
    Comentario = 1
}
