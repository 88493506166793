import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-star-rating",
  templateUrl: "./star-rating.component.html",
  styleUrls: ["./star-rating.component.scss"],
})
export class StarRatingComponent implements OnInit {
  stars: number[] = [70, 140, 210, 280, 350];
  myVariableColor: string = "#0a83ba";
  @Output() ratingUpdated = new EventEmitter();
  @Input() rating: number = 70;

  constructor() {}

  ngOnInit(): void {}
  countStar(star: number) {
    star = star * 70;
    this.rating = star;
    this.ratingUpdated.emit(star);
  }

  showIcon(index: number) {
    if (this.rating >= index * 70) {
      return "star";
    } else {
      return "star_border";
    }
  }
}
