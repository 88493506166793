import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject, Subscription } from "rxjs";
import { UsuarioPreferenciasService } from "../../services/data-services/usuario.preferencias.service";
import { ConfiguracoesUsuario } from "../../models/usuario/ConfiguracoesUsuario";

@Injectable()
export class PopupsService {
  private s_prefs: Subscription;
  private prefs: ConfiguracoesUsuario;

  public popupType: Observable<PopupType>;
  private _popupType = new BehaviorSubject<PopupType>(null);

  public visible: Observable<boolean>;
  private _visible = new BehaviorSubject<boolean>(false);

  public canClose: Observable<boolean>;
  private _canClose = new BehaviorSubject<boolean>(false);

  constructor(private usuarioPreferenciasService: UsuarioPreferenciasService) {
    this.popupType = this._popupType.asObservable();
    this.visible = this._visible.asObservable();
    this.canClose = this._canClose.asObservable();

    this.s_prefs = this.usuarioPreferenciasService.$Configuracoes.subscribe(
      (c) => {
        this.prefs = c;
        this.update();
      }
    );

    this.popupType.subscribe((type) => this.popupType_subscribe(type));
  }

  private update() {
    if (
      !this.prefs ||
      (this.prefs.palavrasMinuto && this.prefs.perfilUsuario)
    ) {
      this._popupType.next(null);
    } else {
      this._popupType.next(PopupType.BoasVindas);
    }
  }

  public get aberto(): boolean {
    return this._visible.getValue();
  }

  private popupType_subscribe(type: PopupType): void {
    const v = type ? true : false;

    this._visible.next(v);
    if (v) {
      const c = type !== PopupType.BoasVindas ? true : false;
      this._canClose.next(c);
    }
  }

  public abrirPopupPerfil(): void {
    this._popupType.next(PopupType.Perfil);
  }

  public abrirPopupPreferencias(): void {
    this._popupType.next(PopupType.Preferencias);
  }

  public abrirPopupFeedback(): void {
    this._popupType.next(PopupType.Feedback);
  }

  public abrirPopupIndiceSistematico(): void {
    this._popupType.next(PopupType.IndiceSistematico);
  }

  public fecharPopups(): void {
    if (this._canClose.getValue()) {
      this._popupType.next(null);
    }
  }
}

export enum PopupType {
  BoasVindas = 1,
  Perfil = 2,
  Preferencias = 3,
  Feedback = 4,
  IndiceSistematico = 5,
}
