import { RangeSelecao } from './RangeSelecao';
import { EntitiesHelper } from '../helpers/entities.helper';
import { TipoSelecao } from '../services/selection.service';
import { ItemProvaDatasource } from './UserData';
import { BaseObj } from './UserdataSync';

export class Marcacao extends BaseObj {
    id: string;
    tipoSelecao: TipoSelecao;
    dados: ProvaDados;
    cont: number;
    range: RangeSelecao;
    idLei: string;
    instituicao: any;
    banca: any;
    ano: any;
    tipo: any;
    cargo: any

    constructor() {
        super();

        this.id = EntitiesHelper.generateGuid();
        this.range = new RangeSelecao();
        this.dados = new ProvaDados();
    }

    public static EstaNaLinha(m: Marcacao, id: string, versao: number): boolean {
        return m.range.idItens.findIndex(i => i.idItem === id && i.idImportacao === versao) !== -1;
        // console.log(id, versao);
        // return false;
    }
}

export enum TipoMarcacao {
    CaiuProva = 0,
    ErrouProva = 1,
    Dificil = 2,
    Importante = 3
}


export class ProvaDados {
    instituicao: ItemProvaDatasource;
    banca: ItemProvaDatasource;
    ano: ItemProvaDatasource;
    tipo: ItemProvaDatasource;
    cargo: ItemProvaDatasource;
}


export class FuncoesMarcacao {
    static getTipoMarcacaoCssClass(tipo: number) {
        let ret = null;

        switch (tipo) {
            case 0:
                ret = 'caiu-prova';
                break;
            case 1:
                ret = 'errou-prova';
                break;
            case 2:
                ret = 'dificil';
                break;
            case 3:
                ret = 'importante';
                break;
            default:
                ret = 'comentario';
        }

        return ret;
    }
}

export class FuncoesProva {
    public static getLabelProva(prova: ProvaDados) {
        if (!prova) {
            return '';
        }

        const instituicao = (prova.instituicao) ? prova.instituicao.descricao : '';
        const banca = (prova.banca) ? prova.banca.descricao : '';
        const ano = (prova.ano) ? prova.ano.descricao : '';
        const tipo = (prova.tipo) ? prova.tipo.descricao : '';
        const cargo = (prova.cargo) ? prova.cargo.descricao : '';

        const labelarray = new Array(instituicao, banca, ano, tipo, cargo).filter(String);
        let label = labelarray.join(' - ');

        if (label === '') {
            label = 'Prova sem descrição';
        }


        return label;
    }
}