<div class="painel-frame">
  <div class="painel-barra-titulo" [style.backgroundColor]="titlebarColor" [style.color]="'white'">
    <div class="left painel-titulo">{{titlebarText}}</div>
    <div class="right painel-barra-titulos-botoes">
      <span (click)="voltar()" title="Voltar" class="glyphicon glyphicon-ok" *ngIf="parameters.comentarioAtivo"></span>
      <span (click)="fecharPainel()" title="Fechar painel" class="glyphicon glyphicon-remove"></span>
    </div>
    <div class="clearfix"></div>
  </div>
  <div class="painel-conteudo">
    <div *ngIf="!parameters.comentarioAtivo" class="painel-lista">
      <ul>
        <li *ngFor="let comentario of parameters.listaComentarios" (click)="editar(comentario)" (mouseenter)="mouseEnter(comentario)"
          (mouseleave)="mouseLeave()">
          <span class="item-lista-titulo">
            <div class="clearfix"></div>
          </span>
          <span class="item-lista-texto">{{comentario.texto}}</span>
          <div class="right">
            <a href="#" (click)="remover($event, comentario)">
              <span class=" glyphicon glyphicon-trash"></span>
            </a>
          </div>
        </li>
      </ul>
    </div>
    <div *ngIf="parameters.comentarioAtivo" class="painel-detalhes">
      <div class="form-group">
        <mat-form-field class="text-area-comentario">
          <textarea matInput #textComentario placeholder="Comentário" rows="5" [(ngModel)]="parameters.comentarioAtivo.texto" (keyUp.enter)="voltar()" (blur)="salvar()"></textarea>
        </mat-form-field>
        <span>Título ?</span>
        <mat-slide-toggle class="right" style="margin-right: 5px;" [(ngModel)]="parameters.comentarioAtivo.mnemonico" (change)="salvar()"></mat-slide-toggle>
      </div>
    </div>
  </div>
</div>
