<div class="placeholder flex-row center-center">
  <div class="esquerda clickable flex-column center-center" (click)="retroceder_click()">
    <div class="icon icon-left"></div>
  </div>
  <div class="meio clickable flex-1 flex-row center-center" title="{{textoCentralPaginador}}"
    [matMenuTriggerFor]="menu">
    <span>{{textoCentralPaginador | slice:0:40}}</span>
    <span *ngIf="textoCentralPaginador.length > 40">...</span>
    </div>
  <div class="direita clickable flex-column center-center" (click)="avancar_click()">
    <div class="icon icon-right"></div>
  </div>
</div>

<mat-menu #menu="matMenu">
  <button mat-menu-item *ngFor="let p of paginas" (click)="irPara_click($event, p)" title="{{p.tituloLei}}">
    <span class="texto-pagina">{{p.tituloLei}}</span>
  </button>
</mat-menu>
