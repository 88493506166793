import { Injectable } from "@angular/core";
import { EstatisticasLeitura } from "../models/usuario/EstatisticasLeitura";
import { NgxIndexedDBService } from "ngx-indexed-db";
import { StatusService } from "../services/status.service";
import { AppConfig } from "../app.config";

const STORE_NAME = "estatisticas";

@Injectable()
export class EstatisticasRepositorio {
  constructor(
    private dbService: NgxIndexedDBService,
    private statusService: StatusService
  ) {}

  tryMigrateData(): Promise<void> {
    const dbName = "userdata";
    const dbVersion = AppConfig.versaoIndexDB;
    const collectionName = "estatisticas";

    const beginTransaction = (db: IDBDatabase) =>
      db.transaction(collectionName, "readwrite").objectStore(collectionName);

    const apagarRegistro = (objectStore: IDBObjectStore, id: string) =>
      new Promise<void>(async (resolve) => {
        const request = objectStore.delete(id);
        request.onsuccess = () => {
          resolve();
        };
      });

    const needsMigration = (objectStore: IDBObjectStore) =>
      new Promise<boolean>(async (resolve) => {
        const request = objectStore.count();
        request.onsuccess = () => {
          resolve(request.result > 0);
        };
      });

    return new Promise(async (resolve) => {
      const idbRequest = window.indexedDB.open(dbName, dbVersion);

      idbRequest.onsuccess = () => {
        const db = idbRequest.result;

        if (db.objectStoreNames.contains(collectionName)) {
          const objectStore = beginTransaction(db);

          if (needsMigration(objectStore)) {
            this.statusService.mostrarMensagemProgresso(
              "Atualizando suas estatísticas de leitura",
              "tryMigrateData"
            );
            const listaEstatisticas: EstatisticasLeitura[] = [];

            objectStore.openCursor().onsuccess = async (event) => {
              const cursor = (event.target as IDBRequest)
                .result as IDBCursorWithValue;
              if (cursor) {
                listaEstatisticas.push(cursor.value as EstatisticasLeitura);
                cursor.continue();
              } else {
                for (let estatisticas of listaEstatisticas) {
                  await this.salvar(estatisticas);
                  await apagarRegistro(beginTransaction(db), estatisticas.id);
                }

                this.statusService.ocultarMensagemProgresso("tryMigrateData");
                resolve();
              }
            };
          } else {
            resolve();
          }
        } else {
          resolve();
        }
      };
    });
  }

  async listar(): Promise<EstatisticasLeitura[]> {
    await this.tryMigrateData();
    const retorno = await this.dbService
      .getAll(STORE_NAME)
      .toPromise()
      .then((referencia: EstatisticasLeitura[]) => referencia);
    return retorno;
  }

  async buscar(idLei: string): Promise<EstatisticasLeitura> {
    return await this.dbService
      .getByKey(STORE_NAME, idLei)
      .toPromise()
      .then((referencia: EstatisticasLeitura) => referencia);
  }

  async salvar(estatisticas: EstatisticasLeitura) {
    await this.dbService.update(STORE_NAME, estatisticas).toPromise();
  }
}
