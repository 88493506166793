<div class="quiz-space"></div>
<mat-card class="quiz-card">
  <mat-card-content class="quiz-header">
    <div class="quiz-header-title">
      <div class="quiz-header-card-title">
        Sobre o <strong>{{ quizList.artigo }}</strong>
      </div>
      <div class="quiz-header-card-subtitle">Responda:</div>
    </div>
    <div
      aria-hidden="false"
      aria-label="close"
      (click)="closeQuiz()"
      id="blink-close"
      class="icon icon-close material-icons-outlined"
    ></div>
  </mat-card-content>
  <mat-card-content class="quiz-header-question">
    <p>
      {{ quizList.question }}
    </p>
  </mat-card-content>
  <mat-card-actions class="quiz-radio-button-actions">
    <mat-radio-group class="quiz-radio-group" [(ngModel)]="quizResponse">
      <mat-radio-button
        (click)="quizReset()"
        [disabled]="isDisabled"
        [ngClass]="{
          'quiz-radio-errado': Response === 'errado' && option === quizResponse,
          'quiz-radio-correto':
            Response === 'correto' && option === quizResponse
        }"
        class="quiz-radio-button"
        *ngFor="let option of quizList.anslistobj"
        [value]="option"
      >
        {{ option }}
      </mat-radio-button>
    </mat-radio-group>
  </mat-card-actions>
  <div *ngIf="Response === 'errado'" class="quiz-center">
    <span>Não foi dessa vez, tente novamente</span>
    <mat-icon
      aria-hidden="false"
      aria-label="quiz"
      class="icon icon-cancel material-icons-outlined"
    >
      cancel
    </mat-icon>
  </div>
  <div *ngIf="Response === 'correto'" class="quiz-center">
    <span>Parabéns! você selecionou a resposta correta</span>
    <mat-icon
      aria-hidden="false"
      aria-label="quiz"
      class="icon icon-check-circle material-icons-outlined"
    >
      check_circle
    </mat-icon>
  </div>
  <mat-card-actions class="quiz-center">
    <button
      mat-raised-button
      color="primary"
      class="btn-quiz"
      (click)="quizCorrect()"
    >
      Responder
    </button>
  </mat-card-actions>
</mat-card>
<div class="quiz-space"></div>
