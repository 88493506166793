import { Component, OnInit, OnDestroy } from '@angular/core';
import { PaineisService } from '../leitor-content-tabcontent/paineis.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-leitor-content-panelspinner',
    templateUrl: './leitor-content-panelspinner.component.html',
    styleUrls: ['./leitor-content-panelspinner.component.css']
})
export class LeitorContentPanelSpinnerComponent implements OnInit, OnDestroy {
    private s_painelSpinner: Subscription;

    exibirSpinner: boolean;

    constructor(private paineisService: PaineisService) {
    }

    ngOnInit() {
        this.s_painelSpinner = this.paineisService.painelSpinner.subscribe(exibir => {
            this.exibirSpinner = exibir;
        });
    }

    ngOnDestroy(): void {
        this.s_painelSpinner.unsubscribe();
    }
}
