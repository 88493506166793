export class MaskHelper {

    public static celular = {
        guide: true,
        showMask: false,
        mask: ['(', /[1-9]/, /\d/, ')', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
    };

    public static mesAno = {
        guide: true,
        showMask: false,
        mask: [/[0-1]/, /\d/, '/', /\d/, /\d/]
    };

    public static diaMesAno = {
        guide: true,
        showMask: false,
        // mask: MaskHelper.DiaMesAnoFunc
        mask: [/[0-3]/, /\d/, '/', /[0-1]/, /\d/, '/', /[0-2]/, /\d/, /\d/, /\d/]
    };

    public static cpf = {
        guide: false,
        showMask: false,
        mask: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]
    };

    public static cep = {
        guide: false,
        showMask: false,
        mask: [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]
    };

    public static cartaoCredito = {
        guide: false,
        showMask: false,
        mask: [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]
    };

    private static DiaMesAnoFunc(valor) {
        if (!valor) { return [/[0-3]/, /\d/, '/', /[0-1]/, /\d/, '/', /[0-2]/, /\d/, /\d/, /\d/]; }
    }

}
