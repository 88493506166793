import { LeitorContentParameters } from '../leitor-content/leitor-content.parameters';
import { LeitorToolbarParameters } from '../leitor-toolbar/leitor-toolbar.parameters';

export class LeitorParameters {
    leitorContentParameters: LeitorContentParameters;
    leitorToolbarParameters: LeitorToolbarParameters;

    constructor() {
        this.leitorContentParameters = new LeitorContentParameters();
        this.leitorToolbarParameters = new LeitorToolbarParameters();
    }
}
