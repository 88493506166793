import { Component, OnInit } from '@angular/core';
import { TextFormatingService } from '../../../services/ui/textFormating.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'controle-fonte',
  templateUrl: './controle-fonte.component.html',
  styleUrls: ['./controle-fonte.component.scss']
})
export class ControleFonteComponent implements OnInit {
  podeAumentar: Observable<boolean>;
  podeDiminuir: Observable<boolean>;

  constructor(
    private textFormatingService: TextFormatingService
  ) {
    this.podeAumentar = textFormatingService.podeAumentar;
    this.podeDiminuir = textFormatingService.podeDiminuir;
  }

  ngOnInit() {
  }

  diminuirFonte(){
    this.textFormatingService.decreaseFontSize();
  }

  aumentarFonte(){
    this.textFormatingService.increaseFontSize();
  }
}
