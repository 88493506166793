import { Component, OnInit, OnDestroy } from '@angular/core';
import { PainelLateralService } from './painel-lateral.service';

@Component({
  selector: 'app-painel-lateral',
  templateUrl: './painel-lateral.component.html',
  styleUrls: ['./painel-lateral.component.scss']
})
export class PainelLateralComponent implements OnInit, OnDestroy {
  public constructor(
    public painelService: PainelLateralService
  ) { }

  public ngOnInit(): void { }

  public ngOnDestroy(): void { }
}

export class FuncoesData {
  public static getMes(data: Date): string {
    switch (data.getMonth()) {
      case 0: return 'Janeiro';
      case 1: return 'Fevereiro';
      case 2: return 'Março';
      case 3: return 'Abril';
      case 4: return 'Maio';
      case 5: return 'Junho';
      case 6: return 'Julho';
      case 7: return 'Agosto';
      case 8: return 'Setembro';
      case 9: return 'Outubro';
      case 10: return 'Novembro';
      case 11: return 'Dezembro';
    }
  }

  public static getDiaSemana(data: Date): string {
    switch (data.getDay()) {
      case 0: return 'Domingo';
      case 1: return 'Segunda-feira';
      case 2: return 'Terça-feira';
      case 3: return 'Quarta-feira';
      case 4: return 'Quinta-feira';
      case 5: return 'Sexta-feira';
      case 6: return 'Sábado';
    }
  }
}