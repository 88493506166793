import { Component, Inject, OnInit } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";

@Component({
  selector: "app-referencia-dialog",
  templateUrl: "./referencia-dialog.component.html",
  styleUrls: ["./referencia-dialog.component.scss"],
})
export class ReferenciaDialogComponent implements OnInit {
  dialog: MatDialog;

  constructor(
    public dialogRefConfig: MatDialogRef<ReferenciaDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  ngOnInit() {}

  onCancel(): void {
    this.dialogRefConfig.close();
  }
}
