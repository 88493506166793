import CryptoJS from 'crypto-js';

export class EncryptionHelper {
    public static Encrypt(rawText: string, key: string): string {
        return CryptoJS.AES.encrypt(rawText, key).toString()
    }

    public static Decrypt(encryptedText: string, key: string): string {
        if (!encryptedText)
            return null

        const bytes = CryptoJS.AES.decrypt(encryptedText, key)
        return bytes.toString(CryptoJS.enc.Utf8)
    }
}