import { Component, OnInit, OnDestroy } from '@angular/core';
import { IndicatorsService } from '../../services/indicators.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-indicators',
  templateUrl: './indicators.component.html',
  styleUrls: ['./indicators.component.css']
})
export class IndicatorsComponent implements OnInit, OnDestroy {
  loadingInterstitialParams: any;
  alertParams: any;

  private s_GetLoadingInterstitialState: Subscription;
  private s_GetAlertState: Subscription;

  constructor(
    private indicatorsService: IndicatorsService
  ) { }

  ngOnInit() {
    this.s_GetLoadingInterstitialState = this.indicatorsService.GetLoadingInterstitialState.subscribe(params => {
      this.loadingInterstitialParams = params;
    });

    this.s_GetAlertState = this.indicatorsService.GetAlertState.subscribe(params => {
      this.alertParams = params;
    });
  }
  
  ngOnDestroy(): void {
    this.s_GetLoadingInterstitialState.unsubscribe();
    this.s_GetAlertState.unsubscribe();
  }
}
