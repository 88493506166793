<div class="editor-dicaestudo" *ngIf="editorDicaEstudo; else readonly">
  <quill-editor
    [(ngModel)]="dicaEstudo"
    class="content-editor"
    [placeholder]="''"
    [modules]="quillConfiguration"
    (onContentChanged)="editDicas($event)"
  >
  </quill-editor>
</div>

<ng-template #readonly>
  <quill-view-html [content]="dicaEstudo" theme="snow"></quill-view-html>
</ng-template>
