import { Item, TipoItem } from './Item';
import { StringHelper } from '../helpers/string.helper';
import { NumberHelper } from '../helpers/numbers.helper';
import { KeyValue } from '@angular/common';

export class Lei {
    id: string;
    descricao: string;
    url: string;
    dataHoraUltimaModificacao: Date;
    dataHoraUltimaModificacaoOficial: Date;
    
    // 0 - Lei, 1 - Sumula, 2 - LeiInternacional
    tipoDocumento: number;
    itens: Array<Item>;

    quantidadeItens: number;
    quantidadePalavras: number;

    constructor() {
        this.itens = new Array<Item>();
    }

    static carregarIndice(lei: Lei, indexarRevogados: boolean): KeyValue<string, number>[] {
        if (!lei)
            return null

        const index: KeyValue<string, number>[] = []

        lei.itens.forEach(linha => {
            const versao = linha.versoes[linha.versoes.length - 1]
            const prefixo = versao.prefixo

            if ((!versao.revogado || indexarRevogados) && prefixo && (linha.tipoItem == TipoItem.Artigo || linha.tipoItem == TipoItem.Sumula)) {
                let numPrefixo = StringHelper.RemoverAcentosCaracteresEspeciais(prefixo
                    .toLowerCase())
                    .replace('artigo', '')
                    .replace('art', '')
                    .replace('sumula', '')
                    .trim()

                let numero: number = null

                if (NumberHelper.isRomanNumber(numPrefixo.toUpperCase()))
                    numero = NumberHelper.unRomanize(numPrefixo.toUpperCase())
                else {
                    numero = parseInt(numPrefixo.match(/\d+/g)[0])
                }

                index.push({ key: linha.id, value: numero })
            }
        })

        return index;
    }
}

